import React, { useState, useEffect, useContext } from "react";
import { Column, Grid } from "../../../../theme/Grid";
import footerImage from "../../../../assets/images/guzelliklerburada.png";
import Input from "../../../../theme/CustomMUI/Input";
import formImageCampaigns from "../../../../assets/images/formImageCampaigns.png";
import { BsWhatsapp } from "react-icons/bs";
import styled from "styled-components";
import Axios from "axios";
import { toast } from "react-toastify";
import MaskedInput from "../../../../theme/CustomMUI/MaskedInput";
import { Element } from "react-scroll";
import ReCaptcha from "../../../../components/ReCaptcha";
import AppContext from "../../../../context/store";

function Footer() {
  const [name, setName] = useState(null);
  const context = useContext(AppContext);
  const [surname, setSurname] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [company, setCompany] = useState(null);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [reCaptchaToken, setReCaptchaToken] = useState(null);

  const sendMessageForm = async () => {
    if (!name || !surname || !phoneNumber) {
      toast.warn("Lütfen isim, soyisim veya telefon numarasını eksiksiz giriniz.");
      return;
    }

    if (!reCaptchaToken) {
      toast.warn("Lütfen robot olmadığınızı doğrulayın");
      return;
    }

    await Axios.post(
      `https://api.vatansms.com/api/telegram/sendMessage`,
      {
        chat_id: "salon-uyelik",
        "g-recaptcha-response": reCaptchaToken,
        message:
          process.env.APP_NAME === "salon"
            ? `- app.salonrandevu.com İlk Alım Sayfası Bilgi Formu -
Kişi Adı: ${name} ${surname}
Telefon Numarası: ${phoneNumber}
Kurum Adı: ${company}
`
            : process.env.APP_NAME === "en"
              ? `- app.enrandevu.com İlk Alım Sayfası Bilgi Formu -
Kişi Adı: ${name} ${surname}
Telefon Numarası: ${phoneNumber}
Kurum Adı: ${company}
`
              : `- app.salonmanagementapp.com İlk Alım Sayfası Bilgi Formu -
Kişi Adı: ${name} ${surname}
Telefon Numarası: ${phoneNumber}
Kurum Adı: ${company}
`,
      }
    )
      .then((data) => {
        if (data.data.ok) {
          toast.success(context.t(`["sms/group"].successToast`));
        }
      })
      .catch(() => toast.error(context.t(`campaigns.footer.sendMessageErrorToast`)))
      .finally(() => {
        setName("");
        setSurname("");
        setPhoneNumber("");
        setCompany("");
        setReCaptchaToken(null);
      });
  };

  useEffect(() => {
    setWhatsAppMessage(
      `https://api.whatsapp.com/send?phone=902127060510&text=Merhabalar,%20size%20kampanya%20sayfanız%20%C3%BCzerinden%20ula%C5%9F%C4%B1yorum.`
    );
  }, []);

  return (
    <div
      style={{
        width: "100%",
        marginTop: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "50px",
        paddingBottom: "50px",
      }}
    >
      <FooterTitleWrapper>
        <Grid>
          <Column className="xs-12 sm-12 md-6 lg-6 xl-6 mt-3">
            <div>
              <Column
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "left",
                }}
                className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3"
              >
                <div>
                  <div
                    style={{
                      padding: "4px",
                      backgroundColor: "rgb(255, 193, 7)",
                      color: "black",
                      fontWeight: 800,
                      fontFamily: "'Mulish', sans-serif;",
                      fontSize: "18px",
                      width: "70%",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    #1 SALON UYGULAMASI
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Element name="wpdensor">
                      <h2
                        style={{
                          width: "80%",
                          fontSize: "24px",
                          color: "#040149",
                          fontWeight: 800,
                          fontFamily: "'Mulish', sans-serif;",
                          boxSizing: "border-box",
                        }}
                      >
                        Salon yönetiminde kalite ve güven arıyorsanız,
                        <span style={{ color: "#7500FF" }}>SALONRANDEVU </span>
                        sizin için doğru adres.
                      </h2>
                    </Element>
                  </div>
                </div>
              </Column>
              <Column
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3"
              >
                <img style={{ maxWidth: "250px" }} src={formImageCampaigns} />
              </Column>
            </div>
          </Column>
          <Column
            style={{ boxShadow: "0px 8px 1rem rgba(0,0,0,.15)" }}
            className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3"
          >
            <Grid>
              <Column
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                }}
                className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3"
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "28px",
                    color: "rgb(4, 1, 73)",
                    fontFamily: "'Mulish', sans-serif;",
                    fontWeight: 600,
                  }}
                >
                  Bilgi Almak İstiyorum
                </div>
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
                <WhatsAppButtonWrapper>
                  <button
                    onClick={() => window.open(whatsAppMessage)}
                    style={{
                      padding: "12px",
                      borderRadius: "10px",
                      color: "white",
                      backgroundColor: "rgb(40, 167, 69)",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      fontFamily: "'Mulish', sans-serif;",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                  >
                    <BsWhatsapp size={20} /> WhatsApp'tan Sor
                  </button>
                </WhatsAppButtonWrapper>
              </Column>
            </Grid>

            <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mt-3">
              <div
                style={{
                  textAlign: "center",
                  color: "rgb(4, 1, 73)",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
              >
                <span style={{ color: "#7500FF" }}>SALONRANDEVU</span>.app'in
                Premium avantajlarından bahsetmemizi ister misiniz?
              </div>
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
              <Input
                required
                value={name}
                onChange={(e) => setName(e.target.value.trim())}
                label={"Adınız"}
                placeholder={"Adınız"}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
              <Input
                required
                value={surname}
                onChange={(e) => setSurname(e.target.value.trim())}
                label={"Soyadınız"}
                placeholder={"Soyadınız"}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
              <MaskedInput
                required
                label={"Telefon Numaranız"}
                maskChar={""}
                mask="0 999 999 9999"
                // startAdornment=""
                value={phoneNumber !== null ? phoneNumber : ""}
                placeholder="0 500 000 0000"
                onChange={(e) => setPhoneNumber(e.target.value.trim())}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-6 xl-6 mt-3">
              <Input
                value={company}
                onChange={(e) => setCompany(e.target.value.trim())}
                label={"Kurum Adı"}
                placeholder={"Kurum Adı"}
              />
            </Column>

            <Column
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              className="xs-12 sm-12 md-12 lg-12 xl-12 mt-3"
            >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px" }}>
                <ReCaptcha
                  reCaptchaCodeHandler={(token) => {
                    setReCaptchaToken(token);
                  }}
                />
                <button
                  onClick={sendMessageForm}
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: "#7500FF",
                    color: "white",
                    width: "250px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Beni Ara
                </button>
              </div>
            </Column>
          </Column>
        </Grid>
      </FooterTitleWrapper>
    </div>
  );
}

export default Footer;

const FooterTitleWrapper = styled.div`
  width: 80%;

  @media only screen and (max-width: 1190px) {
    width: 98%;
  }
`;

const WhatsAppButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  @media only screen and (max-width: 1190px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
