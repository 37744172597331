import React, { Component, useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import Axios from "axios";
import moment from "moment";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import { SendOutlined, DeleteForever, Update } from "@material-ui/icons";
import styled from "styled-components";
import { toast } from "react-toastify";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import Select from "../../../theme/CustomMUI/Select";
import { FaUserEdit } from "react-icons/fa";

const TodoIndex = () => {
  const { api_endpoint, t, state } = useContext(AppContext);

  const [date, setDate] = useState(new Date());
  const [data, setData] = useState({
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => getTodos(page),
    },
    data: [],
  });
  const [staffs, setStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [newTodo, setNewTodo] = useState({
    message: "",
    state: 1,
  });
  const [loaded, setLoaded] = useState(true);

  const getTodos = (page = data.pagination.page) => {
    setLoaded(false);
    Axios.get(`${api_endpoint}/company/todos`, {
      params: {
        page,
        date: moment(date).tz("Europe/Istanbul").format("YYYY-MM-DD"),
      },
    })
      .then(({ data: { data } }) => {
        setData({
          data: [...data.records],
          pagination: {
            ...data.pagination,
            page: data.page,
            total_page: data.records.length,
            onChange: (page) => getTodos(page),
          },
        });
      })
      .finally(() => setLoaded(true));
  };

  const getStaffs = async () => {

    const response = await Axios.get(`${api_endpoint}/company/staffs/unsafe`);
    setStaffs(response.data.data);
  };

  const addTodo = () => {
    Axios.post(`${api_endpoint}/company/new/todo`, {
      ...newTodo,
      reminding_date: `${moment(date)
        .tz("Europe/Istanbul")
        .format("YYYY-MM-DD")}T00:00:00.000Z`,
      assigned_staff: selectedStaff ? selectedStaff.id : null,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(t("todos.toastSuccess"));
          getTodos();
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          toast.warning(e.response.data.message);
        } else {
          toast.error(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getTodos();
    getStaffs();
  }, [date]);

  return (
    <AuthContainer>
      <Grid>
        <Column className="xs-12" style={{ width: "342px" }}>
          <CustomMUIDatePicker
            autoOk
            openTo="date"
            variant="static"
            disableToolbar
            showTodayButton
            value={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-auto">
          <SectionContainer>
            <h1>{t("todos.addTodoTitle")}</h1>
            <span style={{ position: 'relative' }}>
              <span
                className="bg-orange"
                style={{
                  position: 'absolute',
                  top: "-2px",
                  right: "2px",
                  zIndex: "11",
                  color: 'white',
                  padding: '2px 7px',
                  borderRadius: '12px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
              >
                {t(`appointments.detail.statusNew`)}
              </span>
              <Select
                label={t(`appointments.create.selectedStaffInputLabel`)}
                items={[
                  { id: null, full_name: t("todos.noStaff") },
                  ...staffs.filter(staff => state.user ? staff.id !== state.user.id : true)
                ]}
                labelKey="full_name"
                valueKey="id"
                selected={selectedStaff ? `${selectedStaff?.id}` : "null"}
                returnFullObject
                handler={(selectedStaffElement) =>
                  setSelectedStaff(selectedStaffElement)
                }
              /></span>
            <Input
              className="mb-0"
              label={t("todos.todoInputLabel")}
              placeholder={t("todos.todoInputPlaceholder")}
              value={newTodo.message}
              rows={4}
              multiline
              autoFocus
              onChange={(e) => {
                if (e.target.value.length < 251) {
                  setNewTodo({
                    ...newTodo,
                    message: e.target.value,
                  });
                }
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
              <Button
                iconComponent={SendOutlined}
                disabled={!newTodo.message || newTodo.message.length > 250}
                title={t("todos.addButtonTitle")}
                onClick={() => {
                  if (!newTodo.message) {
                    toast.warning(t("todos.emptyWarning"));
                  } else {
                    addTodo();
                    setNewTodo({ ...newTodo, message: "" });
                  }
                }}
              />
              <span>{t("todos.characterLimit", { length: newTodo.message.length })}</span>
            </div>
          </SectionContainer>

          <SectionContainer>
            <h1>
              {moment(date).tz("Europe/Istanbul").format("LL")}{" "}
              {t("todos.dateTitle")}
            </h1>
            {loaded ? (
              data.data.length > 0 ? (
                <>
                  {data.data.map((todo) => (
                    <SingleTodo
                      key={todo.id} // key propunu unutmayın
                      data={todo}
                      staffs={staffs}
                      reload={() => getTodos(data.pagination.page)}
                    />
                  ))}
                </>
              ) : (
                <NoDataContainer>
                  <img
                    src={require("../../../assets/images/manypixels/empty.svg")}
                    alt={t("todos.imageAlt")}
                  />
                  <p>
                    {t("todos.notFoundText", {
                      date: moment(date).tz("Europe/Istanbul").format("LL"),
                    })}
                  </p>
                </NoDataContainer>
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={
                    process.env.APP_NAME === "salon"
                      ? Loader
                      : process.env.APP_NAME === "en"
                        ? LoaderEN
                        : LoaderSM
                  }
                  width="100"
                  height="100"
                  alt="loading"
                />
              </div>
            )}

            <PaginationContainer>
              <div className="pagination p7">
                <ul>
                  <button
                    disabled={data.pagination.page < 2}
                    onClick={() => {
                      const newPage = data.pagination.page - 1;
                      window.history.pushState(
                        { path: "" },
                        "",
                        `?page=${newPage}`
                      );
                      data.pagination.onChange(newPage); // onChange işlevi çağrılıyor
                    }}
                  >
                    <li>
                      <ArrowBackIosIcon
                        className="icon-back"
                        fontSize="small"
                      />
                    </li>
                  </button>
                  <button
                    disabled={
                      data.data.length < 3 || data.pagination.total_page < 10
                    }
                    onClick={() => {
                      const newPage = data.pagination.page + 1;
                      window.history.pushState(
                        { path: "" },
                        "",
                        `?page=${newPage}`
                      );
                      data.pagination.onChange(newPage); // onChange işlevi çağrılıyor
                    }}
                  >
                    <li>
                      <ArrowForwardIosIcon fontSize="small" />
                    </li>
                  </button>
                </ul>
              </div>
            </PaginationContainer>
          </SectionContainer>
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default TodoIndex;

const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 64px;

  h1 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

const TodoCard = styled.div`
  width: 100%;
  margin: 0 0 24px;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
`;

const TodoText = styled.div`
  width: 100%;
  max-width: 1000px;
  word-wrap: break-word;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.614;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    width: 100%;
  }

  /* Add more media queries and adjust styles as needed for different screen sizes */
`;

const TodoBottom = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  span{
    font-size: 13px;
    margin-right: 24px;
    font-weight: 500;
    color: #555;

    @media only screen and (max-width: 576px) {
      display: block;
      margin-right: 0;
      margin-bottom: 8px;

      &:nth-last-child(1){
        margin: 0;
      }
    }

    &.staff{
      display: inline-flex;
      align-items: center;
      
      img{
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 100px;
        overflow: hidden; 
        margin-right: 8px;
        background: #FAFAFA;
      }
    }

    &.status{
      padding: 4px 8px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 600;s
    }
  }
`;

const TodoActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    flex: 1;
    margin-right: 12px;

    &:nth-last-child(1) {
      margin: 0;
    }
  }
`;

const SingleTodo = ({ data, reload, staffs }) => {
  const context = useContext(AppContext);
  const { t } = context;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [assignedStaffInfo, setAssignedStaffInfo] = useState(data.assigned_staff !== null ? `${data.assigned_staff}` : `${data.staff_id}`);
  const [selectedStaff, setSelectedStaff] = useState(null);


  useEffect(() => {
    if (data.assigned_staff && staffs && staffs.length > 0) {
      const foundStaff = staffs.find(staff => staff.id === data.assigned_staff);
      if (foundStaff) {
        setSelectedStaff(foundStaff);
      }
      setAssignedStaffInfo(foundStaff);
    }
  }, [staffs, data.assigned_staff]);

  let STATE_COLOR = "orange";
  let STATE_TEXT = context.t(`todos.statusWaiting`);

  switch (data.state) {
    case 1:
      STATE_COLOR = "orange";
      STATE_TEXT = context.t(`todos.statusWaiting`);
      break;

    case 2:
      STATE_COLOR = "green";
      STATE_TEXT = context.t(`todos.statusCompleted`);
      break;

    default:
      break;
  }

  const setStateSingleTodo = (data, callback) => {
    Axios.put(`${context.api_endpoint}/company/update/todo`, {
      ...data,
      assigned_staff: selectedStaff ? selectedStaff.id : null,
      reminding_date: `${moment(data.reminding_date).format(
        "YYYY-MM-DD"
      )}T00:00:00.000Z`,
    }).then((response) => {
      if (response.status === 201) {
        toast.success(context.t(`todos.updateToast`));
        callback();
      }
    }).catch((error) => {
      toast.error(context.t(`todos.updateError`));
      console.error("Todo güncelleme hatası:", error);
    });
  };

  const deleteSingleTodo = (data, callback) => {
    Axios.post(`${context.api_endpoint}/company/delete/todo`, {
      id: data.id,
    }).then((response) => {
      if (response.status === 201) {
        toast.success(context.t(`todos.deleteToast`));
        callback();
      }
    });
  };

  return (
    <TodoCard>
      <TodoText>{data.message}</TodoText>
      <Grid className="no-gutters-row">
        <Column className="xs-12 sm-12 md-8">
          <TodoBottom>
            <span className="staff">
              <img
                src={
                  data.staff.detail.profile_photo
                    ? `${context.api_endpoint}/${context.state.company_id}/${data.staff.detail.profile_photo}`.replace(
                      "api/v1/",
                      ""
                    )
                    : require("../../../assets/images/profile_photo.svg")
                }
                alt={data.staff.full_name}
              />
              {data.assigned_staff !== null ? (
                <span>
                  {assignedStaffInfo?.full_name} •
                </span>
              ) : (<span>{data.staff.full_name}</span>)}

            </span>
            <span
              className={`status bg-${STATE_COLOR}-opacity fc-${STATE_COLOR}`}
            >
              {STATE_TEXT}
            </span>
          </TodoBottom>
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <TodoActionsContainer>
            <Button
              iconComponent={FaUserEdit}
              size="sm"
              backgroundColor="blue-opacity"
              textColor="blue"
              onClick={() => {
                setOpenEditDialog(true);
              }}
            />
            <Button
              iconComponent={Update}
              size="sm"
              backgroundColor="green-opacity"
              textColor="green"
              onClick={() =>
                setStateSingleTodo(
                  {
                    ...data,
                    state: data.state === 1 ? 2 : 1,
                  },
                  reload
                )
              }
            />

            <Button
              iconComponent={DeleteForever}
              size="sm"
              backgroundColor="red-opacity"
              textColor="red"
              onClick={() => {
                setOpenDeleteDialog(true);
                //deleteSingleTodo(data, reload)
              }}
            />
          </TodoActionsContainer>
        </Column>
        <AlertDialog
          title={t("todos.deleteConfirmTitle")}
          open={openDeleteDialog}
          fullWidth
          textType
          disableBackdropClick={false}
          closeHandler={() => setOpenDeleteDialog(false)}
          buttons={[
            {
              icon: "check",
              title: context.t("wallet.check.confirmButtonTitle"),
              backgroundColor: "primary",
              textColor: "white",
              transitionEffect: true,
              onClick: () => {
                deleteSingleTodo(data, reload);
                setOpenDeleteDialog(false);
              },
            },
            {
              icon: "close",
              title: context.t(`wallet.check.discardButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          {t("todos.deleteConfirmText")}
        </AlertDialog>

        <AlertDialog
          title={t("todos.updateStaffTitle")}
          open={openEditDialog}
          fullWidth
          textType
          disableBackdropClick={false}
          closeHandler={() => setOpenEditDialog(false)}
          buttons={[
            {
              icon: "check",
              title: context.t("wallet.check.confirmButtonTitle"),
              backgroundColor: "primary",
              textColor: "white",
              transitionEffect: true,
              onClick: () => {
                const updatedData = {
                  ...data,
                  assigned_staff: selectedStaff ? selectedStaff.id : null
                };
                setStateSingleTodo(updatedData, reload);
                setOpenEditDialog(false);
              },
            },
            {
              icon: "close",
              title: context.t(`wallet.check.discardButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          <Select
            label={t(`appointments.create.selectedStaffInputLabel`)}
            items={[
              { id: null, full_name: t("todos.noStaff") },
              ...staffs.filter(staff => staff.id !== data.staff_id)
            ]}
            labelKey="full_name"
            valueKey="id"
            selected={selectedStaff ? `${selectedStaff.id}` : "null"}
            returnFullObject
            handler={(selectedStaffElement) =>
              setSelectedStaff(selectedStaffElement)
            }
          />
        </AlertDialog>
      </Grid>
    </TodoCard>
  );
};

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  img {
    height: 160px;
    object-fit: contain;
    margin-bottom: 16px;
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #a0a0a0;
  }
`;
