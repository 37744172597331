import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

function ReCaptcha({ reCaptchaCodeHandler }) {
  return (
    <div>
      <ReCAPTCHA
        sitekey="6LcZ_jolAAAAAOj7LN4V8VdsK3FRQ0TN6Md2rOmF"
        onChange={(value) =>
          setTimeout(() => {
            reCaptchaCodeHandler(value);
          }, 1000)
        }
      />
    </div>
  );
}

export default ReCaptcha;
