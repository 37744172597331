import Axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContainer from "../../../../components/AuthContainer";
import AppContext from "../../../../context/store";
import Button from "../../../../theme/Button";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import Input from "../../../../theme/CustomMUI/Input";
import { Grid, Column } from "../../../../theme/Grid";
import {
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
} from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import Table from "../../../../theme/Table";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import { Alert } from "@material-ui/lab";
import { toast } from "react-toastify";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import Select from "../../../../theme/CustomMUI/Select";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import TimeSelect from "../../../../theme/TimeSelect";
import { Delete, FeaturedPlayList, WhatsApp } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import UploadInput from "../../../../components/UploadInput";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom";

const SMSSettings = ({
  context,
  future_date,
  setFutureDate,
  setOpenSendSmsDialog,
  getSMSReview,
  templates,
  selected_template,
  setSelectedTemplate,
  setMessage,
  send_date,
  setSendDate,
  selected_time,
  setSelectedTime,
  hours,
  message,
  checkedSMS,
  checkedWP,
  shortCode,
  fileHandlerProp,
  open_send_sms_dialog
}) => {
  const messageRef = useRef(null)
  return (
    <Column className="xs-12 sm-12 md-8">
      <Column className="xs-12 sm-12 md-12 lg-2 mt-1">
        <h3>{context.t(`sms.headings.content`)}</h3>
      </Column>
      <Column className="xs-12 sm-12 md-12 lg-8">
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={future_date}
              onChange={(e) => {
                setFutureDate(e.target.checked);
              }}
            />
          }
          label={
            <FCLabel>
              <i className="material-icons">filter_list</i>{" "}
              <span>{context.t(`sms.send.futureDate`)}</span>
            </FCLabel>
          }
        />
      </Column>
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
          <Select
            label={context.t(`sms.send.template`)}
            items={[...templates]}
            selected={selected_template?.name ?? ""}
            labelKey="name"
            valueKey="name"
            returnFullObject
            handler={(template) => {
              setSelectedTemplate(template);
              setMessage(template.content);
            }}
          />
        </Column>
        {future_date && (
          <>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <DatePickerInput
                label={context.t(`sms.send.sendDate`)}
                value={send_date ? new Date(send_date).toISOString() : ""}
                onChange={(date) => {
                  setSendDate(new Date(date).toISOString());
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <TimeSelect
                fullTime={true}
                label={context.t(`['settings/hours'].startInputLabelHour`)}
                type="hour"
                value={
                  selected_time !== null ? selected_time.split(":")[0] : ""
                }
                fullWidth={true}
                size="medium"
                companyShiftTime={true}
                startHour={0}
                endHour={24}
                // minuteStep={5}
                handler={(value) => {
                  setSelectedTime(
                    selected_time !== null
                      ? `${value}:${selected_time.split(":")[1]}`
                      : `${value}:00`
                  );
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <TimeSelect
                label={context.t(`['settings/hours'].startInputLabelMinute`)}
                type="minute"
                value={
                  selected_time !== null ? selected_time.split(":")[1] : ""
                }
                selectedHour={selected_time}
                lastHour={hours[hours.length - 1]}
                fullWidth={true}
                size="medium"
                handler={(time) => {
                  setSelectedTime(
                    selected_time !== null
                      ? `${selected_time.split(":")[0]}:${time}`
                      : null
                  );
                }}
              />
            </Column>
          </>
        )}
      </Grid>
      <Input
        label={context.t(`sms.send.messageInputLabel`)}
        inputRef={messageRef}
        autoFocus
        multiline={true}
        rows={10}
        value={message ?? ""}
        helperText={CalculateMessageCharCount(
          message,
          `${checkedSMS && checkedWP
            ? "1"
            : !checkedSMS && checkedWP
              ? "3"
              : "1"
          } `
        )}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Button
            title={context.t(`sms.send.autoText.nameSurname`)}
            // style={{ border: "1px solid green" }}
            size="xs"
            outlined
            textColor="primary"
            onClick={() => {
              const cursorPosition = messageRef.current.selectionStart;

              const addedMessage = `${message.slice(
                0,
                cursorPosition
              )} ${context.t(`sms.send.autoTextShortCode.nameSurname`)} ${message.slice(cursorPosition)}`;

              setMessage(addedMessage);

              messageRef.current.focus();
            }}
          />
          <Button
            className="ml-1"
            title={context.t(`sms.send.autoText.shortCode`)}
            outlined
            size="xs"
            textColor="purple"
            disabled={shortCode === ""}
            onClick={() => {
              const cursorPosition = messageRef.current.selectionStart;

              const addedMessage = `${message.slice(
                0,
                cursorPosition
              )} ${context.t(`["sms/filter"].shortCodeButtonMessage`).replace("%s", "").replace("%v", shortCode)} ${message.slice(
                cursorPosition
              )}`;

              setMessage(addedMessage);

              messageRef.current.focus();
            }}
          />
        </div>

        {checkedWP && <UploadInput fileHandlerProp={fileHandlerProp} />}
      </div>

      <Button
        className="mt-2"
        icon="send"
        title={context.t("menuItems.sms.children.sendGroup")}
        backgroundColor="primary"
        textColor="white"
        fullWidth={true}
        disabled={
          message.length === 0 ||
          !CalculateMessageCount(
            message,
            `${checkedSMS && checkedWP
              ? "1"
              : !checkedSMS && checkedWP
                ? "3"
                : "1"
            } `
          )
        }
        onClick={() => {
          getSMSReview();
        }}
      />
      <AlertDialog
        title={context.t(`sms.send.check.title`)}
        open={open_send_sms_dialog}
        disableBackdropClick={false}
        buttons={[
          {
            title: context.t(`sms.send.check.confirmButtonTitle`),
            onClick: () => getSMSReview(),
          },
          { title: context.t(`sms.send.check.discardButtonTitle`) },
        ]}
        closeHandler={() => {
          setOpenSendSmsDialog(false);
        }}
      >
        <Alert severity="warning">
          {context.t(`sms.send.check.alertText`)}
        </Alert>
      </AlertDialog>
    </Column>
  );
};


const Group = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [search_key, setSearchKey] = useState("");
  const [loaded, setLoaded] = useState(false);
  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );
  const [pagination, setPagination] = useState({
    total_page: null,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });

  const [groups, setGroups] = useState([]);
  const [available_credit, setAvailableCredit] = useState(null);

  const [selected_groups, setSelectedGroups] = useState([]);
  const [excepted_groups, setExceptedGroups] = useState([]);
  const [all_groups, setAllGroups] = useState(false);
  const [message, setMessage] = useState("");
  const [send_date, setSendDate] = useState(new Date());
  const [selected_time, setSelectedTime] = useState(null);
  const [hours, setHours] = useState([
    ...BetweenTimeGenerator(context.state.company_shift),
  ]);
  const [future_date, setFutureDate] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [selected_customers, setSelectedCustomers] = useState([]);
  const [selected_customer_allpage, setSelectedCustomersAllPage] = useState([]);

  const [templates, setTemplates] = useState([]);
  const [selected_template, setSelectedTemplate] = useState(null);

  const [customer_key, setCustomerKey] = useState("");

  const [new_group, setNewGroup] = useState({
    edited: false,
    selected_group: null,

    name: null,

    pagination: {
      total_page: null,
      page: 1,
      onClick: (page) => setPages(page),
    },

    select_all_customers: false,
  });

  const setPages = (page) => {
    setCustomers((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        page: page,
      }
    }))
  }

  const [open_new_group_dialog, setOpenNewGroupDialog] = useState(false);
  const [open_send_sms_dialog, setOpenSendSmsDialog] = useState(false);

  const [open_sms_review_dialog, setOpenSmsReviewDialog] = useState(false);
  const [total_number, setTotalNumber] = useState(null);
  const [total_sms_credit, setTotalSmsCredit] = useState(null);
  const [total_wp_credit, setTotalWpCredit] = useState(null);

  const [shortCode, setShortCode] = useState(null);

  const [sender, setSender] = useState("");

  // Checkbox for sms and wp
  const [checkedSMS, setCheckedSMS] = useState(true);
  const [checkedWP, setCheckedWP] = useState(true);

  // whatsapp or sms
  const [api_key, setApiKey] = useState("");

  // WPState and WP phone
  const [wp_state, setWPState] = useState(false);
  const [wp_phone, setWPPhone] = useState(null);

  const [send_file, setSendFile] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const updatePage = (newPage) => {
    searchParams.set('page', newPage);
    history.push({ search: searchParams.toString() });
  };


  const fileHandlerProp = (value) => {
    setSendFile(value);
  };

  // checking dor wp accounts
  const checkWp = async () => {
    await Axios.get(`${context.api_endpoint}/company/get/wp/message/settings`)
      .then(async (ress) => {
        if (ress.data.data.device_key !== "" && ress.data.data.device_key) {
          await Axios.post(
            `${context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                setWPState(ress.data.is_connect);
                setWPPhone(ress.data.data.device_number);
                setCheckedWP(true);
              }
            })
            .catch(() => {
              toast.warn(context.t(`["sms/group"].connectionWarnToast`));
              setWPState(false);
              setWPPhone(null);
              setCheckedWP(false);
            });
        } else {
          setCheckedWP(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const getGroups = () => {
    Axios.get(
      `${context.api_endpoint}/company/group/list`,
      {
        params: {
          page: pagination.page ? pagination.page : 1,
          name: debouncedValue ? debouncedValue : null, // debouncedValue kullanılıyor
        }
      }
    )
      .then(({ data }) => {
        setLoaded(true);
        setGroups([...data.data.records]);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          page: data.data.page,
        });
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          history.push("/wallet");
        }
      });
  };

  const [debouncedValue, setDebouncedValue] = useState('');

  // Debounce işlemi
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(customer_key);
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [customer_key]);


  useEffect(() => {
    getGroups();
  }, [debouncedValue, pagination.page]);


  // const searchCustomersByKey = async (page, key) => {
  //   await Axios.get(`${context.api_endpoint}/company/customers`, {
  //     params: {
  //       page: new_group.pagination.page,
  //       key: key === null || key === " " || key === undefined ? "" : key
  //     },
  //   }).then(({ data }) => {
  //     setCustomers([...data.data.records]);
  //   });
  // };

  const getCustomers = () => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: {
        page: new_group.pagination.page,
        key: debouncedValueKey ? debouncedValueKey : null,
      },
    }).then(({ data }) => {
      setLoaded(true);
      setCustomers([...data.data.records]);
    }).catch((error) => {
      console.error(error);
    });
  };
  const [debouncedValueKey, setDebouncedValueKey] = useState('');

  // Debounce işlemi
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValueKey(search_key); // 800ms sonra debouncedValue güncellenir
    }, 800);

    // Temizleme: Yeni giriş olduğunda önceki `setTimeout` iptal edilir
    return () => {
      clearTimeout(handler);
    };
  }, [search_key]);

  useEffect(() => {
    getCustomers()
  }, [debouncedValueKey]);

  // Pagination değiştiğinde müşteri listesini güncelle
  useEffect(() => {
    if (loaded) { // İlk yükleme değilse
      getCustomers();
    }
  }, [new_group.pagination.page]);

  const addNewGroup = () => {
    Axios.post(`${context.api_endpoint}/company/group/insert`, {
      name: new_group.name,
      all: new_group.select_all_customers,
      customers_id: [...selected_customers.map((customer) => customer.id)],
    })
      .then((response) => {
        toast.success(context.t(`['sms/group'].newGroup.successToast`));
        setSelectedCustomers([]);
        setOpenNewGroupDialog(false);
        getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const sendSMS = () => {
    let appointment_date = null;
    if (future_date === true) {
      let appointment_time = selected_time.split(":");
      appointment_date = `${moment(send_date).format("YYYY-MM-DD")}T${appointment_time[0]
        }:${appointment_time[1]}:00Z`;
    }
    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error(context.t(`["sms/filter"].sendFilteredSmsErrorToast`));
    }

    const formData = new FormData();
    formData.append("all", all_groups);
    selected_groups.forEach((group) => {
      formData.append("group_id", group.id);
    });
    formData.append("except", []);
    formData.append("message", message);
    formData.append("sms_prefer", sms_prefer);

    formData.append("file", send_file);

    future_date
      ? formData.append("send_date", appointment_date)
      : formData.append("send_date", "");

    Axios.post(`${context.api_endpoint}/company/group/send/sms`, formData)
      .then(({ data }) => {
        if (data.status === 200 || data.status === 201) {
          toast.success(data.message);
          setStep(0);
          setSelectedGroups([]);
          setExceptedGroups([]);
          setAllGroups(false);
          setMessage("");
          setSendDate(new Date());
          getGroups();
          history.push("/sms");
          getBalance();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getGroupDetail = () => {
    Axios.get(`${context.api_endpoint}/company/group/detail`, {
      params: {
        groupid: new_group.selected_group.id,
      },
    }).then(({ data }) => {
      setNewGroup({
        ...new_group,
        name: data.data.name,
      });
    });
  };

  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        // setWpBalance(data.data.data.wp_credit);
        context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };
  const getGroupCustomers = () => {
    Axios.get(`${context.api_endpoint}/company/group/customer/list`, {
      params: {
        groupid: new_group.selected_group.id,
        page: -1,
      },
    }).then(({ data }) => {
      setSelectedCustomers(data.data);
    });
  };

  const updateGroup = async () => {
    await Axios.put(`${context.api_endpoint}/company/group/update`, {
      group_id: new_group.selected_group.id,
      name: new_group.name,
      all: new_group.select_all_customers,
      customers_id: [...selected_customers.map((customer) => customer.id)],
    })
      .then(({ data }) => {
        toast.success(
          context.t(`['sms/group'].newGroup.edit.updateSuccessToast`)
        );
        setStep(0);
        setSearchKey("");
        setGroups([]);
        setSelectedGroups([]);
        setExceptedGroups([]);
        setAllGroups(false);
        setMessage("");
        setNewGroup({
          ...new_group,
          name: "",
        });
        setOpenNewGroupDialog(false);
        getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const deleteGroup = () => {
    Axios.post(`${context.api_endpoint}/company/group/delete`, {
      group_id: new_group.selected_group.id,
    })
      .then(async ({ data }) => {
        toast.success(
          context.t(`['sms/group'].newGroup.edit.deleteSuccessToast`)
        );
        setStep(0);
        setSearchKey("");
        setGroups([]);
        setSelectedGroups([]);
        setExceptedGroups([]);
        setAllGroups(false);
        setMessage("");
        setOpenNewGroupDialog(false);
        setSelectedCustomers([]);
        setNewGroup({
          ...new_group,
          edited: false,
          selected_group: false,
          select_all_customers: false,
          name: "",
          pagination: {
            ...new_group.pagination,
            page: 1,
          },
        });
        getGroups();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getTemplates = () => {
    Axios.get(`${context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        setTemplates([...data.data]);
      }
    );
  };

  const getMessageSettings = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        setAvailableCredit(`${data.data.credit} SMS`);
        setSender(data.data.settings.sender);
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          setCheckedSMS(false);
        }
      }
    );
  };

  const getSMSReview = () => {
    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0) {
      return toast.error(context.t(`["sms/filter"].sendFilteredSmsErrorToast`));
    }

    /*  const formData = new FormData();
    formData.append("all", all_groups);
    selected_groups.forEach((group) => {
      formData.append("group_id", group.id);
    });
    formData.append("except", []);
    formData.append("message", message);
    formData.append("sms_prefer", sms_prefer); */

    Axios.post(`${context.api_endpoint}/company/group/send/sms/preview`, {
      all: all_groups,
      group_id: [...selected_groups.map((group) => group.id)],
      except: [...excepted_groups.map((group) => group.id)],
      message: message,
      sms_prefer,
    })
      .then((response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_sms_credit);
          setTotalWpCredit(response.data.data.total_wp_credit);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getShortcode = () => {
    Axios.get(`${context.api_endpoint}/company/shortcode/list/keyword`)
      .then((response) => {
        if (response.status === 200) {
          setShortCode(response.data.data.keyword);
        }
      })
      .catch((e) => {
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  // AddGroupDialog

  useEffect(() => {
    new_group.edited && getCustomers();
    new_group.edited && getGroupDetail();
    new_group.edited && getGroupCustomers();
  }, [new_group.edited]);

  const [customerName, setCustomerName] = React.useState(null);

  const allowDrop = (e) => {
    e.preventDefault();
  };
  const drag = (e, customer) => {
    e.dataTransfer.setData("customer", JSON.stringify(customer));
  };
  const drop = (e) => {
    e.preventDefault();
    let data = JSON.parse(e.dataTransfer.getData("customer"));

    let isCustomer = selected_customers.find(
      (_customer) => _customer.id === data.id
    );

    !isCustomer &&
      data &&
      !new_group.select_all_customers &&
      setSelectedCustomers([...selected_customers, data]);
  };

  const onSelectHandler = (e, customer) => {
    if (e.target.checked) {
      setSelectedCustomers([...selected_customers, customer]);
    } else {
      setSelectedCustomers([
        ...selected_customers.filter(
          (_customer) => _customer.id !== customer.id
        ),
      ]);
    }
  };

  useEffect(() => {
    getGroups();
    getCustomers();
    getTemplates();
    getMessageSettings();
    getShortcode();
    checkWp();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="sms">
      <AlertDialog
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            {new_group.edited === false
              ? context.t(`['sms/group'].newGroup.title`)
              : new_group.name === ""
                ? "ㅤㅤㅤㅤㅤㅤ"
                : new_group.name}
            {new_group.edited && (
              <Button
                title={context.t(
                  `['sms/group'].newGroup.edit.deleteGroupButtonTitle`
                )}
                backgroundColor="red"
                textColor="white"
                onClick={() => {
                  deleteGroup();
                }}
              />
            )}
          </div>
        }
        open={open_new_group_dialog}
        disableBackdropClick={false}
        maxWidth="md"
        fullWidth={true}
        buttonDisabledTimeout
        buttons={[
          {
            title: new_group.edited
              ? context.t(`['sms/group'].newGroup.edit.editButtonTitle`)
              : context.t(`['sms/group'].newGroup.createButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            disabledHandleClose: true,
            onClick: () => {
              if (new_group.name !== null || new_group.name !== "") {
                new_group.edited ? updateGroup() : addNewGroup();
              } else {
                toast.error(context.t(["sms/group"].newGroup.emptyGroupName));
              }
            },
          },
          {
            title: context.t(`['sms/group'].newGroup.cancelButtonTitle`),
            icon: "close",
            textColor: "red",
            backgroundColor: "red-opacity",
          },
        ]}
        closeHandler={() => {
          setCustomerName(null);
          setOpenNewGroupDialog(false);
          setSelectedCustomers([]);
          setNewGroup({
            ...new_group,
            edited: false,
            selected_group: false,
            select_all_customers: false,
            name: "",
            pagination: {
              ...new_group.pagination,
              page: 1,
            },
          });
        }}
      >
        <Divider
          color="primary"
          style={{
            backgroundColor: "#3189ec",
            marginBottom: "20px",
            height: "2px",
          }}
        />
        <Grid className="no-gutters-row">
          <Column className="xs-12 sm-12 md-12">
            <TextField
              required
              style={{
                border: "1px solid #EAEAEA",
                borderRadius: "10px",
                width: "99%",
                marginLeft: "10px",
                marginBottom: "15px",
              }}
              variant="outlined"
              label={context.t(`['sms/group'].newGroup.groupName`)}
              value={new_group.name ? new_group.name : ""}
              onChange={(e) => {
                setNewGroup({
                  ...new_group,
                  name: e.target.value,
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Card>
              <CardContent>
                <CustomerContainer onDragOver={allowDrop}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "5px 0px",
                    }}
                  >
                    <h3>
                      {context.t(`['sms/group'].newGroup.customersTitle`)}
                    </h3>
                  </div>
                  <hr />
                  <TextField
                    label={context.t(`['sms/group'].newGroup.searchCustomer`)}
                    placeholder={context.t(
                      `['sms/group'].newGroup.searchCustomerDescription`
                    )}
                    variant="outlined"
                    value={search_key ?? ""}
                    style={{
                      border: "1px solid #EAEAEA",
                      borderRadius: "10px",
                      width: "99%",
                    }}
                    onChange={(e) => {
                      const { value } = e.target;
                      setSearchKey(value);
                      setNewGroup({
                        ...new_group,
                        pagination: {
                          ...new_group.pagination,
                          page: 1,
                        },
                      });
                    }}
                  />
                  {customers.length > 0 ? (
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignContent: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={new_group.select_all_customers ? true : false}
                        onChange={async () => {
                          setNewGroup({
                            ...new_group,
                            select_all_customers:
                              new_group.select_all_customers,
                          });
                          setSelectedCustomers(
                            new_group.select_all_customers ? [] : [...customers]
                          );
                        }}
                      //onChange={(e) => onSelectHandler(e, customer)}
                      />
                      <label
                        onClick={() => {
                          setNewGroup({
                            ...new_group,
                            select_all_customers:
                              !new_group.select_all_customers,
                          });
                          setSelectedCustomers(
                            new_group.select_all_customers ? [] : [...customers]
                          );
                        }}
                        style={{
                          color: new_group.select_all_customers
                            ? "#ff006a"
                            : "#3189ec",
                        }}
                      >
                        {context.t(
                          `['sms/group'].newGroup.${new_group.select_all_customers
                            ? "unselectAllButtonTitle"
                            : "selectAllButtonTitle"
                          }`
                        )}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  {customers.length > 0 ? (
                    customers.map((customer, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignContent: "center",
                          gap: "5px",
                        }}
                      >
                        <input
                          key={customer.id}
                          id={customer.id}
                          type="checkbox"
                          // disabled={new_group.select_all_customers}
                          checked={
                            new_group.select_all_customers
                              ? true
                              : selected_customers.find(
                                (_customer) => _customer.id === customer.id
                              )
                                ? true
                                : false
                          }
                          onChange={(e) => {
                            onSelectHandler(e, customer);
                          }}
                        />
                        <label
                          htmlFor={customer.id}
                          draggable={true}
                          onDragStart={(e) => drag(e, customer)}
                        >
                          {customer.full_name} - {customer.phone}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>
                      {context.t(`['sms/group'].newGroup.notFoundCustomer`)}
                    </p>
                  )}
                </CustomerContainer>
                <PaginationContainer>
                  {/* <Pagination
              style={{ display: "flex", justifyContent: "center" }}
              count={new_group.pagination.total_page}
              onChange={(e, value) => {
                console.log("value");
                getCustomers(value);
              }}
            /> */}
                  <div className="pagination p7">
                    <ul>
                      <button
                        disabled={new_group.pagination.page < 2}
                        onClick={() => {
                          setNewGroup({
                            ...new_group,
                            pagination: {
                              ...new_group.pagination,
                              page: new_group.pagination.page - 1,
                            },
                          });
                        }}
                      >
                        <li>
                          <ArrowBackIosIcon
                            className="icon-back"
                            fontSize="small"
                          />
                        </li>
                      </button>
                      <button
                        disabled={customers.length < 10}
                        onClick={() => {
                          setNewGroup({
                            ...new_group,
                            pagination: {
                              ...new_group.pagination,
                              page: new_group.pagination.page + 1,
                            },
                          });
                        }}
                      >
                        <li>
                          <ArrowForwardIosIcon fontSize="small" />
                        </li>
                      </button>
                    </ul>
                  </div>
                </PaginationContainer>
              </CardContent>
            </Card>
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Card style={{ height: "100%" }}>
              <CardContent style={{ height: "100%" }}>
                <CustomerContainer onDragOver={allowDrop} onDrop={drop}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      padding: "5px 0px",
                    }}
                  >
                    <h3>
                      {context.t(
                        `['sms/group'].newGroup.selectedCustomersTitle`
                      )}
                    </h3>
                    <IconButton
                      style={{ position: "absolute", right: "10px" }}
                      color="secondary"
                      title={context.t(
                        `['sms/group'].newGroup.deleteAllButtonTitle`
                      )}
                      disabled={selected_customers.length > 0 ? false : true}
                      onClick={() => {
                        setSelectedCustomers([]);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                  <hr />
                  {!new_group.select_all_customers &&
                    selected_customers.map(
                      (customer, index) =>
                        customer.name !== undefined &&
                        customer.surname !== undefined && (
                          <Chip
                            key={customer.id + "" + index}
                            style={{ margin: "10px" }}
                            label={`${customer.name} ${customer.surname}`}
                            onDelete={() => {
                              setSelectedCustomers([
                                ...selected_customers.filter(
                                  (_customer) => _customer.id !== customer.id
                                ),
                              ]);
                            }}
                          />
                        )
                    )}

                  {new_group.select_all_customers && (
                    <Alert
                      severity="success"
                      style={{ width: "100%", boxSizing: "border-box" }}
                    >
                      <b>{context.t(`['sms/group'].newGroup.alertBoldText`)}</b>
                    </Alert>
                  )}

                  {/*     {new_group.select_all_customers &&
                    selected_customers.map((customer, index) => (
                      <Chip
                        key={customer.id + "" + index}
                        style={{ margin: "10px" }}
                        label={`${customer.name} ${customer.surname}`}
                        onDelete={() => {
                          setState({
                            selected_customers: [
                              ...selected_customers.filter(
                                (_customer) => _customer.id !== customer.id
                              ),
                            ],
                          });
                        }}
                      />
                    ))} */}
                </CustomerContainer>
              </CardContent>
            </Card>
          </Column>
        </Grid>
      </AlertDialog>
      <Grid className="mb-2">
        {process.env.APP_NAME === "management" ? null : (
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{context.t(`["sms/filter"].smsMessageTitle`)}</StatsHeader>
                    <StatsValue>{sender}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        )}
        <Column className="xs-12 sm-12 md-auto">
          <StatsCardContainer>
            <Grid
              style={{ height: "100%", minHeight: 73 }}
              className="no-gutters-all"
            >
              <Column className="xs-12">
                <IconAlignContainer className="fc-green bg-green-opacity">
                  <WhatsApp
                    style={{
                      fontSize: "3em",
                    }}
                  />
                </IconAlignContainer>
              </Column>
              <Column className="xs-12 col-auto">
                <TextAlignContainer>
                  <StatsHeader>{context.t(`["sms/filter"].wpMessageTitle`)}</StatsHeader>
                  <StatsValue>{wp_phone}</StatsValue>
                </TextAlignContainer>
              </Column>
            </Grid>
          </StatsCardContainer>
        </Column>
        {process.env.APP_NAME !== "management" && (
          <Column className="xs-12 sm-12 md-auto">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sender === "" ? false : checkedSMS}
                    onChange={(e) => {
                      if (sender !== "") {
                        setCheckedSMS(e.target.checked);
                      }
                    }}
                    color="primary"
                  />
                }
                label={context.t(`sms.sendType.sendSMS`)}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedWP}
                    onChange={(e) => {
                      if (!wp_state) {
                        history.push("/settings/wp");
                      }
                      setCheckedWP(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={context.t(`sms.sendType.sendWP`)}
              />
            </FormGroup>
          </Column>
        )}
        {process.env.APP_NAME !== "management" && (
          <Column className="xs-12 sm-12 md-2">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-orange bg-orange-opacity">
                    <FeaturedPlayList
                      style={{
                        fontSize: "2em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{context.t(`sms.credit.sms`)}</StatsHeader>
                    <StatsValue>
                      {" "}
                      {context.state.balance?.data?.credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        )}
        <Column className="xs-12 sm-12 md-auto">
          <StatsCardContainer>
            <Grid
              style={{ height: "100%", minHeight: 73 }}
              className="no-gutters-all"
            >
              <Column className="xs-12">
                <IconAlignContainer className="fc-green bg-green-opacity">
                  <WhatsApp
                    style={{
                      fontSize: "3em",
                    }}
                  />
                </IconAlignContainer>
              </Column>
              <Column className="xs-12 col-auto">
                <TextAlignContainer>
                  <StatsHeader> {context.t(`sms.credit.wp`)}</StatsHeader>
                  <StatsValue>
                    {" "}
                    {context.state.balance?.data?.wp_credit}
                  </StatsValue>
                </TextAlignContainer>
              </Column>
            </Grid>
          </StatsCardContainer>
        </Column>
      </Grid>

      {(sender === "" || sender === null) && !wp_state ? (
        <>
          <UndefinedSenderAlert />
          <WpUndefinedAlert />
        </>
      ) : (
        <></>
      )}

      <Grid>
        {step === 0 ? (
          <Column className="xs-12 sm-12 md-6">
            <Grid className="no-gutters-all mb-1">
              <Column className="xs-12 sm-12 md-9">
                <h3>{context.t(`['sms/group'].group.title`)}</h3>
              </Column>
              <Column className="xs-12 sm-12 md-3">
                <Button
                  title={context.t(`['sms/group'].newGroupButtonTitle`)}
                  icon="add"
                  backgroundColor="primary"
                  textColor="white"
                  fullWidth
                  size="sm"
                  onClick={() => {
                    setOpenNewGroupDialog(true);
                  }}
                />
              </Column>
            </Grid>
            <Grid className="no-gutters-row">
              <Column className="xs-12 sm-12 md-6">
                <Input
                  label={context.t(`['sms/group'].group.searchInputLabel`)}
                  placeholder={context.t(
                    `['sms/group'].group.searchInputPlaceholder`
                  )}
                  onChange={async (e) => {
                    const { value } = e.target;
                    setPagination((prev) => ({ ...prev, page: 1 }))
                    updatePage(1)
                    setCustomerKey(value);
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-6">
                <Button
                  title={
                    all_groups
                      ? context.t(`['sms/group'].group.unselectAll`)
                      : context.t(`['sms/group'].group.selectAll`)
                  }
                  textColor={all_groups ? "red" : "green"}
                  className="elevation-1"
                  size="lg"
                  fullWidth
                  icon={all_groups ? "close" : "check"}
                  onClick={() => {
                    setAllGroups(!all_groups);
                    setSelectedGroups(!all_groups ? [...groups] : []);
                    setExceptedGroups([]);
                  }}
                />
              </Column>
            </Grid>

            <Table
              refreshAction={() => getGroups(pagination.page)}
              loaded={loaded}
              headings={{
                name: {
                  label: context.t(`['sms/group'].group.headings.groupName`),
                },
                customer_count: {
                  label: context.t(
                    `['sms/group'].group.headings.customerCount`
                  ),
                },
                _: { label: context.t(`component.actionHeadingText`) },
              }}
              rows={groups}
              pagination={pagination}
              buttons={[
                {
                  textColor: "green",
                  icon: "check",
                  backgroundColor: "green-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    all_groups ||
                    (selected_groups.find((group) => group.id === row.id)
                      ? true
                      : false),
                  onClick: (row) => {
                    setSelectedGroups([...selected_groups, row]);
                  },
                },
                {
                  icon: "block",
                  textColor: "red",
                  backgroundColor: "red-opacity",
                  fullWidth: true,
                  pushEffect: true,
                  disabled: (row) =>
                    !all_groups ||
                    (excepted_groups.find((excepted) => excepted.id === row.id)
                      ? true
                      : false),
                  onClick: (row) => {
                    setExceptedGroups([...excepted_groups, row]);
                  },
                },
                {
                  title: context.t(
                    `['sms/group'].newGroup.groupEditButtonTitle`
                  ),
                  icon: "edit",
                  textColor: "blue",
                  backgroundColor: "blue-opacity",
                  fullWidth: true,
                  onClick: (row) => {
                    setOpenNewGroupDialog(true);
                    setNewGroup({
                      ...new_group,
                      edited: true,
                      selected_group: row,
                    });
                  },
                },
              ]}
            />
          </Column>
        ) : (
          <SMSSettings
            context={context}
            future_date={future_date}
            setFutureDate={setFutureDate}
            setOpenSendSmsDialog={setOpenSendSmsDialog}
            getSMSReview={getSMSReview}
            templates={templates}
            selected_template={selected_template}
            setSelectedTemplate={setSelectedTemplate}
            setMessage={setMessage}
            send_date={send_date}
            setSendDate={setSendDate}
            selected_time={selected_time}
            setSelectedTime={setSelectedTime}
            hours={hours}
            message={message}
            checkedSMS={checkedSMS}
            checkedWP={checkedWP}
            shortCode={shortCode}
            fileHandlerProp={fileHandlerProp}
            open_send_sms_dialog={open_send_sms_dialog}
          />
        )}

        <Column className={`xs-12 sm-12 ${step === 0 ? "md-6" : "md-4"}`}>
          <Column className="xs-12 sm-12 md-12">
            <h3>{context.t(`['sms/group'].approved.title`)}</h3>
            {!all_groups ? (
              <ChipContainer>
                {selected_groups.length > 0 ? (
                  selected_groups.map((group) => (
                    <Chip
                      key={group.id}
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-green-opacity fc-green fw-500"
                      label={group.name}
                      onDelete={() => {
                        setSelectedGroups([
                          ...selected_groups.filter(
                            (_group) => _group.id !== group.id
                          ),
                        ]);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    color="warning"
                    style={{
                      width: "100%",
                      boxSizing: "border-box",
                      //backgroundColor: "orange",
                    }}
                  >
                    <b>
                      {context.t(
                        `['sms/group'].approved.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {context.t(`['sms/group'].approved.notFound.alertText`)}
                  </Alert>
                )}
              </ChipContainer>
            ) : (
              <Alert
                severity="success"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <b>{context.t(`['sms/group'].approved.alertBoldText`)}</b>
              </Alert>
            )}
          </Column>
          {all_groups && (
            <Column className="xs-12 sm-12 md-12 mt-2">
              <h3>{context.t(`['sms/group'].excepted.title`)}</h3>
              <ChipContainer>
                {excepted_groups.length > 0 ? (
                  excepted_groups.map((group) => (
                    <Chip
                      key={group.id}
                      style={{ height: 46, borderRadius: 5 }}
                      className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                      label={group.name}
                      onDelete={() => {
                        setExceptedGroups([
                          ...excepted_groups.filter(
                            (_group) => _group.id !== group.id
                          ),
                        ]);
                      }}
                    />
                  ))
                ) : (
                  <Alert
                    severity="info"
                    style={{ width: "100%", boxSizing: "border-box" }}
                  >
                    <b>
                      {context.t(
                        `['sms/group'].approved.notFound.alertBoldText`
                      )}
                    </b>
                    <br />
                    {context.t(`['sms/group'].approved.notFound.alertText`)}
                  </Alert>
                )}
              </ChipContainer>
            </Column>
          )}
          <Column className="xs-12 sm-12 md-12 mt-2">
            {step === 0 && (
              <Button
                className="mt-1"
                title={context.t(`['sms/group'].continueButtonTitle`)}
                backgroundColor="primary"
                textColor="white"
                disabled={selected_groups.length > 0 ? false : true}
                fullWidth
                pushEffect
                onClick={() => {
                  selected_groups.length > excepted_groups.length
                    ? setStep(1)
                    : toast.warning(context.t(`['sms/selective'].warningText`));
                }}
              />
            )}

            {step === 1 && (
              <Button
                className="mt-1"
                title={context.t(`['sms/group'].backToSelectButtonTitle`)}
                backgroundColor="primary-opacity"
                textColor="primary"
                fullWidth
                pushEffect
                onClick={() => setStep(0)}
              />
            )}
          </Column>
        </Column>
      </Grid>
      <SMSReview
        open={open_sms_review_dialog}
        closeHandler={() => {
          setOpenSmsReviewDialog(false);
        }}
        sendSMS={sendSMS}
        sms_content={message}
        total_number={total_number}
        total_sms_credit={context.state.balance?.data?.credit}
        in_use_sms_credit={total_sms_credit}
        total_wp_credit={context.state.balance?.data?.wp_credit}
        in_use_wp_credit={total_sms_credit}
        check_wp={checkedWP}
        check_sms={checkedSMS}
      />
    </AuthContainer>
  );
};

export default Group;

const CustomerContainer = styled.div`
  max-height: 600px;
  min-height: 350px;
  overflow-y: auto;
`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
