import {
  Home,
  AccountBalanceWallet,
  InsertInvitation,
  Receipt,
  LocalMall,
  Store,
  PeopleAlt,
  Layers,
  HowToReg,
  Sms,
  InsertChart,
  Settings,
  AccountBalance,
  ListAlt,
  NoteAdd,
  Storefront,
  PhotoLibrary,
  CalendarTodayOutlined,
  CalendarToday,
} from "@material-ui/icons";
import AppContext from "../context/store";
import { useContext } from "react";


export const FavoritePages = () => {
  const context = useContext(AppContext);
  const favorite_pages = [
    { name: "Müşteri Yönetimi", index: null },
    {
      name: context.t("menuItems.customers.children.active"),
      index: 1,
      icon: PeopleAlt,
      path: "/customers",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.customers.children.risky"),
      index: 2,
      icon: PeopleAlt,
      path: "/customers/passive",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.customers.children.rating"),
      index: 3,
      icon: PeopleAlt,
      path: "/customers/rating",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("['appointments/add'].createNewCustomerButtonTitle"),
      index: 4,
      icon: PeopleAlt,
      path: "/customers/add",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.customers.children.addBulkWithoutPlus"),
      index: 5,
      icon: PeopleAlt,
      path: "/customers/add/bulk",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.services.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.services.children.index"),
      index: 6,
      icon: Layers,
      path: "/services",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("scaffold.QAItems.createService"),
      index: 7,
      icon: Layers,
      path: "/services/add",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.services.children.requests"),
      index: null,
      icon: Layers,
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.services.children.requests"),
      index: 42,
      icon: Layers,
      path: "/services/requests",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.services.children.bounty"),
      index: 43,
      icon: Layers,
      path: "/services/bounty",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.packages.title"), index: null },
    {
      name: context.t("menuItems.packages.children.list"),
      index: 8,
      icon: LocalMall,
      path: "/packages/list",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.packages.children.sales"),
      index: 9,
      icon: LocalMall,
      path: "/packages/sales",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.packages.children.unbookedPackages"),
      index: 33,
      icon: LocalMall,
      path: "/packages/unbooked",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("scaffold.QAItems.createPackage"),
      index: 10,
      icon: LocalMall,
      path: "/packages/add",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.packages.children.salesAdd"),
      index: 11,
      icon: LocalMall,
      path: "/packages/sales/add",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.products.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.products.children.index"),
      index: 12,
      icon: Store,
      path: "/products",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.products.children.addWithoutPlus"),
      index: 13,
      icon: Store,
      path: "/products/add",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.accounting.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.accounting.children.income"),
      index: 14,
      icon: AccountBalance,
      path: "/accounting/income",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.accounting.children.expense"),
      index: 15,
      icon: AccountBalance,
      path: "/accounting/expense",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.accounting.children.expenseTypes"),
      index: 16,
      icon: AccountBalance,
      path: "/accounting/expense/types",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.accounting.children.credit"),
      index: 29,
      icon: AccountBalance,
      path: "/accounting/credit",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.accounting.children.debit"),
      index: 72,
      icon: AccountBalance,
      path: "/accounting/debit",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.accounting.children.debt"),
      index: 17,
      icon: AccountBalance,
      path: "/accounting/debt",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.employees.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.employees.children.index"),
      index: 18,
      icon: HowToReg,
      path: "/settings/employees",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.accounting.children.bounty"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("employees.createNewEmployee"),
      index: 20,
      icon: HowToReg,
      path: "/settings/employees/add",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.reports.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.reports.children.company"),
      index: 21,
      icon: InsertChart,
      path: "/reports/company",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.reports.children.staff"),
      index: 22,
      icon: InsertChart,
      path: "/reports/staff",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.reports.children.customer"),
      index: 23,
      icon: InsertChart,
      path: "/reports/customer",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.reports.children.service"),
      index: 24,
      icon: InsertChart,
      path: "/reports/service",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.reports.children.stock"),
      index: 25,
      icon: InsertChart,
      path: "/reports/stock",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.sms.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.sms.children.index"),
      index: 28,
      icon: Sms,
      path: "/sms/reports",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.sms.children.sendSelective"),
      index: 26,
      icon: Sms,
      path: "/sms/send/selective",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.sms.children.sendFilter"),
      index: 27,
      icon: Sms,
      path: "/sms/send/filter",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.sms.children.index"),
      path: "/sms/reports",
      icon: Sms,
      index: 56,
      auth: [1, 2, 3, 4]
    },
    {
      name: context.t("menuItems.sms.children.settings"),
      index: 32,
      icon: Sms,
      path: "/settings/sms",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.settings.title"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.profile.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.settings.children.index"),
      index: 30,
      icon: Settings,
      path: "/settings",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.platform.children.settings"),
      index: 39,
      icon: Settings,
      path: "/settings/platform-settings",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.settings.children.hours"),
      index: 31,
      icon: Settings,
      path: "/settings/hours",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.settings.children.branchAdd"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.settings.children.logs"),
      index: 34,
      icon: Settings,
      path: "/settings/logs",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.settings.children.giveAReferans"),
      index: 38,
      icon: Storefront,
      path: "/settings/give-referance",
      auth: [1, 2, 3, 4],
    },
    { name: "Dil Ayarları", index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.wallet.title"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.notify.title"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("dashboard.usageCard.licenseDetail"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("wallet.buyButtonTitle"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("wallet.sms_package"), index: null, auth: [1, 2, 3, 4] },
    { name: "Çıkış", index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.gallery.title"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.gallery.children.platform_photo"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.gallery.children.enterprise_gallery"), index: null, auth: [1, 2, 3, 4] },
    { name: context.t("menuItems.gallery.children.service_gallery"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.appointment.children.index"),
      index: 37,
      icon: CalendarToday,
      path: "/appointments",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.appointment.children.online"),
      index: 57,
      icon: CalendarToday,
      path: "/appointments/online",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("scaffold.QAItems.createAppointment"),
      index: 58,
      icon: CalendarToday,
      path: "/appointments",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.appointment.children.filter"),
      index: 59,
      icon: CalendarToday,
      path: "/appointments/list",
      auth: [1, 2, 3, 4],
    },
    { name: context.t("menuItems.receipt.title"), index: null, auth: [1, 2, 3, 4] },
    {
      name: context.t("menuItems.receipt.children.index"),
      index: 60,
      icon: Receipt,
      path: "/receipts",
      auth: [1, 2, 3, 4],
    },
    {
      name: context.t("menuItems.receipt.children.recordAdd"),
      index: 61,
      icon: Receipt,
      path: "/receipts/add",
      auth: [1, 2, 3, 4],
    },
  ];
  return favorite_pages;
}



export const favoritePage = (index) => {

  return FavoritePages().find((item) => item.index === parseInt(index));
};
