const langEN = {
  langText: "English",

  /**
   * İlk açılan pop-up içeriği
   */

  definePopup: {
    title: "We define the best features for you.",
    description:
      "In addition to the appointment calendar in your business, would you like to use our admission and accounting feature?",
    buttons: {
      approve: "Yes",
      cancel: "No",
    },
    succesMessage:
      "Your preference has been successfully saved. Please login again.",

    appTitleOnBrowser: "🕐 Manage Your Time Effectively! - "
  },

  /**
   * Giriş sayfasının bulunduğu key.
   * https://app.salonrandevu.com/login
   */
  login: {
    title: "Log in",
    mailPhoneInputLabel: "E-mail",
    passInputLabel: "Password",
    loginButtonTitle: "Log in",
    registerButtonTitle: "Haven’t you got an account yet? Register now!",
    forgotPassButtonTitle: "Forgot password / Reset password",
    loginWithGoogle: "Login with Google",
    loginwithApple: "Login with Apple",

    license: {
      // Modal
      title: "Your licence has expired!",
      description:
        "Your panel usage period is over. By purchasing the most suitable package for your business, you may continue to use your panel.   ",
      buttonTitleUK: " Call us now to buy package - UK: [number]",
      buttonTitleUSA: " Call us now to buy package - USA: [number]",

      standart: {
        type: "1 USER",
        title: "SOLO",
        items: [
          "Customer Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      essentials: {
        type: "5 USER",
        title: "Essentials",
        items: [
          "Customer Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      deluxe: {
        type: "9 USER",
        title: "Deluxe",
        items: [
          "Customer Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },

      premium: {
        type: "UNLIMITED",
        title: "PREMIUM",
        items: [
          "Customer Management",
          "Staff Management",
          "Stock Control",
          "Mail Management",
          "Advanced Reports",
          "Branch Management",
          "Business Gallery",
        ],
      },
    },

    downloadApp: "Download the app now by scanning the QR code.",

    loginErrorToast: "Operation failed! Please check your login information and try again.",
  },
  /**
   * login vaidate bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  validation: {
    enterField: "Please fill in the required fields.",
    wrongEmailOrPassword:
      "The email or phone number you entered is not linked to an account.",
    wrongPassword: "The password you entered is incorrect.",
  },
  /**
   * Kayıt sayfasının bulunduğu key.
   * https://app.salonrandevu.com/register
   */
  register: {
    title: "Register",
    trialText: " All features are FREE OF CHARGE for 7days!",
    noPaymentText: "No payment, No commitment, No cancellation fees.",
    privacyPolicyText: "I have read and accepted privacy policy",

    continueButtonTitle: "Continue",
    completeButtonTitle: "Complete registration",
    alreadyUserButtonTitle: "Already a member? Sign in!",

    companyNameInputLabel: "Company name",
    phoneInputLabel: "Phone number",
    campaingCodeInputLabel: " If you have a campaign code, enter please",
    staffNameInputLabel: "Official’s Name",
    staffSurnameInputLabel: "Official’s surname",
    staffMailInputLabel: "E-mail address",
    passwordInputLabel: "Password",
    verficationCode: "Verification code sent to number successfully!",
    registerError:
      "Registration could not be created! Please review your information and try again.",
    registerNavigate:
      "Your registration has been successfully created! You are directed to the panel.",
    registerErrorSecond:
      "The operation could not be completed! Please check your login information and try again.",
    registerAlreadyExist: "A gym record with this email already exists!",
    fieldCompany: "Business Name",
    fieldNumber: "Phone Number",
    fieldStaffName: "Authorized Person's First Name",
    fieldStaffSurname: "Authorized Person's Last Name",
    fieldStaffEmail: "Email Address",
    fieldStaffPassword: "Password",
    fieldVerificationCode: "Verification Code",
    securityWarning: "Accepting the privacy agreement is mandatory!",
    approveCode: "Approval Code",
    smsSubmit: "SMS Verification",
    finishRegister: "Complete Registration",
  },

  /**
   * Şifremi unuttum sayfasının bulunduğu key.
   * https://app.salonrandevu.com/forgot-pass
   */
  forgotPass: {
    title: "Forgot password & Reset",

    stepText: "STEP",
    firstStepText:
      "In this step, your email address linked to your employee profile should be entered in the field below. You will receive a message with verification code, by entering this code you can create your new password at the next step",
    secondStepText:
      "We have sent a message including verification code to the email address you entered. In the fields below, you can type your new password and update it by entering verification code sent to you.",

    continueButtonTitle: "Continue",
    backToHomeButtonTitle: "Return to Log in page",
    updatePassButtonTitle: "Update password",

    phoneInputLabel: "Phone Number",
    verificationCodeInputLabel: "Verification Code",
    newPassInputLabel: "New Password",

    verificationSuccessfullySentToast:
      "Verification code is successfully sent.",
    verificationErrorToast: "Verification code could not be sent.",
    validPhoneErrorToast: "Enter your full email address.",
    passwordSuccessfullyChangedToast:
      "Your password has been changed successfully.",
    warningStatusToast: "Incorrect, your password could not be changed.",
  },

  /**
   * Panel içerisindeki sol menü ve alt elemanların bulunduğu key.
   */
  menuItems: {
    dashboard: { title: "Dashboard" },
    profile: { title: "Profile Management" },
    wallet: { title: "Wallet Management" },
    todos: { title: "To Do Management" },
    notify: { title: "Payment Notification" },
    appointment: {
      title: "Appointment Management",
      children: {
        index: "Appointment Calendar",
        filter: "Appointment List",
        online: "Online Appointment Requests",
        repeat: "Repeat Appointments",
        add: "+ Create a New Appointment",
      },
    },
    receipt: {
      title: "Invoice Management",
      children: {
        index: "View Invoices",
        add: "+ Create New Invoices",
        recordAdd: "Create a Ticket for an Existing Client",
        newAdd: " Create a Ticket for a New Client",
      },
    },
    packages: {
      title: "Package Management",
      children: {
        sales: "List Sales",
        salesAdd: "+ Add New Sale",
        list: "List Packages",
        add: "+ Create Package",
        unbookedPackages: "Unbooked Packages",
      },
    },
    products: {
      title: "Stock Management",
      children: {
        index: "List Products",
        add: "+ Create New Product",
        addWithoutPlus: "Create New Product",
      },
    },
    customers: {
      title: "Customer Management",
      children: {
        active: "Active Customers",
        passive: "Inactive Customers",
        risky: "List of Risk",
        rating: "Evaluation Survey",
        add: "+ Create New Customer",
        addBulk: "+ Create Bulk Customers with Excel",
        addBulkWithoutPlus: "Create Bulk Customers with Excel",
      },
    },
    services: {
      title: "Service Management",
      children: {
        index: "List Services",
        add: "+ Create New Service",
        requests: "Service Name Requests",
        bounty: "Create Custom Bounty",
      },
    },
    employees: {
      title: "Staff Management",
      children: {
        index: "List Staff",
        add: "+ Create New Staff",
      },
    },
    sms: {
      title: "Message Management",
      children: {
        index: "Message Management",
        sendSelective: "Sent Selective Message",
        sendFilter: "Send Filtered Message",
        sendGroup: "Send Group Message",
        settings: "Sending Settings",
        blacklist: `${process.env.APP_NAME === "management" ? "Message" : "SMS"
          } Blacklist`,
        template: "Template Settings",
        wpSettings: "WhatsApp Settings",
      },
    },
    accounting: {
      title: "Accounting Management",
      children: {
        income: "Income Transaction",
        debt: "Debt Transaction",
        bounty: "Bonus Transaction",
        expense: "Expense Transaction",
        expenseTypes: "Expense Items",
        credit: "Credit Transaction",
        debit: "Debit Transaction",
      },
    },
    newReports: {
      title: "New Enhanced Reports",
      children: {
        company: "Company Reports",
        service: "Service Reports",
        stock: "Stock Reports",
        staff: "Staff Reports",
        customer: "Customer Reports",
        appointment: "Appointment Reports",
        settings: "Report Settings",
      },
    },
    reports: {
      title: "Advanced Reports",
      children: {
        company: "Enterprise Reports",
        service: "Service Reports",
        stock: "Product Reports",
        staff: "Staff Reports",
        customer: "Customer Reports",
        appointment: "Appointment Reports",
      },
    },
    settings: {
      title: "Enterprise Management",
      children: {
        index: "Enterprise Settings",
        hours: "Working Hours",
        gallery: "Enterprise Gallery",
        logs: "Transaction Records",
        branchAdd: "Create New Branch",
        giveAReferans: "Give a Referans",
      },
    },
    platform: {
      title: "Platform Management",
      children: {
        photo: "Showcase Photo",
        settings: "Platform Settings",
        category_settings: "Category Settings",
      },
    },
    gallery: {
      title: "Gallery Management",
      children: {
        platform_photo: "Showcase Photo",
        enterprise_gallery: "Enterprise Gallery",
        service_gallery: "Service Gallery",
      },
    },
    agreement: {
      settings: "Contract Settings",
    },
    support: {
      title: "Live Support",
    },
  },

  /**
   * Panel içerisindeki üst menü ve açılan popuplarının bulunduğu key.
   */
  scaffold: {
    translateViewHeading: "SYSTEM LANGUAGE",
    logsViewHeading: "SYSTEM LOGS",
    notificationViewHeading: "NOTIFICATIONS",
    quickActionsViewHeading: "QUICK TRANSACTIONS",

    logsViewAllButtonTitle: "View All",

    notificationFound: "New Notification!",
    notFoundNotification: "No notification yet!",

    QAItems: {
      createCustomer: "Create New Customer",
      createAppointment: "Create New Appointment",
      createReceipt: "Create New Invoice",
      createPackage: "Create New Package",
      createPackageSale: "New Package Sale",
      createService: "Create New Service",
      createEmployee: "Create New Staff",
      showIncomes: "View Income",
      showExpenses: " View Expenses",
      showDebts: " View Debt",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/branches
   */
  branches: {
    heroHeadingText: "Salon Appointment Multiple Branch Management",
    greetingText: "Welcome",
    greetingBottomText:
      "In the field blow, branches listed under your management.",
    greetingBottomText2:
      "You can either update log in information of any branch or log into relevant branch by using the buttons on the right-hand side of table.",

    licenseExpiredToast: "License Expired User",
    logoutButtonTitle: "Sign out",
    loginBranchButtonTitle: "Log in to Branch",
    editStaffButtonTitle: "Edit Information",
    completeEditingButtonTitle: "Complete Editing",
    discardEditingButtonTitle: "Cancel",
    createBranchButtonTitle: "Create Branch",

    tabBranchListTitle: "List Branches",
    tabBranchReportsTitle: " List Branches",
    tabBranchAddTitle: "+ Create New Branch",

    headings: {
      listTable: {
        id: "Enterprice Code",
        companyName: "Enterprise/Branch Name",
        staffFullName: "Official’s Full Name",
        staffPhoneNumber: " Official’s Phone Number",
        staffMail: "Official’s E-mail",
        licenseEndDate: "License Expiry Date",
        refresh: "Refresh",
      },
      reportsTable: {
        companyName: "Enterprise/Branch Name",
        staffCount: "Number of Staff",
        appointmentCount: "Number of Appointments",
        appointmentStatus: "Arrived / Expecting / Not Arrived",
        receiptCount: "Number of Cheques",
        serviceIncome: "Service Income",
        saleIncome: "Product Sale Income",
        staffBounty: "Bonus Paid",
        totalIncome: "Total Income",
        totalExpense: "Total Expense",
        totalCount: "Net Income",
      },
    },

    createBranch: {
      companyNameInputLabel: "Enterprise Name",
      staffNameInputLabel: "Official’s Name",
      staffSurnameInputLabel: "Official’s Surname",
      commonMailInputLabel: "E-Mail Address",
      commonPhoneInputLabel: "Phone Number",
      passInputLabel: "Password",
    },

    licenseExpiredLoginToast:
      " The license period of account you want to log in has expired.",
    licenseExpiredEditToast:
      "The license period of account you want to edit has expired.",
    staffUpdateToast: "Staff is successfully updated.",
    newBranchSuccessToast: "The new branch was successfully created.",
    staffUpdateSuccessToast: "Staff information has been successfully updated!",
    updateStaffTitle: " Edit Staff",

    inputLabels: {
      name: "Staff First Name",
      surname: "Staff Last Name",
      mail: "Staff Email",
      phone: "Staff Phone",
    },
  },

  /**
   * Şube yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/give-reference
   */
  referance: {
    createReferanceButtonTitle: "Give a Referance",
    createReferance: {
      companyNameInputLabel: "Enterprise Name",
      staffNameInputLabel: "Official’s Name and Surname",
      commonMailInputLabel: "E-Mail Address",
      commonPhoneInputLabel: "Phone Number",
    },
  },
  platformSettings: {
    approve: "Update Settings",
    appointmentApprovelCode: {
      title: "Appointment Verification Code",
      detail:
        "This is the setting for verification code for appointments received from the platform. When this setting is enabled, a verification code will be sent to the client when an appointment is received from the platform.",
    },
    appointmentApprovelCodeType: {
      title: "Appointment Verification Code Delivery Type",
      detail:
        "This setting is for delivery of verification codes for appointments received from the platform. When this setting is enabled, it indicates which delivery type will be used to send the verification code to the client when an appointment is received from the platform.",
    },

    appointmentConfirmationDeadline: {
      title: "Appointment Link Confirmation Deadline",
      detail:
        "This setting indicates the maximum number of hours before the customer can confirm the appointment link.",
      startInputLabel: "Appointment Confirmation Deadline(Hours)",
    },

    confirmationDeadlineSelect: {
      noLimit: "No Time Limit",
      hour: "Hour",
    },

  },
  /**
   * Genel Bakış sayfasının bulunduğu key.
   * https://app.salonrandevu.com/dashboard
   */
  dashboard: {
    slider: {
      sliderHeader: "Enhanced Reports Update Coming on April 7th!",
      sliderDate: "April 7, 2025",
      developmentFeatures: "Enhanced Features",
      openUpdates: "7 April Updates",
      sliderElement1: {
        title: "1. Enhanced Reporting with Artificial Intelligence",
        content: "Thanks to artificial intelligence technology, your reports are now becoming smarter and more predictive. By analyzing customer behaviors, we provide important insights for your business.",
      },
      sliderElement2: {
        title: "2. Real-Time Data Flow",
        content: "Now you can track all your reports instantly. You can monitor your salon's performance, appointment occupancy rates, and customer satisfaction in real-time.",
      },
      sliderElement3: {
        title: "3. Renewed Visual Graphics",
        content: "All your data is now presented with more understandable and visually rich graphics. You can analyze the state of your business at a glance.",
      },
      sliderElement4: {
        title: "4. Detailed Customized Reports",
        content: "With reports customized according to your business needs, you can make more detailed analyses and strategic decisions.",
      }
    },
    accountInstallCard: {
      heading: "You can follow your account setup from the steps below.!",
      description:
        "By clicking on relevant step, you can go to transaction page.",

      setShift: "Set up your working hours",
      createService: "Create your services",
      setServicePicked: " Choose the services provided by the staff",
      createCustomer: "Create your customers",
    },

    tutorialModal: {
      startHeading: "Start Account Setup",
      startDescription:
        "It is possible to complete your account setup in three basic steps by completing system requirements ",

      startTutorialButton: "Start Setup",
      dontShowAgainButton: "Do not show again",
    },

    enterpriseHead: "Enterprise Info",
    enterpriseDesc:
      "View your enterprise address with your and heck your remaining usage below.",

    businessURLCard: {
      heading:
        "By sharing your enterprise address with your customers, it is easy to manage your appointments!",
      inputLabel: "URL for Enterprise Appointment",
      openBrowserButton: "Open in Browser",
      copyLinkButton: "Copy the Link",
      copySuccessToast: "The link has been successfully copied!",
    },

    usageCard: {
      heading: "You can easily check your remaining usage below.",

      licenseDetail: "License Details",
      packageText: "PACKAGE",
      licenseRemaining: "days left",

      smsDetail: "Remaining Email credit",
    },

    stats: {
      appointment_info: {
        heading: "Appointment Info",
      },
      appointment: {
        heading: "Appointment Statistics",
        createdAppointments: "Already Booked Appointments",
        successAppointments: "Completed Appointments",
        closedAppointments: " Incomplete Appointments",
        occupancyRate: "Current Occupancy Rate",
      },

      customer: {
        heading: "Customer Statistics",
        newCustomers: "New Customers",
        oldCustomers: "Existing Customers",
        appointmentCustomers: "Customers with Appointment",
      },

      accounting: {
        heading: "Cash Statistics",
        income: "Total Income ",
        expense: "Total Expense ",
        debt: "Total Dept",

        licenseUpgradeText: "Upgrade Your Account!",
      },

      meta: {
        total: "Total Appointmens",
        arrived: "Arrived",
        notArrived: "Not Arrived",
        waiting: "Pending",
      },
    },

    waitingAppointments: {
      sectionHeading: "Online Appointment Requests",
      headings: {
        appointmentStartDate: "Appointment Time",
        customerFullName: "Customer Name",
        staffFullName: "Staff Name",
        serviceName: "Service Name",
      },

      buttons: {
        openReceiptTitle: "Open Invoice",
      },
    },

    cardTitle: {
      receipt_income_card_title: "Cash Statistics",
      appointment_calender_card_title: "Appointment Calendar",
      appointments_card_title: "Appointment Details",
      customers_card_title: "Customer Statistics",
    },

    cardDescription: {
      customers_description: "Customer Statistics",
      favorites_description:
        "You can create your own shortcuts by starring the tabs you use most often. You can also change the places of the cards with drag and drop.",
      receipt_income_card_description:
        "You can follow your daily, 7-day or 30-day cash stats on the income graph.",
      appointment_calender_card_description:
        "Click on the day you want to view the appointment details.",
      appointment_statistic_card_description:
        "You can report the number of appointments created on the platform, business link and panel through statistical data at daily, 7-day or 30-day intervals.",
      appointments_card_description:
        "You can track the number of appointments your business makes daily, 7 days or 30 days apart and whether your customers are coming or not.",
      online_appointment_request_card_description:
        "You can approve or reject the appointment by following the online appointment requests from your customers via your business link.",
    },
    PlatformLink: {
      btnText: "Appointment Reservation Page",
      title: "Appointment Reservation Page",
      description:
        "From this area, you can access the link required for your customers to create an appointment request for your business. From this link, your customers can create an appointment request by entering employee, service, day and time information.",
      copyLink: "Copy Link",
      goToLink: "Go to Page",
      linkCopied: "Link Copied.",
      Qr: "See QR Code",
    },
    report: {
      company: {
        typeCASH: "Cash",
        typeCARD: "Card",
        typeEFT: "Wire Transfer/EFT",
        totalIncome: "Total Revenue",
        noDataText:
          "The graph cannot be displayed because your Cash, Credit/Debit Card and Money Order/EFT incomes are not available.",
      },
    },

    appointmentAnalys: {
      platform: "SalonManagement.com",
      company: "Company Website",
      panel: "Created By Business",
      noDataText: "There are no pending open receipt requests.",
    },

    waitingAppointments2: {
      sectionHeading: "Online Appointment Requests",
      headings: {
        appointmentStartDate: "Appointment Time",
        customerFullName: "Customer",
        staffFullName: "Staff",
        serviceName: "Service to be taken",
      },
      noDataText: "You have no pending appointment requests.",

      buttons: {
        openReceiptTitle: "Open Invoice",
      },
    },

    appointmentList: {
      title: "Today's Appointments",
    },

    onlineAppointments: {
      headings: {
        appointmentStartDate: "Date - Hour",
        customerFullName: "Customer",
        customerPhone: "Phone",
        staffFullName: "Staff",
        serviceName: "Service",
        note: "Note",
        customerType: "Customer Type",
        statistics: "Statistics",
      },

      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },

    openAppointments: {
      headings: {
        date: "Date - Hour",
        customerFullName: "Customer",
        products: "Products",
        serviceName: "Service",
        totalPayment: "Total Payment",
      },
      title: "Open Tickets",
      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },
    creditors: {
      headings: {
        customerFullName: "Customer",
        type: "Type",
        paymentDate: "Scheduled Payment Date",
        totalPayment: "Total Payment",
      },

      noDataText: "There are no pending creditors.",
      title: "Creditors",
      approveButtonTitle: "Approve",
      rejectButtonTitle: "Reject",

      rejectConfirm: {
        title: "Do you confirm the appointment cancellation?",
        confirmButtonTitle: "Confirm",
        discardButtonTitle: "Discard",

        alertBoldText: "This action cannot be undone!",
        alertText:
          "If you confirm this request, you will no longer be able to access this appointment request.",
      },

      approveToast: "Appointment request successfully approved!",
      rejectToast: "Appointment request successfully rejected!",
    },
    birthDay: {
      headings: {
        customerFullName: "Customer",
        customerPhone: "Phone Number",
        birthDate: "Birth Date",
      },
      noDataText: "There are no upcoming birthdays.",
      title: "Upcoming Birthdays",
    },
    openReceiptOnSuccessToast:
      "Income belonging to appointment has been successfully created!",

    packageTitle: "First Buy Opportunity!",
    packageTitleSpan: "Discounted Prices !",
    packageBuyButton: "Buy Now",
    packageMoreInfo: "Learn More",
    packageCardDetails: [
      {
        title: "Gold Package",
        info: "For Businesses That Want to Manage Appointments",
        listItems: ["5 Staff Unlimited Appointment", "Multi Branch Management", "Special Customer Representative", "Business Gallery", "SMS Sending", "Special '250 SMS Gift' for New Members"],
        price: "₺599/month",
        oldPrice: "₺839",
        discount: "₺240 earnings",
      },
      {
        title: "Platinium Package",
        info: "For Businesses That Want to Manage Appointments, Receipts, Packages, Stock and Bonuses",
        listItems: ["7 Staff Unlimited Appointment", "Receipt Management", "Package Management", "Stock Management", "Bonus Management", "Special '500 SMS Gift' for New Members"],
        price: "₺849/month",
        oldPrice: "₺1199",
        discount: "₺350 earnings",
      },
      {
        title: "Diamond Package",
        info: "For Businesses That Want to Manage Contracts, Appointments, Pre-Accounting and Bonuses",
        listItems: ["10 Staff Unlimited Appointment", "E-Signature Add-On", "Contract Management", "Receipt Management", "Package Management", "Special '1000 SMS Gift' for New Members"],
        price: "₺1249/month",
        oldPrice: "₺1749",
        discount: "₺500 earnings",
      }
    ],

    packageTitleRenewal: "Renewal Package",
    packageBuyButtonRenewal: "Buy Now",
    packageMoreInfoRenewal: "Learn More",
    packageCardDetailsRenewal: [
      {
        title: "Gold Package",
        info: "For Businesses That Want to Manage Appointments",
        listItems: ["5 Staff Unlimited Appointment", "Multi Branch Management", "Special Customer Representative", "Business Gallery", "SMS Sending", "Special '250 SMS Gift' for New Members"],
        price: "₺839 / month",
      },
      {
        title: "Platinium Paket",
        info: "For Businesses That Want to Manage Appointments, Receipts, Packages, Stock and Bonuses",
        listItems: ["7 Staff Unlimited Appointment", "Receipt Management", "Package Management", "Stock Management", "Bonus Management", "Special '500 SMS Gift' for New Members"],
        price: "₺1199 / month",
      },
      {
        title: "Diamond Paket",
        info: "For Businesses That Want to Manage Contracts, Appointments, Pre-Accounting and Bonuses",
        listItems: ["10 Staff Unlimited Appointment", "E-Signature Add-On", "Contract Management", "Receipt Management", "Package Management", "Special '1000 SMS Gift' for New Members"],
        price: "₺1749 / month",
      }
    ],

  },

  /**
   * Randevu tablosun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments
   */
  appointments: {
    dayInputLabel: "Date of Appointments",
    beforeDayButtonTitle: "Previous Day",
    afterDayButtonTitle: "Next Day",
    today: "Back to Today",
    allStaff: "All Staff",
    dayWeekSelectLabel: "Day-Week-Monthly",
    customerStateSelectLabel: "Filter by Status",

    repeatButtons: {
      showAppointment: 'View Appointments',
      goAppointment: 'Go to Bill',
      cancelAppointment: 'Cancel Appointment',
      appointmentDate: 'Appointment Date',
      appointmentStatus: 'Appointment Status',
      startEnd: 'Start-End'
    },

    definitionGreenText: " Represents appointments with opened invoice.",
    definitionRedText:
      "Represents overdue appointments and unopened appointments invoice.",
    definitionYellowText:
      "The appointments whose date and time have not been passed are waiting to be opened.",
    definitionLightText: "Represents incoming and open tickets.",
    definitionPurpleText: "Arrived and processing is in progress.",
    definitionLinkText: "Represents appointments that were booked through an online link.",
    create: {
      title: "Quickly Create Appointment for Selected Time",
      completeButtonTitle: "Complete",
      discardButtonTitle: "Cancel",
      notInterval: "Interval not set",
      minutes: "minutes",

      selectedTimeInputLabel: "Appointment Date and Time",
      selectedStaffInputLabel: "Selection of Staff",
      selectedServiceInputLabel: "Service Provided by Staff",
      selectedPackageInputLabel: "Packages Provided by Staff",
      selectedCustomerInputLabel: "Customers to be given appointment",
      selectedCustomerInputPlaceholder: "Type customer’s name in this field.",
      createNewCustomerButtonTitle: "Create New Customer",
      createNewWalkinCustomerButtonTitle: "Create Unregistered Customer",

      closeAllDayTitle: "Close All Day",

      selecting_service: "Service Selection",
      selecting_package: "Package Selection",
      select_package_alert:
        "The selected package contains deleted services. Please change the selected package!",

      // Add unregistered customer
      sendSmsInputLabel: "Notification Email Sending",
      sendSmsTrue: "Send",
      sendSmsFalse: "Not Send",

      groupAppointment: "Bulk Appointment Creation",
      clockOff: "Clock Off",
      addEmployeeButtonTitle: "Add Employee + Service",

      noteInputLabel: "Note for Appointment (Optional)",

      onCreateErrorToast: "There is an existing appointment starting at the time you are trying to create!",
      selectStaffWarnToast: "Please select a staff member first to make an appointment!",

      onWarningToast: "Enter customer and service selection fields completely!",
      noDataText:
        "To create a Bulk Appointment, you must select at least 2 employees and services.",
    },

    detail: {
      title: "Appointment Details",
      deleteButtonTitle: "Delete Appointment",
      closeButtonTitle: "Close",
      allStaffSelectedTitle: "All",

      deleteButton: "Delete",
      editButton: "Edit",
      clockOffDetail: "Clock Shutdown Detail",
      clockOffDateInputLabel: "Closing Date/Time",
      clockOffStaffInputLabel: "Relevant Personnel",
      clockOffNoteInputLabel: "Explanation",

      appointmentTime: "Appointment Time (min)",
      appointmentTimeExtension: "Extend Appointment Time",
      appointmentalAlertMessage:
        "Your appointment time has been extended. You can view the current appointment time from the Appointment Calendar.",
      appointmentalWarningMessage: "Please enter the appointment time.",

      dateInputLabel: "Appointment Date/Time",
      serviceInputLabel: "Service to be Taken",
      staffInputLabel: "Staff",
      customerInputLabel: "Customer’s full name",
      customerPhoneInputLabel: "Customer’s Phone Number",
      noteInputLabel: "Note for Appointment",
      noteInputPlaceholder: "Enter Appointment Note",

      appointmentStatus: {
        title: "Appointment Status",
        attended: "Attended",
        notAttended: "Not Attended",
        accepted: "Approved",
        inProcess: "In Process",
        new: "New",

        changeStatusSuccessToast:
          "The appointment status has been successfully changed.",
      },

      appointmentStatusOnline: "Appointment Taken via Online Appointment",

      appointmentStatusTable: "Appointment Type",
      appointmentStatusOnlineList: "Online Appointment",
      appointmentStatusPlatformList: "By Business",

      statusAttended: "Attended",
      statusNotAttended: "Not Attended",
      statusAccepted: "Approved",
      statusInProcess: "In Process",
      statusNew: "New",
      repeatButtonTitle: 'Recurring Appointment',
      repeatFrequency: 'Frequency of Repetition',
      repeatCount: 'Number of Repetitions',
      logButtonTitle: "Go to appointment process logs",
      sendSms: "Send SMS",
      send: "Send",
      cancel: "Cancel",
    },

    deleteConfirm: {
      title: "Appointment Cancellation Confirmation",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This action is irreversible!",
      alertText:
        "In case of Email sending settings active and having remaining balance, a notification will be sent to customer about cancellation of appointment.",
    },

    pastCreateErrorToast:
      "You tried to create an appointment to a past date-time.",
    onMouseOverCardToast:
      "You can click on the card you want to see the details of the appointment.",
    insertToast: "The appointment was created successfully.",
    deleteToast: "The appointment was successfully deleted.",

    weeklyAppointmentsStaffErrorToast:
      "Please select an employee for weekly appointments.",
    weeklyAppointmentsDisplayErrorToast:
      "To create an appointment, view the daily appointments.",

    checkButton: "Understood",
    firstLoginAppointmentsTitle: "TO INFORM",
    firstLoginAppointmentsDescription:
      "Sample personnel information and appointment card have been created for you. You can update your personnel information and appointments by clicking on the available tabs.",

    updateAppointment: {
      title: "Do you want to change the appointment worker and/or time?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you update the current appointment, the employee and/or time information will change.",
      confirmButtonTitle: "Confirm",
      discardButtonTitle: "Discard",

      updateAppointmentSuccessToast:
        "The appointment has been successfully updated.",
    },

    orderStaffsButtonTitle: "Order Staff",

    rightButtonTooltip: "Move the calender to the right",
    leftButtonTooltip: "Move the calender to the left",

    createAppointmentButtonTitle: "Create Appointment",
    appointmentsComp: {
      green: "Green indicates available appointment times.",
      red: "Red represents previously scheduled appointment times.",
      purple:
        "Purple may indicate potential conflicts with existing appointments or closed hours when making an appointment.",
      gray: "Gray signifies employee details or hours closed on the appointment calendar.",
    },

    comunicateWithWhatsApp: "Contact via Whatsapp",
    tooltipExplanations: {
      selectStaff:
        "Here you can select the staff you want to see on the calendar",
      staffCount:
        "Here you can edit the number of people you want to appear on the page",
      appointmentRequest: "Your Online Appointment Requests",
      closeToGetAppointment: "Appointment Booking Closed",
      appointmentList: "Your Appointment List",
      appointmentTypes:
        "You can change the appointment calendar here to view daily, weekly or monthly",
    },
    staffSelect: {
      all: "All",
    },
    staffViewCount: {
      person3: "3 person view",
      person5: "5 person view",
      person7: "7 person view",
      person9: "9 person view",
      all: "All",
    },

    repetition: {
      dailyPeriod: "Every day",
      twoDayPeriod: "Every 2 days",
      threeDayPeriod: "Every 3 days",
      fourDayPeriod: "Every 4 days",
      fiveDayPeriod: "Every 5 days",
      weeklyPeriod: "Once a week",
      twoWeekPeriod: "Every 2 weeks",
      threeWeekPeriod: "Every 3 weeks",
      fourWeekPeriod: "Every 4 weeks",
      monthlyPeriod: "Every month",
      fortyFiveDayPeriod: "Every 45 days",
      twoMonthPeriod: "Every 2 months",
      threeMonthPeriod: "Every 3 months",
      sixMonthPeriod: "Every 6 months",
    },
    gotRepetition: "Recurring",
    cancelRepetition: "Cancel",
    repetitionFrequencyTitle: "Repetition Frequency",
    repetitionCountTitle: "Repetition Count",

    recurenctModalAccept: "Yes",
    recurenctModalRefuse: "No",
    recurenctModalCancel: "Cancel",
    recurenctModalWarn: "This is a recurring event. Would you like to delete the other recurring events as well?",

    servicesInReceiptWarn: "To open a receipt, you need to set the session interval for the services in the package.",
    serviceName: "Service Name",

    selectStaffWarn: "Please select staff.",
    onlineAppointmentTitle: "Online Appointment",

    closeAppointmentClockTimeSuccess: "Successfully Added.",
    closeAppointmentClockTimeError: "An error occurred",

    youtubeTitle: "Creating a New Appointment",
    youtubeTitle2: "Viewing Appointment History",
    youtubeTitle3: "Daily and Weekly Appointment Calendar",
    updateAppointmentWarn: "The end time cannot be earlier than the start time!",
    transferAppointmentWarn: "Transfer of closed hours cannot be made.",
    allDayClosedText: "Closed All Day",
    calendarTodayButtonTitle: "This Month",
    descriptionNoteTitle: "Note: ",
    saveButtonTitle: "Save",
    quitButtonWarn: "Please don't forget to save your changes!",
    routeToReceipt: "Go to Receipt",
    openReceipt: "Open Receipt",
    permissionWarn: "You must purchase the Premium package to use this feature.",
    historyTitle: "Appointment History",
    appliedPackageTitle: "Applied Package",
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/filter
   */
  "appointments/filter": {
    alertBoldText:
      "By using the fields below you can filter your appointments.",
    alertText:
      "In case of making any changes over the fields, this will be applied automatically.",

    startInputLabel: "Starting Date",
    endInputLabel: "Due to Date",
    staffInputLabel: "Staff Selection",
    allStaffsText: "All Staff",
    serviceInputLabel: "Service Name",
    customerInputLabel: "Customer’s Full Name",

    tabMenuLeft: {
      customerBill: "Customer Bills",
      customerAppointment: "Customer Appointments",
      customerDetails: "Customer Details",
      customerPhotos: "Customer Photos",
      updateCustomerDetailsTrue: "Customer Details successfully updated!",
      updateCustomerDetailsFalse: "Customer Detail Update Failed!",
    },

    statusInputLabel: "Appointment Status Selection",
    statusInputItems: [
      "All Appointments",
      "Upcoming Appointments",
      "Missed Appointments",
      "Pending Appointments",
      "Cancelled Appointments",
    ],

    filterTitle: "Date Filter",

    canceled: "Cancelled",
    situations: "Situations",

    headings: {
      appointmentStartDate: "Appointment Date",
      customerFullName: "Customer",
      serviceName: "Service",
      appointmentNote: "Appointment Note",
      staffFullName: "Staff",
      customerPhone: "Customer Phone Number",
      createdAt: "Appointment Booking Date",
    },
  },

  /**
   * Randevuları Filtrele sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/online
   */
  "appointments/online": {
    alertText:
      "In the field below if approved, online appointment requests are listed. Those appointments can only be confirmed/rejected by authorized person.",
    headings: {
      appointmentStartDate: "Date - Time",
      customerFullName: "Related Customer",
      customerPhone: "Customer Phone Number",
      staffFullName: "Staff",
      serviceName: "Service",
      note: "Customer’s Appointment Note",
      customerType: "Customer Type",
    },

    approveButtonTitle: "Confirm",
    rejectButtonTitle: "Reject",

    rejectConfirm: {
      title: "Do you confirm appointment cancellation?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "This action irreversible!",
      alertText: "You can no more access this appointment request if rejected.",
    },

    conflictConfirm: {
      title: "Do you want to confirm the appointment request ?",
      alertBoldText: "The appointment request conflicts with an existing appointment. Do you still want to confirm it ?",
    },

    approveToast: "The appointment request has been successfully confirmed!",
    rejectToast: "The appointment request has been successfully rejected!",
    blackListWarning:
      "After being blacklisted, appointment requests cannot be made with this number.",
    addToNumberBlackList: "Do you want to add this number to the blacklist?",
  },

  /**
   * Randevu Oluştur sayfasının bulunduğu key.
   * https://app.salonrandevu.com/appointments/add
   */
  "appointments/add": {
    staffInputLabel: "Staff Selection",
    serviceInputLabel: "Services Offered by the Staff",
    customerInputLabel: "Customer to Be Given Appointment",
    customerInputPlaceholder: "Type Customer’s Name in This Field",
    sendSmsInputLabel: "Sending Notification Email",
    sendSmsTrue: "Send",
    sendSmsFalse: "Do not Send",
    noteInputLabel: "Appointment Note (Optional)",

    availableHoursButtonTitle: "Upload Available Appointment Hours",
    confirmButtonTitle: "Confirm Appointment Selection",
    confirmButtonHoursTitle: "Confirm Appointment Hours",
    createNewCustomerButtonTitle: "Create New Customer",
    createNewWalkinCustomerButtonTitle: "Create Unregisterd Customer",
    unregisteredCustomerCreated: "Unregistered Customer is created",

    insertToast: "The Appointment has been successfully booked.",
    onlyActiveSwitch: 'Get Active Hours Only'
  },

  /**
   * Adisyonların listeletildiği sayfaların bulunduğu key.
   * https://app.salonrandevu.com/receipts
   * https://app.salonrandevu.com/packages/sales
   */
  receipts: {
    filter: {
      title: "Based on Date Filtration",
      startInputLabel: "Starting Date",
      endInputLabel: "Due to Date",
      typeOfSales: "Type of Sales",
      openTitle: "All",
      open: "Open Sales",
      close: "Close Sales",
    },

    previous: "Previous",
    next: "Next",

    headings: {
      id: "Invoice No",
      type: "Invoice Type",
      billType: "Bill Type",
      createdAt: "Creation Date",
      customerFullName: "Customer Name",
      info: "Invoice Content",
      allAmount: "Total",
      paid: "Paid",
      debtTotal: "Remaining",
      processDate: "Process Date",
    },

    replacements: {
      undefinedCustomer: "Undefined Customer",
      allSales: "All Sales",
      packageSale: "Package Sale",
      standartSale: "Standard Sale",
    },

    showButtonTitle: "View",

    detailButtons: {
      confirmButton: "Approve",
      closeButton: "Close",
    },
    alert_receipt_installment:
      "There are overdue installments in the receipt with past due dates.",

    deleteReceiptSuccessToast: "The receipt and payments have been successfully deleted!",

    customerStatus: {
      attend: "Attended",
      notAttend: "Did Not Attend",
      waiting: "Waiting"
    }
  },

  packages: {
    filter: {
      title: "Package Based Filtering",
      packageInputLabel: "Package Name",
    },
  },
  /**
   * Adisyonların oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/receipts/add
   */
  "receipts/add": {
    searchCustomerInputLabel: "Search Customer",
    searchCustomerInputPlaceholder: "Type Customer Full Name in This Field",
    receiptNoteInputLabel: "Invoice Note (Optional)",

    openReceiptBySelectedButtonTitle: "Create Invoice Over Selected Customer",
    openReceiptByNewCustomerButtonTitle: "Create Invoice Over New Customer",
    openReceiptByRandomCustomerButtonTitle:
      "Create Ticket on Unregistered Client",
    openReceiptWithCreatedNewCustomerButtonTitle: `Create a New Client and a Ticket`,

    insertToast: "The Invoice has been successfully created!",
    infoToast: "You need to make a selection from the customer search box first!",
  },

  /**
   * Tekli adisyonun sayfasının bulunduğu key.
   * https://app.salonrandevu.com/receipts/detail/:id
   */
  "receipt/detail/id": {
    single: {
      warnTitle: 'Bill Update Warning',
      warnDescription: 'The selected service is not provided by the selected personnel. Do you want to continue?',
      warnApprove: 'Accept'
    },
    customerPoint: "Customer Accumulated Points",
    saveChangesButtonTitle: "Save Changes",

    applyDiscountButtonTitle: "Apply Discount",
    addPackageSaleButtonTitle: "Add Package Sale",
    addServiceTransactionButtonTitle: "Add Service Sale",
    addStockSaleButtonTitle: "Add New Product Sale",
    addPaymentButtonTitle: "Add New Payment",

    installmentButtonTitle: "Add Installment Payment",
    installmentCountInputLabel: "Installment Count",
    installmentStaffInputLabel: 'Select Staff',
    installmentProcess: "Installment Process",

    deleteReceiptButtonTitle: "Delete Invoice",

    alertText:
      "The message has been saved, but you must first save the ticket for the message to be forwarded.",

    sms: {
      sendButtonTitle: "Save SMS",
      discardButtonTitle: "Discard",
      addSmsButtonTitle: "Send Collection Message",
      smsButtonTitle: "Send Debt Information / Collection Message",
      languageLabel: "Select language for sample message :",
    },

    creationdate: "Creation Date",
    point: "point",

    packageInner: {
      title: "Package Content",
      headings: {
        // Table Headings
        name: "Service Name",
        count: "Total Sessions",
        completed: "Completed Sessions",
        pending: "Remaining Sessions",
      },
    },

    serviceTransactions: {
      // Section
      print: "Print",
      soldTitle: "New Service Sale",
      title: "Service Sales",
      dateInputLabel: "Date",
      packageDateInputLabel: "Package Date",
      nameInputLabel: "Name",
      staffInputLabel: "Staff",
      transactionStatus: "Status",
      amountInputLabel: "Amount",
      totalAmount: "Total Service Sale Amount ",
      noDataText: "No service sale has been added to invoice yet!",
      searchPlaceholder: "Search services...",
    },

    stockSales: {
      // Section
      title: "Product Sales",
      nameInputLabel: "Name",
      deletedName: "Deleted Product",
      staffInputLabel: "Sold by",
      amountInputLabel: "Unit Price",
      countInputLabel: "Quantity",
      totalAmount: "Total Product Sale Amount ",
      noDataText: "No product sale has been added to invoice yet",
      selectWithBarcode: "Select Product With Barcode",
      selectStock: "Select Product",
      barcodeCheckAlert:
        "Paid content is not active. Please contact the representative for activation.",
    },
    barcodeAlert: {
      pleaseEnterACode: "Please Enter a Product Code",
    },

    productTransactions: {
      productSaleTitle: "New Product Sales",
      successAdd: "Product Added Successfully",
    },

    payments: {
      // Section
      title: "Payments",
      dateInputLabel: "Payment Date",
      typeInputLabel: "Payment Type",
      amountInputLabel: "Amount",
      pointInputLabel: "Points to be Used",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Customer Points",

      totalAmount: "Total Invoice ",
      totalPaidAmount: "Amount Paid ",
      totalDebtAmount: "Debt Amount ",

      noDataText: "No payment method has been added to invoice!",
    },

    installment: {
      title: "Installment payments",
    },

    notes: {
      // Section
      title: "Notes",
      receipt: "Invoice Note",
      customer: "Customer Explanation",
      appointment: "Appointment Note",
      receiptPlaceholder: "Enter invoice note here...",
    },

    applyDiscount: {
      // Modal
      title: "Apply Discount",
      typeInputLabel: "Discount Type",
      typeCASH: "Discount",
      typePERCENT: "Discount as Percentage",
      amountInputLabel: "Discount Amount",
      discardButtonTitle: "Cancel",
    },

    packageSale: {
      // Modal
      title: "New Package Sale",
      completeButtonTitle: "Complete Sale",

      packageNameInputLabel: "Package Selection",
      serviceInputLabel: "Service Selection",
      staffInputLabel: "Staff Selection",
      startInputLabel: "Starting Date",
      periodInputLabel: "Session Quantity",
      periodBetweenInputLabel: "Session Interval",
      periodAmountInputLabel: "Session Price",

      noDataText: "No service session has been added to package sale!",
      totalAmount: "Package Total",

      servicePickToast: "Complete your service selections totally!",
      customerPickToast: "Complete your customer selections totally!",

      packageSaleSuccess:
        "The package has been successfully added to the receipt.",
    },

    serviceTransaction: {
      // Modal
      title: "New Service Sale",
      addButtonTitle: "Add",
      discardButtonTitle: "Cancel",

      staffInputLabel: "Staff Selection",
      serviceInputLabel: "Service Selection",
      successAdd: "Service Added Successfully",
    },

    stockSale: {
      // Modal
      title: "New Product Sale",
      addButtonTitle: "Add",
      discardButtonTitle: "Cancel",

      staffInputLabel: "Selling Staff",
      stockInputLabel: "Product Selection",
    },

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertText:
        "This action is irreversible! In case of deleting this cheque will also delete transaction in it. It may affect your accounting records!",
    },

    discountBeforePaymentToast:
      "In order to apply discount, you must remove payments.",
    saveChangesToast: "Changes have been successfully saved.",

    transactionSuccessToast: "Note Saved Successfully",
    agreementSuccessToast: "Agreement sent successfully.",
    uploadAgreementErrorToast: "The agreement size must not exceed 2 MB.",
    uploadAgreementWarnToast: "The agreement is being prepared...",
    uploadAgreementSuccessToast: "Agreement uploaded successfully.",
    stockItemErrorToast: "No Record Found",
    pointPaidWarnToast: "More points were used than the accumulated customer points. Please adjust your payments!",

    headingTitles: {
      receiptId: "Receipt No: ",
      services: "Services",
      staff: "Staff",
      service: "Service",
      amount: "Fee",
      products: "Products",
      product: "Product",
      quantity: "Quantity",
      receiptTotalAmount: "Receipt Total: ",
      discount: "Discount: ",
      netTotal: "Net Total: ",
      paidAmount: "Paid Amount: ",
      remaintAmount: "Remaining Amount: "
    },

    agreementSaveButtonWarnToast1: "Please save the changes you have made.",
    agreementSaveButtonWarnToast2: "To use this feature for the Diamond license package, you need to purchase the Diamond license package.",
    routeToLogs: "Go to receipt transaction logs",
    previewTitle: "Preview",
    previewErrorToast: "Invalid Identity Number",

    selectStaff: "Staff Selection (Optional)",
    paymentAmount: "Payment Amount",
    installments: {
      two: "2 Installments",
      three: "3 Installments",
      four: "4 Installments",
      five: "5 Installments",
      six: "6 Installments",
      seven: "7 Installments",
      eight: "8 Installments",
      nine: "9 Installments",
      ten: "10 Installments",
      eleven: "11 Installments",
      twelve: "12 Installments"
    },

    charLimitTitle: "Your character limit",

    greenHourTitle: "Available time slots for appointment booking are shown in green.",
    redHourTitle: "Time slots that have previously been booked or have been closed by the employee's details are shown in red.",
    orangeHourTitle: "When a time is closed by the authorized person, it is shown in orange.",

    uploadAgreementTitle: "Upload Agreement",
    uploadAgreementText: "Click here to upload the agreement related to this receipt.",
    uploadAgreementWarnToast2: "Please fill in the missing fields: Agreement Name.",
    savedAgreementTitle: "Saved Agreements",
    selectAgreementTemplateTitle: "Select Agreement Template",

    nationalIDTitle: "National ID Number",
    taxIDTitle: "Tax ID Number",
    addressTitle: "Address",

    sendToSignatureTitle: "Send for Signature",
    sendToSignatureAlertDialogTitle: "Do you confirm sending the edited agreement for mobile signature?",
    sendToSignatureAlertDialogText: "You can view, edit, and sign the sent agreement from the Contracts List section in your mobile application.",

    getServices: "Get available services",
    clear: "Clear",
    deletedService: "Deleted Service",

    saveBeforeGetAppointmentWarnToast: "Please save your changes before making an appointment.",
    addNote: "Add Note",

    getProducts: "Get Products",
    installmentText: "installment",

    packageSessionUpdateTitle: "Package Session Update",
    packageSessionUpdateText1: `All sessions in the package will be updated to "%s". Do you confirm?`,
    packageSessionUpdateText2: "Note: Don't forget to save changes after this update.",

    packageDateUpdateTitle: "Package Date Update",
    packageDateUpdateText: "All session dates in the package will be updated according to the date range. Do you confirm?",

    packageDeleteTitle: "Package Deletion",
    packageDeleteText1: "The package and all services within it will be deleted. Do you confirm?",
    packageDeleteText2: "If you confirm deletion, the package and all services within it will be deleted. Do you confirm?",

    stockSaleErrorToast: "The selected product is out of stock.",
    barcodeInputPlaceholder: "Enter barcode.",
  },

  "packages/sales": {
    filter: {
      title: "Date Based Filtering",
    },

    loaderTitle: "Packages are loading. Please wait...",
  },

  /**
   * Paket satışının yapıldığı sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/sales/add
   */
  "packages/sales/add": {
    searchPackegeInputLabel: "Search Package",
    customerInputLabel: "Customer to Sell",
    customerInputPlaceholder: "Enter Customer Name in this Field",

    importPackageButtonTitle: "Import Package",
    addServiceButtonTitle: "Add Service to Package",
    completeButtonTitle: "Complete Sale",

    enterPackageName: "Enter Package Name",

    serviceInputLabel: "Service Selection",
    staffInputLabel: "Staff Selection",
    startInputLabel: "Starting Date",
    periodInputLabel: "Session Quantity",
    periodBetweenInputLabel: "Session Interval",
    periodAmountInputLabel: "Session Price",
    totalInputLabel: "Package in Total",

    noDataText: "No service – session has been added to package sale yet!",

    addPackageInSaleWarnToast: "Make sure you have selected the package you want to import!",
    validatePackageSelectServiceWarnToast: "Complete your service selections fully!",
    validatePackageSelectCustomerWarnToast: "Complete your customer selection fully!",

    dayTitle: "%s Day",

    modal: {
      title: "Import Prepared Package",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      packageInputLabel: "Package Selection",
    },
  },

  /**
   * Paketlerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/list
   */
  "packages/list": {
    headings: {
      name: "Package Name",
      amount: "Package Sum",
    },
  },

  /**
   * Paketlerin oluşturulduğu sayfanın bulunduğu key.
   * https://app.salonrandevu.com/packages/add
   */
  "packages/add": {
    name: "Package Name",
    completeButtonTitle: "Create Package",
    addServiceButtonTitle: "Add New Service to Package",

    serviceInputLabel: "Service Selection",
    periodInputLabel: "Number of Session",
    periodAmountInputLabel: "Sessions Unit Price ",
    totalInputLabel: "Package Price in Total ",

    noDataText: "No service – session has been added to package yet!",

    validateServiceToast: "Complete your service selections fully!",
    insertToast: "Package has been successfully created.",
  },

  /**
   * Paket detayı sayfasının bulunduğu key.
   * https://app.salonrandevu.com/packages/detail/:id
   */
  "packages/detail/id": {
    updatePackageButtonTitle: "Update Package",
    deletePackageButtonTitle: "Delete Package",
    addServiceButtonTitle: "Add New Service to Package",

    nameInputLabel: "Package Name",
    serviceInputLabel: "Service Selection",
    countInputLabel: "Number of Session",
    periodAmountInputLabel: "Session Unit Price",

    totalInputLabel: "Package Price in Total",
    noDataText: "No service – session has been added to package yet!",

    delete: {
      title: "Confirm to Delete Package",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",
      alertText:
        "Do you confirm to delete package? Previous selling, cheques and accounting records will not be affected",
    },

    validateServiceToast: "Full complete your service selections!",
    deleteToast: "Package has been successfully deleted.",
    updateToast: " Package has been successfully updated",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  products: {
    deleteButtonTitle: "Delete Product",

    headings: {
      name: "Product Name",
      amount: "Product Price",
      limit: "Stock Quantity",
      bounty: "Percentage of Bonus",
    },
    delete: {
      // Modal
      title: "Confirmation of Product Deletion",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "This action irreversible!",
      alertText: "If you delete, you can no more make sales over the product!",
    },

    deleteToast: "Product has been successfully deleted.",
    stockName: "Product Stock",

    insertStockSaleSuccessToast: "The sale transaction was completed successfully!",
    customerNameInput: "Enter the customer's full name in this field",
    goWithSelectedCustomer: "Proceed with Selected Customer",
    goWithSelectedCustomerWarnToast: "You need to select your customer first.",
    goWithoutSelectedCustomer: "Proceed with Unspecified Customer",

    productList: "Product List",
    productName: "Product Name",
    productAmount: "Product Price",
    stockPiece: "Stock Quantity",
    addToCart: "Add to Cart",

    cartList: "Items in Cart",
    singleAmount: "Unit Price",
    totalAmount: "Total Price",
    removeFromCart: "Remove from Cart",

    completeStockSale: "Complete Sale",

    codeLabel: "Code",
    saleDate: "Sale Date",
  },

  /**
   * Ürünlerin liste sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products
   */
  "products/add": {
    searchProductInputLabel: "Search Product",
    searchProductInputPlaceholder: "Please enter product name",

    createButtonTitle: "Create Product",

    nameInputLabel: "Product Name",
    nameInputPlaceholder:
      "Enter product name that will be listed in your management application.",
    amountInputLabel: "Product Price",
    amountInputHint:
      "The amount you set is your default amount. You can change the amount while adding to cheque .",
    bountyInputLabel: "Percentage of Bonus (%)",
    bountyInputHint:
      "If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",
    pointInputLabel: "Points to be Earnt",
    addBarcode: "Add a Product with Barcode Code",
    addBarcodeExplain: "Enter the code to add a barcode for the product.",
    pointInputHint: " 100 customer point corresponds to 1.",
    limitInputLabel: "Stock Quantity",
    limitInputHint:
      "Enter product stock quantity. You can change the value later.",

    insertToast: "Product has been successfully created.",
    barcodeCheckTitle:
      "Paid content is not active. Please contact the representative for activation.",
  },

  /**
   * Ürün detay sayfasının bulunduğu key.
   * https://app.salonrandevu.com/products/detail/:id
   */
  "products/detail/id": {
    updateButtonTitle: "Create Product",

    nameInputLabel: "Product Name",
    nameInputPlaceholder:
      "Select product name that will be listed in your management application.",
    amountInputLabel: "Product Name ",
    amountInputHint:
      "The amount you set is default amount. You can change the price while adding to cheque.",
    bountyInputLabel: "Percentage of Bonus (%)",
    bountyInputHint:
      "If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",
    pointInputLabel: "Point to be Earnt",
    pointInputHint: "100 customer point corresponds to 1.",
    limitInputLabel: "Stock Quantity",
    limitInputHint: "Enter stock quantity. You can change the value later.",

    updateToast: "Product has been successfully updated.",
  },

  /**
   * Aktif müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers
   */
  customers: {
    alertTExt:
      "It is on/off switch setting for sms sending for your customers on your customer list. When you perform this operation, the added All your customers' sms sending settings will be updated.",
    filter: {
      title: "Customer Search filter",
      inputLabel: "Name, Surname or Phone Number",
    },
    amount: {
      openSmsSendingStatus: "Open SMS Sending Status",
      closeSmsSendingStatus: "Close SMS Sending Status",
      title: "View Amounts",
      updateSmsStatus: `Update ${process.env.APP_NAME === "management" ? "Email" : "SMS"
        } Status`,
      allCustomerSmsUpdate: "All Customers' SMS Delivery Status Will Change!",
      excelTooltip: "This parameter is enabled if you want to add additional data to the customer list, such as debt amount, earned amount and SMS delivery status, to the Excel file.",
    },
    headings: {
      code: "Customer Code",
      fullName: "Name Surname",
      phone: "Phone Number",
      sex: "Gender",
      incomeAll: "Amount Earnt",
      debtAll: "Amount of Debt",
      message_state: "Message State",
      message_open: "Open",
      message_close: "Closed",
    },
    replacements: {
      totalCustomerNumber: "Total Customer Number",
      sex: {
        undef: "Unspecified",
        male: "Male",
        female: "Female",
      },
    },

    passiveAll: {
      passiveAllButtonTitle: "Make All Pasive",
      title: "Do you approve to make passive all of your customers?",
      alertText:
        "If you make all customers passive, you will not be able to see customers in areas such as appointments / bills again. You can access the information of customers from the Passive Customers tab whenever you want.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",

      successToast: "All customers have been successfully gone passive.",
    },
    passiveSelected: {
      passiveSelectedButtonTitle: "Make Selected Pasive",
      title: "Do you approve to make passive chosen customers?",
      alertText:
        "If you make chosen customers passive, you will not be able to see customers in areas such as appointments / bills again. You can access the information of customers from the Passive Customers tab whenever you want.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Cancel",

      successToast: "Chosen customers have been successfully gone passive.",
    },

    months: {
      jan: "January",
      feb: "February",
      mar: "March",
      apr: "April",
      may: "May",
      jun: "June",
      jul: "July",
      aug: "August",
      sep: "September",
      oct: "October",
      nov: "November",
      dec: "December"
    },
    customerFullNameLabel: "Unnamed Customer",
    ispackageTrueLabel: "Package Sale",
    ispackageFalseLabel: "Standard Sale",
    createSuccessToast: "Customer record has been successfully created!",
    wrongDateWarnToast: "You have entered an incorrect date. Please reselect the 'Date of Birth.'",
    errorOccuredToast: "An unexpected error occurred. Please contact your customer representative.",
    deletedStaffLabel: "(Deleted Staff)",
    photoLimitErrorToast: "You have exceeded the photo limit for the current package.",
    photoUploadErrorToast: "You cannot upload a photo.",
    updateToPassiveSuccessToast: "Customer has been successfully set to passive!",
    updateToActiveSuccessToast: "Customer has been successfully set to active!",
    customerDeleteSuccessToast: "Customer has been successfully deleted.",
    suitableClockWarnToast: "Please select a time suitable for the appointment day.",
    staffUpdateWarn1: "You cannot make changes to customer information according to your permissions!",
    staffUpdateWarn2: "Only business authorities can edit customer information and delete customers.",
    whatsappTooltipTitle: "Contact via Whatsapp",
    whatsappWarnToast: "Paid content is not active. Please contact your representative.",
    sendMessageTooltipTitle: "Send Message",

    contractHeading: {
      date: "Date",
      agreement: "Agreement Name",
      bytes: "Size",
      transactions: "Transactions"
    },

    sendContractViaSMS: "Do you want to send the registered contract via SMS?",
    sendContractMessage: "Are you sure you want to send '%v' to the customer named %s via SMS?",
    sendContractWP: "Are you sure you want to send '%v' to the customer named %s via Whatsapp?",
    sendContractViaWP: "Do you want to send the registered contract via Whatsapp?",

    deleteContractTitle: "Do you confirm deleting the contract?",
    deleteContractAccept: "Confirm and Delete",
    deleteContractWarn1: "The contract will be permanently deleted.",
    deleteContractWarn2: "This action cannot be undone. The contract will be permanently deleted.",

    serviceTableLabel: "Packages",
    routeToAppointment: "Go to Appointment",
    acceptAppointmentDecline: "Do you confirm canceling the appointment?",
    appointmentDeclineWarn1: "This action cannot be undone!",
    appointmentDeclineWarn2: "The appointment will be removed from the appointment calendar. Do you confirm?",

    beforePhoto: "Previous Photo",
    afterPhoto: "Next Photo",

    routeToLogs: "Go to Customer Transaction Records",

    totalCustomers: "Total Customers:",

    // customers/detail/:id
    // customers/detail/:id
    single: {
      toast: {
        validateCustomer: {
          success: "Customer information has been successfully updated!",
          warn: "Customer information could not be updated!",
        },
        makePassiveSingleCustomer: {
          success: "Customer has been successfully set to passive!",
        },
        makeActiveSingleCustomer: {
          success: "Customer has been successfully set to active!",
        },
        deleteSingleCustomer: {
          success: "Customer successfully deleted!",
        },
      },
      tabs: [
        "Customer Information",
        "Customer Cheques",
        "Customer Appointments",
      ],
      form: {
        tab1: {
          // customer info
          info: [
            "You cannot change the customer information in accordance with your authorization!",
            "Only business officials can edit customer information and delete customers.",
          ],
          clientName: [
            //0: label, 1: placeholder
            "Customer Name",
            "Enter customer name",
          ],
          clientSurname: [
            //0: label, 1: placeholder
            "Customer Surname",
            "Enter customer surname",
          ],
          clientGender: ["Gender", "Female", "Male"],
          birthday: [
            "Date of birth",
            "Please log in the desired format. Day/Month/Year",
          ],
          maritalStatus: ["Marital Status", "Married", "Single"],
          phone: [
            "Mobile Number",
            "Second Mobile Number",
            "Enter second mobile number if available",
          ],
          email: [
            "Enter Your E-mail",
            "If available enter your e-mail",
            "If available enter customer's e-mail",
            "Enter Customer Email",
          ],
          additionalDescription: ["Additional Notes"],
          clientPoint: ["Customer Rating"],
          workStatus: {
            label: "Working Status",
            woman: [
              "Employed",
              "Unemployed",
              "Employer",
              "Student",
              "Housewife",
            ],
            man: ["Employed", "Unemployed", "Employer", "Student"],
          },
          relatedInstitution: [
            "Relevant Institution",
            "Specify the instituiton which the customer comes from",
          ],
          smsSendStatus: ["Email Sending Status", "Send", "Do not send"],
          checkers: ["Yes", "No"],
          buttons: [
            "Update Customer",
            "Activate Customer",
            "Deactivate Customer",
            "Do you approve to delete?",
            "Do you approve passive adjustment?",
          ],
          modals: {
            deleteConfirm: [
              "This process cannot be reversed!",
              "By deleting this customer you may not be able to access their information again.",
            ],
            passiveConfirm: [
              "If you make this customer pasif you will not be able to see the customer in areas such as appointmentcheque.",
              "You can always access customer information from the passive customer tab.",
            ],
          },
        },
        tab2: {
          tableHeadings: [
            "Cheque No",
            "Type of Cheque",
            "Opening Date",
            "Bill Content",
            "Total",
            "Paid",
            "Remaining",
          ],
          tableButtons: ["View"],
          unknownInfo: [
            "Unidentified Customer",
            "Package Sales",
            "Standard Sales",
          ],
          noData: ["Data Not Found"],
        },
        tab3: {
          customerFullName: "Customer Fullname",
        },
      },
    },
  },

  "logs/index": {
    filter: {
      title: "Advanced Search Filter",
      form: {
        description: "Process Detail / Customer Name / Personal Name",
        startDate: "Start Date",
        endDate: "End Date",
        allDate: "All Time",
        findCustomer: {
          label: "Customer Name",
          placeholder: "Search specific customer name",
          helper: "Only specific customer filtering",
        },
      },
    },
  },

  /**
   * Riskli müşterilerin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/risky
   */
  "customers/risky": {
    offsetInputLabel: "Day(s) Selection For",
    offsetItem15: "Those which have been inactive for more than 15 days",
    offsetItem30: "Those which have been inactive for more than 30 days",
    offsetItem45: "Those which have been inactive for more than 45 days",
    offsetItem60: "Those which have been inactive for more than 60 days",

    headings: {
      code: "Customer Code",
      fullName: "Name Surname",
      phone: "Phone Number",
      sex: "Gender",
      incomeAll: "Amount Earnt",
      debtAll: "Amount of Debt",
      lastTransaction: "The time passed over the last transaction",
    },

    sendMessageSuccessToast: "Message sent successfully.",
    sendMessageButtonTitle: "Send Campaign Message",
    selectedAllButtonTitle: "Select All",
    cancelAllButtonTitle: "Remove All",
    deleteSelectedCustomers: "Delete Selected Client",
    deleteAllCustomer: "Delete All Client",
    listButtonTitle: "Get Risky Clients",
    noDataTextInitial: "Please select a date and then click the \"Get Risky Clients\" button.",
    noDataText: "No risky clients found in the selected date range.",
    infoText: "Information!",
  },

  /**
   * Değerlendirme anketlerinin listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/rating
   */
  "customers/rating": {
    headings: {
      createdAt: "Evaluation Date",
      staffFullName: "Staff",
      serviceName: "Service Applied",
      companyStar: "Enterprise Point",
      staffStar: "Staff Point",
      description: "Explanation",
    },

    deleteButtonTitle: "Delete Evaluation",
    replyToComment: "Reply",
    viewToComment: "View",
    delete: {
      title: "Do you confirm to delete?",
      alertText:
        "This action is irreversible! You cannot access it anymore if you delete the evaluation survey.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
    },

    deleteToast: "Specified evaluation has been successfully deleted!",
    deleteBeforePlanUpgradeToast:
      "You must upgrade to premium package in order to delete customer comments.",
  },

  /**
   * Müşterilerin eklendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/customers/add
   */
  "customers/add": {
    nameInputLabel: "Customer Name",
    nameInputPlaceholder: "Enter Customer Name",

    surnameInputLabel: "Customer Surname",
    surnameInputPlaceholder: "Enter Customer Surname",

    phoneInputLabel: "Phone Number",

    genderInputLabel: "Gender",
    genderMale: "Male",
    genderFemale: "Female",

    disableOnlineBooking: "Online Appointment",
    disableOnlineBookingLabelTrue: "Cannot",
    disableOnlineBookingLabelFalse: "Can",
    secondPhoneNumberWarning: "Second phone number cannot be empty",
    firstPhoneNumberWarning: "First phone number cannot be empty",
    bothPhoneNumberWarning: "One or both phone numbers cannot be empty",

    blacklist: {
      primary: "1. phone number in blacklist. Messages cannot be sent to this number.",
      secondary: "2. phone number in blacklist. Messages cannot be sent to this number.",
      both: "1. and 2. phone number in blacklist. Messages cannot be sent to these numbers.",
      removeBlacklist: "Click to remove the block.",
    },

    sendSmsTooltipTitle: "Indicates the status of sending messages such as appointment reminders and appointment confirmation messages.",



    birthdayInputLabel: "Date of Birth",
    birthdayInputInvalidMessage:
      "Enter your date of birth as required Day/Month/Year",

    maritalStatusInputLabel: "Marital Status",
    maritalStatusMarried: "Married",
    maritalStatusSingle: "Single",

    secondPhoneInputLabel: "Additional Phone Number",
    secondPhoneInputPlaceholder: "Enter second phone number if exists.",

    mailInputLabel: "E-Mail Address",
    mailInputPlaceholder: "Enter e-mail address if exists",

    descriptionInputLabel: "Additional Explanations",

    workStatusInputLabel: "Employment Status",
    workStatusWorking: "Employed",
    workStatusUnemployed: "Unemployed",
    workStatusEmployer: "Employer",
    workStatusStudent: "Student",
    workStatusHouseWife: "Housewife",

    relatedBusinessInputLabel: "Related Foundation",
    relatedBusinessInputPlaceholder:
      "Please indicate the foundation that customer has come from if known.",

    sendSmsInputLabel: "Email Sending Status",
    sendSmsTrue: "Send",
    sendSmsFalse: "Do not Send",

    selectPhoneNumberForMessage: "Select the number to send messages to",
    firstPhoneNumber: "First phone number",
    secondPhoneNumber: "Second phone number",
    allPhoneNumber: "Both",

    createNewCustomerButtonTitle: "Create Customer",
  },

  "customers/passive": {
    makeActiveButtons: {
      selectedButtonTitle: "Activate Selected",
      allButtonTitle: "Activate All",
    },

    selectedCustomersSuccessToast:
      "Selected customers have been successfully activated!",
    allCustomersSuccessToast: "All customers have been successfully activated!",
    allCustomersDeletedSuccessToast: "All customers have been successfully deleted.",
    selectedCustomersDeletedSuccessToast: "Selected customers have been successfully deleted.",
    selectedCustomersDeleteWarnToast: "Customers without active appointments have been deleted. If you want to delete customers with active appointments, you must cancel their active appointments.",
  },

  /**
   * Toplu müşteri ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/customers/add/bulk
   */
  "customers/add/bulk": {
    orginzeProcess: "Contacts are being edited. Please wait.",
    uploadAreaText: "Please click here to upload the excel file",
    stepsHeading: "Uploading Bulk Customer Steps with Excel",
    steps: [
      "In order to add your customer into the system over excel, you must upload the excel data created for you.",
      "By clicking the button on next step, download excel template.",
      "Download Excel Format",
      "Convert your customer data to previously downloaded excel format and upload them into system.",
    ],
    boldText:
      "IMPORTANT: For lossless and faultless data transfer, locations and names of columns must not be changed on excel template!",

    preview: {
      heading: "Preview of Excel Uploading",
      completeButtonTitle: "Complete Bulk Customer Registration",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",
      nationalID: "nationalID",
      phoneInputLabel: "Phone Number",
      sendSmsInputLabel: "Sending Email",
      sendSmsTrue: "Open",
      sendSmsFalse: "Close",
      selectedPhoneLabel: "Selected Number",
      descriptionLabel: "Additional Notes",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      mailInputLabel: "E-mail Address",
      birthdayInputLabel: "Date of Birth",

      maritalStatusInputLabel: "Marital Status",
      maritalStatusMarried: "Married",
      maritalStatusSingle: "Single",
    },

    duplicateRecords: "%s duplicate records were deleted.",

    duplicatedPhoneWarnToast: "Phone numbers are already registered in the system.",

    insertToast:
      " Transaction of bulk upload has been successfully queued. You are being redirected to homepage.",
  },

  /**
   * Hizmet listesinin bulunduğu key.
   * https://app.salonrandevu.com/services
   */
  services: {
    filter: {
      title: "Service Search Filter",
      inputLabel: "Service Name",
    },

    headings: {
      name: "Service Name",
      processTime: "Processing Time",
      bounty: "Bonus Percentage",
      amount: "Service Fee",
      showAtUrl: "Online Appointment Settings",
    },

    replacements: {
      showAtUrlTrue: "Open",
      showAtUrlFalse: "Close",
    },

    deleteButtonTitle: "Delete Service",
    delete: {
      title: "Do you confirm to delete service?",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this service, you cannot control cheque management or book/complete appointments any more over this service. Appointments relating to this service are reserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
    },

    dataNotFound: "Data Not Found!",

    deleteToast: "Service has been successfully deleted.",

    addAllService: {
      warningTitle: "WARNING!",
      addButtonTitle: "Add Bulk Service",
      informationDialog: {
        addServiceDescription:
          "The duration and price information of the services you have transferred are automatically assigned by the system. Please update the service time and price information you have provided in your business.",
        confirmButtonTitle: "Confirm",
      },
    },

    deleteAll: {
      title: "Do you confirm deletion of services?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete all services, you will not be able to perform transactions in the ticket management, and you will not be able to make an appointment through these services while making/defining an appointment. Your existing appointments for these services will be preserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
      deleteAllServicesToast: "Services deleted successfully",
      deleteAllButtonTitle: "Delete All",
    },

    deleteSelectedServices: {
      title: "Do you confirm deletion of selected services?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete the selected services, you will not be able to perform transactions in the ticket management, and you will not be able to make an appointment through these services while making/defining an appointment. Your existing appointments for these services will be preserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "Discard",
      deleteSelectedButtonTitle: "Delete Selected Services",
    },

    deleteToast: "The service was successfully deleted.",

    amountBasisTitle: "Amount Based",
    percentBasisTitle: "(%) Percent Based",
  },

  /**
   * Hizmet detay/ekleme sayfasının bulunduğu key.
   * https://app.salonrandevu.com/services/add
   * https://app.salonrandevu.com/services/detail/id
   */
  "services/detail": {
    placeholder: {
      search: "Enter the service you're looking for",
    },
    validate: {
      serviceCategory: "Service Category",
      servicesName: "Service Name",
      servicePrice: "Service Price",
      servicesProcessTime: "Service Process Time",
    },
    selcetAllEmployees: "Select All Employees",

    serviceName: "That you are looking for ",
    lookingFor: "Service Name",
    ourList: "is not on our list?",
    createRequest: "Create Request",
    newCreateRequest: "Create A Service Request",
    createNewRequest: "Create New Request",
    serviceCategoryInput: "Service Category",
    serviceCategoryHint:
      "The service category and service name you choose will be used on the Salon Appointment platform.",

    nameInputLabel: "Service Name",
    nameInputHint:
      "Enter the service name that will be listed on management application and appointment page.",

    amountInputLabel: "Service Fee ",
    amountInputHint:
      "The amount you set is default amount. While adding to cheque you can change transaction fee.",

    bountyInputLabel: "Bonus Percentage (%)",
    bountyMoneyBased: "Bonus Money Based",
    bountySelectMoney: "Money Based ",
    bountySelectPer: "Bonus Percentage",
    bountyInputHint:
      " If your staffs fixed bonus percentage is undefined, they will earn bonus according to percentage will be defined.",

    pointInputLabel: "Points to Earn",
    pointInputHint: "100 customer point corresponds to 1.",

    timeInputLabel: "Average Waiting Time (min)",
    timeInputHint:
      "Transaction time is an information that will be displayed while the customer is booking an appointment. Staff who produce this service cannot accept a new appointment during the period of it .",

    repeatInputLabel: "Repetition Time (Day)",
    repeatInputHint:
      "If this service requires repetition of transaction, it allows you to send E-mail notification to customer when the required time repetition comes.",

    urlInputLabel: "Online Appointment Settings",
    urlInputHint:
      "This is the setting if transaction will be shown or not for the online appointments booked from enterprise’s URL.",

    urlInputTrue: "Open",
    urlInputFalse: "Close",

    staffSectionTitle: "Service Application Staff",
    staffSelectAll: "Apply For All Staff",
    staffDeSelectAll: "Remove All",
    timeWarnAlertText:
      "The changes you make will be valid from creating new appointment onwards, previously created appointments will not be affected.",

    createButtonTitle: "Create Service",
    updateButtonTitle: "Update Service",
    deleteButtonTitle: "Delete Service",

    delete: {
      title: "Do you confirm to delete this service?",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this service, you cannot control cheque management or book/complete appointments any more over this service. Appointments relating to this service are reserved.",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
    },

    lastTransactions: {
      headings: {
        createdAt: "Transaction Date",
        staffFullName: "Staff",
        amount: "Transaction Fee",
      },
      showOnReceiptButton: "Shown in Cheque",
    },

    deleteToast: "Service has been successfully deleted.",
    insertToast: " Service has been successfully created.",
    updateToast: " Service has been successfully updated.",

    newServiceText: "Is the Service Name you are looking for not on our list?",
    newServiceBtn: " Create Request",

    newServiceInputTitle: "New Service Name",
    newServiceRequestToast:
      "Your new Service request has been successfully created.",
    newServiceRequestErrorToast: "New Service Name cannot be blank.",
    newServiceRequestDialogTitle: "New Service Request",
    newServiceCategoryHint:
      "The Service Category to which the new service will depend.",
    createNewServiceRequest: "Create Request",
    cancelNewServiceRequest: "Cancel",

    setCampaignTitle: "Set as Campaign",
    campaignNameTitle: "Campaign Name",
    campaignHint:
      "Products marked as Campaigns will appear in the Campaign field on your business page.",

    serviceDescriptionLabel: "Service Description",
    serviceDescriptionHint:
      "The service description allows your customers to get to know the service you offer better.",
    continueWithoutCategory: "Continue Without Category",
  },

  "payment-notify": {
    copyIban: "Copy IBAN",
    ibanSuccess: "IBAN copied successfully.",
    depositBank: "Deposit Bank",
    depositAmonut: "Deposit Amount",
    depositDate: "Deposit Date",
    makePaymentNotice: "Make Payment Notice",
  },

  "services/requests": {
    headings: {
      time: "Transaction date",
      name: "Service Name",
      category: "Category",
      state: "Status",
      actionHeadingText: "Actions",
      stateCompleted: "Request Approved",
      stateIncompleted: "Request Not Approved",
      stateWaiting: "Awaiting Request Approval",
    },

    deletedRequestToast: "Request has been Deleted",

    cancel: {
      cancelButton: "Cancel",
      cancelTitle: "Do You Want to Delete New Service Request?",
      cancelConfirmButtonTitle: "Confirm",
      cancelDiscardButtonTitle: "Discard",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you cancel this request, we will not be able to perform the transaction and no new service check will be made.",
    },
  },

  "services/bounty": {
    addBountyTitle: "Add Bounty",

    bountyDescription:
      "You can add a special premium here for your services and employees that do not have a fixed premium percentage, with the option to define special premiums.",

    staffInputLabel: "Employee to be added bonus",
    categoryInputLabel: "Service Category",
    serviceInputLabel: "Service Name",
    bountyAmountInputLabel: "Bounty Percentage",
    bountyAdd: "Add",

    headings: {
      bounty: "Bounty Percentage(%)",
      serviceName: "Service Name",
      staffName: "Employee Name",
      deleteBountyButtonTitle: "Delete Bounty",
    },

    deleteBountyDialog: {
      title: "Are you sure you want to delete the bounty?",
      alertBoldText: "This action cannot be undone!",
      alertText:
        "If you delete this premium, your employee will not have a premium account linked to this service.",
      confirmButtonTitle: "Confirm",
      discardButtonTitle: "Discard",
    },

    addBountySuccessToast:
      "The service-specific employee bonus has been successfully added.",
    deleteBountySuccessToast:
      "The employee bonus for the service has been successfully deleted.",
  },

  noDataFound: "No Data Found",

  /**
   * Çalışanların listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees
   */
  employees: {
    editButtonTitle: "Edit Staff",
    staffPageTitle: "Staff List",
    createNewEmployee: "Create Staff",
    orderStaffs: "Change Order",
    insertStaffSuccessToast: "Staff successfully created!",
    updateStaffSuccessToast: "Staff successfully updated!",
    deleteStaffSuccessToast: "Staff successfully deleted!",

    addAllService: "Add All",
    addAllServiceWarn: "The services you selected in the package are not among the services offered by the staff. You cannot proceed without adding the service to the staff's offered services.",
    routeToStaffLogs: "Go to Staff Transaction Records",
    packagesTitle: "Offered Packages",
  },

  /**
   * Geri Bildirim verilen sayfa
   * https://app.salonrandevu.com/feedback
   */
  feedback: {
    insertFeedbackSuccessToast: "Your feedback has been successfully received. Thank you!",
    insertFeedbackWarnToast: "Please write your feedback!",
    feedbackTitle: "Leave Feedback",
    feedbackLabel1: "We value the opinions, thoughts, and suggestions of our users.",
    feedbackLabel2: process.env.APP_NAME === "salon" ? "Salonrandevu" : process.env.APP_NAME === "en" ? "EnRandevu" : "Salon Management",
    feedbackLabel3: "as a management application",
    feedbackLabel4: "user experience",
    feedbackLabel5: "we continue to work on how to improve the user experience. For this purpose, we have developed a feedback channel where we can constantly interact with our valuable users.",
    feedbackLabel6: "feedback channel",
    feedbackLabel7: "Through the form on the page, you can share any ideas, opinions, and suggestions with us.",
    feedbackInputLabel: "You can write here",
  },

  /**
   * Çalışan profil sayfalarının listelendiği sayfanın bulunduğu key.
   * https://app.salonrandevu.com/employees/detail/id
   * https://app.salonrandevu.com/employees/add
   * https://app.salonrandevu.com/profile
   */
  profile: {
    detail: {
      //Section
      title: "Staff Profile",
      profileTitle: "Profile Information",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      jobTitleInputLabel: "Title",
      bountyInputLabel: "Percentage of Fixed Bonus",

      permissionInputLabel: "Panel Authorization Status",
      permissionOwner: "Authorized Person",
      permissionStaff: "Staff",
      permissionLimited: "Limited Authority",

      sendSmsInputLabel: "Email Sending",
      sendSmsTrue: "Send",
      sendSmsFalse: "Do not Send",

      urlInputLabel: "Display in Enterprise URL",
      showAtUrlTrue: "Display",
      showAtUrlFalse: "Do not Display",

      branch_list: "Branch List",
      branch_change: "Change Branch",
      branch_enter: "Enter Branch",
      branch_change_alert: "Branch change has been made.",
    },
    logoutButtonTitle: "Logout",

    contact: {
      //Section
      title: "Contact Information",

      mailInputLabel: "Email",
      phoneInputLabel: "Phone Number",
      passwordInputLabel: "Panel Password",
      newPasswordInputLabel: "New Panel Password",
    },

    services: {
      //Section
      title: "Service Provided",

      alertBoldText:
        "Services provided by staff below must be selected by clicking on it!",
      alertText:
        "Otherwise services provided by staff at enterprise’s appointment address will be appeared as empty field.",
    },

    booking: {
      //Section
      title: "Appointment Hours",
      alertBoldText: "Manage Your Appointment Interval!",
      week: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      dayOpenText: "Open the day for appointment selection",
      dayCloseText: "Close the day for appointment selection",
    },

    photo: {
      //Section
      uploadButtonTitle: "Upload Photos",
      deleteButtonTitle: "Remove Photo",
    },

    createButtonTitle: "Create Staff",
    updateButtonTitle: "Save Changes",
    deleteButtonTitle: "Delete Staff",

    delete: {
      //Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This action is irreversible!",
      alertText:
        "If you delete this staff, you might not be able to access staff profile and other information anymore.",
    },

    photoUploadToast: "Photo has been successfully uploaded.",
    photoDeleteToast: " Photo has been successfully removed.",
    insertToast: " Staff has been successfully created.",
    updateToast: " Staff has been successfully updated.",
    deleteToast: " Staff has been successfully deleted.",

    limited_permission_label: {
      no_permission: "No Access",
      display: "Only View",
      full_permission: "Full Permision",
      all: "All",
      just_itself: "Just Itself"
    },
  },

  /**
   * SMS Yönetimi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms
   */
  sms: {
    foundWpDevice: "WhatsApp device registered to your account has been found.",
    noFoundWpDevice:
      "No whatsapp devices registered to your account were found.",

    creditSMSLabel: "Usable/Remaining SMS Credit",
    creditWPLabel: "Usable/Remaining WP Credit",
    senderLabel: "Sender Name",

    sendType: {
      label: "Message Sending method",
      sendSMS: "Send SMS",
      sendWP: "Send Whatsapp Message",
      both: "Send on both",
    },
    credit: {
      sms: "Sms Credit",
      wp: "WhatsApp Credit",
    },
    loginWithQr: "Scan QR Code",
    loginWithNo: "Enter Phone Number",

    addDevice: "Add Device",
    removeDevice: "Remove Device",

    deviceStatus: "Status",
    wpPhone: "WhatsApp Number",
    wpCredits: "WhatsApp Credits",

    phoneNotConnected: "Your Number is Not Connected",

    deviceConnected: "Device Connected",
    deviceNotConnected: "Device Not Connected",

    wpQrTutorial1: "Open the WhatsApp application, then tap on the ︙.",
    wpQrTutorial2: "Tap on the 'Connect Device' button.",
    wpQrTutorial3: "Scan your QR code with your camera.",

    qrPopupTitle: "Scan QR Code",
    qrPopupContext:
      "Scan the QR code using your device's camera to connect to the application.",

    withPhoneNumberTitle:
      "Enter the Number for Which You Want to Receive WhatsApp Code.",
    codeForThisPhone: "WhatsApp verification code for this number",

    alertBoldText: "Your Email sender information is being taken...",
    alertText: "It may take for a while please wait.",

    undefinedAlerts: {
      wp_heading:
        "No device found for sending WhatsApp messages in your account",
      wp_content:
        "There is no WhatsApp thread that you can send with your business name. If you want to be able to send special WhatsApp messages to your customers other than the fixed information messages of the platform",
      wp_span: " define device to your account.",
      sms_heading: "SMS header is not defined for customized deliveries!",
      sms_content:
        "  There is no SMS header that you can send with your business name. If you want to be able to send special SMS messages to your customers other than the fixed information messages of the platform, you need to purchase an SMS service.",
    },

    sendButtonText: "View",
    dialogTitle: "Sms viewing",
    buttonApprove: "Approve",
    buttonCancel: "Cancel",

    groupSMSReports: "Group SMS Reports",
    singleSMSReports: "Automatic SMS Reports",
    groupWPReports: "Group WP Reports",
    singleWPReports: "Automatic WP Reports",

    lastSendingsTitle: "Last Email Sendings",
    creditPaybackButtonTitle: "Get Refund SMS Credits",
    whatsappPaybackButtonTitle: "Get Refund WhatsApp Credits",
    searchInputLabel: "Enter a keyword",

    headings: {
      date: "Date",
      sender: "Sender Name",
      phone: "Phone Number",
      status: "Sending Status",
      content: "Message Content",
    },
    table_titles: {
      date: "Date",
      total: "Total",
      successful: "Successful",
      unsuccessful: "Unsuccessful",
      post_postCredit: "Before Post-Credit",
      content: "Content",
    },
    bottom_table_titles: {
      phone: "Phone Number",
      status: "Status",
      message_content: "Message Content",
      details: "Details",
      successful: "Successful",
      unsuccessful: "Unsuccessful",
      data_not_found: "Data Not Found!",
    },
    replacements: {
      status: ["Sent", "Rejected", "Invalid Number", "Return", "In queue"],
    },

    send: {
      // Section
      messageInputLabel: "Message Content",
      chartType: "Character Type",
      charTR: "Turkish",
      charNormal: "Normal",
      buttonTitle: "Send Email",
      discardButtonTitle: "Discard",
      sendDate: "Send Date",
      futureDate: "Advanced SMS",
      platformRequired: "Send Platform is not selected! Please select platform.",
      platformSelect: "Send Platform",
      sampleMessage: "Sample Message Select",
      aiInputLabel: "Create Message with AI",
      aiInputPlaceholder: "Example: Create Message for Holiday",
      aiInputButtonTitle: "Create with AI",

      autoText: {
        nameSurname: "Name Surname",
        amountPaid: "Amount Paid",
        remainingDebt: "Remaining Debt",
        businessUrl: "Business Url",
        shortCode: "Short Code",
        forSmsSelection: "For SMS Selection",
        writeIt: "write it to 4607.",
      },

      autoTextShortCode: {
        nameSurname: "|name surname|",
        remainingDebt: "|remaining debt|",
        paidDebt: "|paid debt|",
        businessUrl: "|business url|",
      },

      check: {
        // Modal
        title: "Do you confirm this action?",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",
        alertText:
          "If your credit is enough, sending process will be started afterwards your confirmation. This action is irreversible!",
      },

      template: "Template Selection",

      nameSurnameShortcut: "Name Surname",
      successToast: "Email sending has been successfully started!",
    },

    planAlertLabel: "You can contact us at 0(212) 706 0510 to purchase an SMS package.",
    availableCreditLabel: "Available/Remaining Credit",
  },

  /**
   * Seçimli SMS Gönder sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/selective
   */
  "sms/selective": {
    continueButtonTitle: "Continue",
    backToSelectButtonTitle: " Return to Selections",

    warningText: "The Send List cannot be the same as the Exclude List!",

    customer: {
      // Section
      title: "Customer List",
      headings: {
        fullName: "Name Surname",
        phone: "Phone Number",
      },

      searchInputLabel: "Search for Customer",
      selectAll: "Select All Customer",
      unselectAll: "Remove All Selection",
      includePassive: "Include Passive Customers",
      notIncludePassive: "Exclude Passive Customers",
    },

    approved: {
      // Section
      title: "List to be Sent",
      notFound: {
        alertBoldText:
          "The selection has not been made among customers to send!",
        alertText:
          "You can start to select your costumer by clicking the tik sign in customer list.",
      },

      alertBoldText: "Your all customers have been successfully selected!",
    },

    excepted: {
      // Section
      title: "List to be Excluded",
      notFound: {
        alertBoldText:
          "Customers who will be excluded from sending have not been selected!",
        alertText:
          "You can exclude customer by clicking block button in table if there are exceptional customers who will be excluded from sending in others.",
      },
    },
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/filter": {
    applyFilterButtonTitle: "Apply Filter",
    continueButtonTitle: "Continue",
    backToFilterButtonTitle: "Return to Filter",

    tabs: {
      // Section
      debt: "Status of Dept",
      debtTrue: "Indebted",
      debtFalse: "Debtless",

      risk: "Status of Risk",
      risk15: " Those who did not have a transaction in the last 15 days",
      risk30: " Those who did not have a transaction in the last 30 days",
      risk45: " Those who did not have a transaction in the last 45 days",
      risk60: " Those who did not have a transaction in the last 60 days",

      work: "Status of Employment",
      workWorking: "Employed",
      workUnemployed: "Unemployed",
      workEmployer: "Employer",
      workStudent: "Student",
      workHouseWife: "Housewife",

      gender: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      marital: "Marital Status",
      maritalMarried: "Married",
      maritalSingle: "Single",

      service: "Service Taken",
      staff: " Staff Who Proceeded Transaction",
      age: "Age Group",
    },

    customer: {
      // Section
      title: "Customer List",
      headings: {
        fullName: "Name Surname",
        phone: "Phone Number",
        gender: "Gender",
      },
      replacements: {
        genderNull: "Unspecified",
        genderFemale: "Female",
        genderMale: "Male",
      },
    },

    except: {
      title: "Those who will be excluded form Filtration List",
      alertBoldText:
        "Customers who will be excluded from sending have not been selected!",
      alertText:
        "You can exclude customer by clicking block button in table after filtration if there are exceptional customers who will be excluded from sending in others.",
    },

    sendFilteredSmsErrorToast: "Please select one of the sending methods.",
    sendFilteredSmsSuccessToast: "SMS sending has started successfully!",
    shortCodeButtonMessage: "%s Write %v to cancel SMS and send it to 4607.",

    smsMessageTitle: "SMS Message",
    wpMessageTitle: "WhatsApp Message",
  },

  "sms/group": {
    continueButtonTitle: "Continue",
    backToSelectButtonTitle: "Return to Selections",

    newGroupButtonTitle: "Create New Group",

    group: {
      title: "Group List",

      headings: {
        groupName: "Group Name",
        customerCount: "Customer Count",
      },

      searchInputLabel: "Search for Group",
      searchInputPlaceholder: "Search for Group ",
      selectAll: "Select All Group",
      unselectAll: "Remove All Selection",
    },

    approved: {
      title: "List to be Sent",
      notFound: {
        alertBoldText: "The selection has not been made among groups to send!",
        alertText:
          "You can start to select your group by clicking the tik sign in group list.",
      },

      alertBoldText: "Your all groups have been successfully selected!",
    },

    excepted: {
      title: "List to be Excluded",
      notFound: {
        alertBoldText:
          "Groups who will be excluded from sending have not been selected!",
        alertText:
          "You can exclude group by clicking block button in table if there are exceptional groups who will be excluded from sending in others.",
      },
    },

    newGroup: {
      title: "Create New Group",

      groupName: "Group Name",
      groupEditButtonTitle: "Edit Group",

      customersTitle: "Customer List",
      selectedCustomersTitle: "Selected Customers",

      searchCustomer: "Search for Customer",
      searchCustomerDescription: "Write the customer name and number",
      selectionDescription:
        "You can select customer by clicking the tik sign in customer list.",
      notFoundCustomer: "The customer(s) you are looking for were not found.",

      selectAllButtonTitle: "Select All",
      unselectAllButtonTitle: "Remove All Selection",
      deleteAllButtonTitle: "Delete All",

      alertBoldText: "Your all customers have been successfully selected!",

      createButtonTitle: "Create Group",
      cancelButtonTitle: "Cancel",

      successToast: "Group has been successfully created!",
      emptyGroupName: "Group name can not be empty!",

      edit: {
        editGroupTitle: "Update Group",
        editButtonTitle: "Update",
        deleteGroupButtonTitle: "Delete Group",

        updateSuccessToast: "Group has been successfully updated!",
        deleteSuccessToast: "Group has been successfully deleted!",
      },
    },

    successToast: "Group has been successfully created!",
    connectionWarnToast: "The device is not active, please update it!",
  },

  /**
   * Filtreli SMS sayfasının bulunduğu key.
   * https://app.salonrandevu.com/sms/send/filter
   */
  "sms/settings": {
    updateButtonTitle: "Update Settings",
    customerSwitchTitle: "Customer",
    staffSwitchTitle: "Staff",
    onSwitchTitle: "Open",
    offSwitchTitle: "Close",


    padiContent:
      "Paid content is not active. Please contact your customer representative.",

    reminder: {
      title: "Appointment Reminder",
      description:
        "This is the setting of sending or not sending Email relating to appointment reminder.",
    },

    longTermReminder: {
      title: "Appointment Reminder for Long Period",
      description:
        "This is the setting of sending or not sending Email day before the appointment if there is more than one day to appointment date",
    },

    requestInfo: {
      title: "Appointment Request Notification",
      description:
        "This is the setting of sending /not sending Email if there is a new online appointment request.",
    },

    whenApproved: {
      title: "When Appointment Request Confirmed",
      description:
        " This is the setting of sending /not sending Email when online appointment request has been confirmed.",
    },

    whenRejected: {
      title: "When Appointment Request Rejected",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenCreated: {
      title: "When Appointment Created",
      description:
        "This is the setting of sending /not sending Email at the time of appointment being created.",
    },

    whenCancelled: {
      title: "Active Appointment Cancellation",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenBirthday: {
      title: "Birthday Message",
      description:
        "This is the setting of sending/not sending congratulation Email to customer who has got birthday. This setting can only work on if you have got a special sender name for your enterprise/yourself.",
    },

    whenCustomerCreate: {
      title: "When Customer Added",
      description:
        " This is the setting of sending/not sending Email notification to customer who has been added to your customer list after customer registration.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Reminding the Customer Who Does Not Appointment",
      description: [
        "Automatic reminders to customers who have not visited your business for 60 days",
        "Email is sent.",
        "Sample Message Content: Dear Figen Çelik, we missed you so much!",
        "We noticed that you have not been trading for 60 days. Hope to see you again, We wish you happy days ..",
        "To make an appointment: https://app.salonmanagementapp.com/company/2037",
      ],
    },

    dragAndDrop: {
      title: "Drag and Drop Appointment",
      description:
        "It is the setting to send/not send WhatsApp to customer when appointment is drag and drop.",
    },

    smsShortcode: {
      title: "SMS Short Code",
      description:
        "It is the setting for WhatsApp to go/not go when the customer number is added to the blacklist.",
    },

    appointmentUpdate: {
      title: "Appointment Update",
      description:
        "It is the setting to send/send the updated appointment time and date via SMS.",
    },

    smsAttendedApproval: {
      title: "Sending Link for Appointment Attendance via SMS",
      description:
        "A link is sent to find out whether or not to attend the appointment created by the customer. This link is a setting for the client to choose whether to attend the appointment positively or negatively.",
    },

    afterAppointment: {
      title: "Evaluation After Appointment",
      description:
        "This is the setting of sending/not sending Email for evaluation after appointment.",
    },

    reminderHour: {
      title: "Hours for Sending Appointment Reminder",
      description:
        "This is the setting of time arrangement for Email that will be sent before appointment.",

      inputLabel: "How many hours before to send?",
      options: [
        "Close",
        "1 hour",
        "2 hours",
        "3 hours",
        "4 hours",
        "5 hours",
        "6 hours",
        "7 hours",
        "8 hours",
        "9 hours",
        "10 hours",
        "11 hours",
        "12 hours",
        "13 hours",
        "14 hours",
        "15 hours",
        "16 hours",
        "17 hours",
        "18 hours",
        "19 hours",
        "20 hours",
        "21 hours",
        "22 hours",
        "23 hours",
        "24 hours",
      ],
    },

    updateToast: "Email settings have been successfully updated.",
    saveSMSSettingsErrorToast: "Long-term appointment reminders send SMS 24 hours in advance. Please update your reminder time or disable the long-term appointment.",
    routeToSmsLogs: "Go to SMS transaction logs",
  },

  "sms/wp/settings": {
    updateButtonTitle: "Update Settings",
    customerSwitchTitle: "Customer",
    staffSwitchTitle: "Staff",
    onOffSwitchTitle: "Open/Close",

    padiContent:
      "Paid content is not active. Please contact your customer representative.",

    reminder: {
      title: "Appointment Reminder",
      description:
        "This is the setting of sending or not sending Email relating to appointment reminder.",
    },

    longTermReminder: {
      title: "Appointment Reminder for Long Period",
      description:
        "This is the setting of sending or not sending Email day before the appointment if there is more than one day to appointment date",
    },

    requestInfo: {
      title: "Appointment Request Notification",
      description:
        "This is the setting of sending /not sending Email if there is a new online appointment request.",
    },

    whenApproved: {
      title: "When Appointment Request Confirmed",
      description:
        " This is the setting of sending /not sending Email when online appointment request has been confirmed.",
    },

    whenRejected: {
      title: "When Appointment Request Rejected",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenCreated: {
      title: "When Appointment Created",
      description:
        "This is the setting of sending /not sending Email at the time of appointment being created.",
    },

    whenCancelled: {
      title: "Active Appointment Cancellation",
      description:
        "This is the setting of sending /not sending Email when online appointment request has been rejected.",
    },

    whenBirthday: {
      title: "Birthday Message",
      description:
        "This is the setting of sending/not sending congratulation Email to customer who has got birthday. This setting can only work on if you have got a special sender name for your enterprise/yourself.",
    },

    whenCustomerCreate: {
      title: "When Customer Added",
      description:
        " This is the setting of sending/not sending Email notification to customer who has been added to your customer list after customer registration.",
    },

    smsApprovalLink: {
      title: "WhatsApp'dan Randevu Katılımı İçin Link Gönderimi",
      description:
        "Müşterinin oluşturduğu randevuya katılacağını ya da katılmayacağını öğrenmek için bir link gönderilir. Bu link müşterinin randevuya katılımının olumlu ya da olumsuz olarak seçmesi için bir ayardır.",
    },

    reminderNoncomerCustomer: {
      title: "Reminding the Customer Who Does Not Appointment",
      description: [
        "Automatic reminders to customers who have not visited your business for 60 days",
        "Email is sent.",
        "Sample Message Content: Dear Figen Çelik, we missed you so much!",
        "We noticed that you have not been trading for 60 days. Hope to see you again, We wish you happy days ..",
        "To make an appointment: https://app.salonmanagementapp.com/company/2037",
      ],
    },

    dragAndDrop: {
      title: "Drag and Drop Appointment",
      description:
        "It is the setting to send/not send SMS to customer when appointment is drag and drop.",
    },

    smsShortcode: {
      title: "WhatsApp short code",
      description:
        "It is the setting for WhatsApp to go/not go when the customer number is added to the blacklist.",
    },

    appointmentUpdate: {
      title: "Appointment Update",
      description:
        "It is the setting to send/send the updated appointment time and date via Email.",
    },

    smsAttendedApproval: {
      title: "Sending Link for Appointment Attendance via WhatsApp",
      description:
        "A link is sent to find out whether or not to attend the appointment created by the customer. This link is a setting for the client to choose whether to attend the appointment positively or negatively.",
    },

    afterAppointment: {
      title: "Evaluation After Appointment",
      description:
        "This is the setting of sending/not sending Email for evaluation after appointment.",
    },

    reminderHour: {
      title: "Hours for Sending Appointment Reminder",
      description:
        "This is the setting of time arrangement for Email that will be sent before appointment.",

      inputLabel: "How many hours before to send?",
      options: [
        "Close",
        "1 hour",
        "2 hours",
        "3 hours",
        "4 hours",
        "5 hours",
        "6 hours",
        "7 hours",
        "8 hours",
        "9 hours",
        "10 hours",
        "11 hours",
        "12 hours",
        "13 hours",
        "14 hours",
        "15 hours",
        "16 hours",
        "17 hours",
        "18 hours",
        "19 hours",
        "20 hours",
        "21 hours",
        "22 hours",
        "23 hours",
        "24 hours",
      ],
    },

    updateToast: "Email settings have been successfully updated.",
    loginWPDeviceSuccessToast: "The device has been successfully connected.",
    logoutWPSuccessToast: "Logged out from the WhatsApp device.",
    infoCodeText: "The WhatsApp login code will be sent to the entered number.",
  },

  "sms/template": {
    createTemplateButtonTitle: "Create New Template",

    templatesHeadings: {
      name: "Name",
      content: "Content",
    },

    deleteButtonTitle: "Delete Template",

    templateDialog: {
      addTitle: "New Template",
      updateTitle: "Template Detail/Edit",

      templateTitleInputLabel: "Tamplate Name",
      templateTitleInputPlaceholder: "Enter template name",

      templateContentInputLabel: "Tamplate Content",

      createButtonTitle: "Create",
      updateButtonTitle: "Update",
      discardButtonTitle: "Discard",
    },

    deleteConfirm: {
      title: "Do you approve to delete template?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",
      alertBoldText: "This process can not be reverted !",
      alertText:
        "If you remove this phone number from black list, Bulk SMS will be able to send this number SMSs.",
    },

    addSuccessToast: "Successfully template created.",
    updateSuccessToast: "Successfully template updated",
    deleteSuccessToast: "Successfully template deleted",

    tableButtonWarnToast: "Please fill in the required fields.",
  },

  "sms/blacklist": {
    totalNumber: "Total Number count: ",
    blacklistHeadings: {
      number: "Number",
      addDate: "Add Date",
      disableOnlineBooking: "Online Appointment Status",
    },
    deleteUserBlacklist: "Remove number from the list",
    numberInputLabel: "Enter the numbers that you want to block.",
    searchNumberInputLabel: "Enter the Number You Want to Search",
    addToBlacklist: "Add to Black List",
    addToBlacklistError:
      "Please enter a valid number, use ',' to add multiple numbers.",
    addSuccessToast: "Succesfully number added.",
    updateSuccessToast: "Number successfully updated.",
    deleteConfirm: {
      title: "Do you approve to remove number from the list?",
      // alertText:"If you delete this number you will not be able to reach it again.",
    },
  },

  /**
   * Muhasebe Yönetimindeki ortak kullanımda olan key.
   * https://app.salonrandevu.com/accounting/income
   * https://app.salonrandevu.com/accounting/debt
   */
  accounting: {
    showOnReceipt: "Show in Cheque",

    startInputLabel: "Starting Date",
    endInputLabel: "Ending Date",
    typeInputLabel: "Payment Method",

    typeALL: "All Method",
    typeCASH: "Cash",
    typeCARD: "Credit/Bank Card",
    typeEFT: "Transfer",

    totalIncome: "Total Income",
    totalDebt: "Total Debt",

    alertBoldText: "By using the field below, you can make changes.",
    alertText:
      "If you make changes on the fields, this will be applied automatically.",

    today: "Today",
    thisWeek: "This Week",
    thisMonth: "This Month",

    incomeMethods: "Revenue transactions",

    filterArea: {
      header: "Select Time Range",
      yesterday: "Yesterday",
      lastSevenDays: "Last 7 Days",
      lastFifteenDays: "Last 15 Days",
      lastThirtyDays: "Last 30 Days",
      approve: "Approve",
      cancel: "Cancel",
    },

    noRecords: "The data is equal to zero in the date range you select.",
  },

  /**
   * Gelir işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/income
   */
  "accounting/income": {
    headings: {
      createdAt: "Payment Date Specified",
      customerFullName: "Customer Name",
      amount: "Transaction Fee",
      type: "Payment Method",
    },
    totalIncome: "Total Income",
  },

  /**
   * Borç işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debt
   */
  "accounting/debt": {
    headings: {
      createdAt: "Transaction Date",
      customerFullName: "Customer Name",
      amount: "Total",
      paid: "Paid",
      remaining: "Remaining",
    },
  },

  /**
   * Prim işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/bounty
   */
  "accounting/bounty": {
    payButtonTitle: "Pay Premium",
    allPayButtonTitle: "Pay All",
    paymentType: "Please select the payment type",
    routeReceipt: "Go to Receipt",
    routeError: "Something Went Wrong",

    customerInfo: {
      name: "Customer Name",
      paid: "Amount Paid",
      primAmount: "Commission Amount",
      transactions: "Transactions",
    },

    headings: {
      staffFullName: "Staff Full Name",
      bounty: "Bonus Amount",
    },

    check: {
      title: "Bonus Payment",
      confirmButtonTitle: "Make Payment",
      discardButtonTitle: "Cancel",
      alertBoldText:
        "There is {bounty}₺ bonus payment for {selected_employee} staff.  You can fill process date and payment type for bonus payment.",
    },
  },

  /**
   * Gider işlemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense
   */
  "accounting/expense": {
    add: {
      title: "Add New Expense",
      dialogTitle: "New Expense",
      dialogQATitle: "Do You Confirm Deleting the Expense?",
      typeInputLabel: "Expense Item",
      amountInputLabel: "Amount",
      amountInputPlaceholder: "Enter this field related expense amount.",
      descriptionInputLabel: "Explanation",
      descriptionInputHint:
        "In this field you can make notes about related transaction that you would like to add.",

      buttonTitle: "Add Expense",
      cancelButtonTitle: "Cancel",

      paymentType: "Payment Type",
      processDate: "Process Date",

      spending: "Spending",
      spendingPlaceholder: "Enter your spend.",
    },

    payments: {
      cash: "Cash",
      creditCard: "Credit / Debit Card",
      transfer: "Bank Transfer / EFT",
      cusPoint: "Customer Rating",
    },

    table: {
      title: "Expense Transaction",
      headings: {
        createdAt: "Created Date",
        transactionDate: "Transaction Date",
        type: "Expense Item",
        description: "Expense Explanation",
        amount: "Amount",
      },
      deleteButtonTitle: "Delete Expense",
    },

    totalExpense: "Total Expense",
    dialogQAWarn1: "This action cannot be undone!",
    dialogQAWarn2: "The expense will be removed from the list.",
    insertErrorToast: "Please Select the Payment Method",
    deleteToast: "Expense record has been successfully deleted.",
    insertToast: "Expense has been successfully created.",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/expense/types
   */
  "accounting/expense/types": {
    alertBoldText: "About Expense Item",
    alertText:
      "By defining your specific transaction expense items in this field, you can make transactions over those items created by you.",

    saveChangesButtonTitle: "Save Changes",
    typeInputLabel: "{i}. Special Expense Item",

    insertToast: "Transaction has been successfully done.",

    moreButtonTitle: "More",
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/credit
   */
  "accounting/credit": {
    filter: {
      title: "Based on Date Filtration",
      satrtDate: "Start Date",
      endDate: "End Date",
      name: "Customer Name",
    },

    add: {
      customerSelection: "Select Customer",
      scheduledPaymentDate: "Scheduled Payment Date",
      enterAmount: "Enter Amount",
      explanation: "Explanation",
      saveReceivable: "Save Receivable",
      updateReceivable: "Update Receivable",
      collectPayment: "Collect Payment",
      paymentDialogTitle: "Payment",
      savePaymnet: "Save Payment",
      closePayment: "Close Payment",

      date: "Date",
      amount: "Amount",
      paymentType: "Payment Type",
      actions: "Actions",
      delete: "Delete",
      update: "Update",

      start_amount: "Start Amount",
      end_amount: "End Amount",
    },

    payments: {
      typeInputLabel: "Payment Type",
      typeInputPlaceholder: "Select Payment Type",
      paid: "Paid",
      notPaid: "Not Paid",

      amountInputLabel: "Enter Amount",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Customer Points",
    },

    inserted: "Debt Added",
    missingInfo: "You Entered Incomplete Information",
    error: "An Error Occurred",
    addCredit: "Add Credit",

    paid: "Paid",
    notPaid: "Not Paid",

    headings: {
      createdAt: "Transaction Date",
      scheduledPaymentDate: "Scheduled Payment Date",
      nameSurname: "Name-Surname",
      all_amount: "All Amount",
      paid: "Paid",
      debt_total: "Debt Total",
      view: "Detail & View",
      delete: "Delete",
      transactions: "Transactions",
      is_paid: "Payment Status",
    },
  },

  /**
   * Gider kalemleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/accounting/debit
   */
  "accounting/debit": {
    filter: {
      title: "Based on Date Filtration",
      satrtDate: "Start Date",
      endDate: "End Date",
      name: "Customer Name",
    },

    add: {
      customerSelection: "Customer Name",
      scheduledPaymentDate: "Scheduled Payment Date",
      enterAmount: "Enter Amount",
      explanation: "Explanation",
      saveReceivable: "Save Debt",
      uppdateReceivable: "Update Debt",
      collectPayment: "Collect Payment",
      paymentDialogTitle: "Payment",
      savePaymnet: "Save Payment",
      closePayment: "Close Payment",

      date: "Date",
      amount: "Amount",
      paymentType: "Payment Type",
      actions: "Actions",
      delete: "Delete",
      update: "Update",

      start_amount: "Start Amount",
      end_amount: "End Amount",
    },

    payments: {
      typeInputLabel: "Payment Type",
      typeInputPlaceholder: "Select Payment Type",
      paid: "Paid",
      notPaid: "Not Paid",

      typeCASH: "Cash",
      typeCARD: "Credit / Bank Card",
      typeEFT: "Bank Transfer",
      typePOINT: "Customer Points",
    },

    addCredit: "Add Debit",

    paid: "Paid",
    notPaid: "Not Paid",

    headings: {
      createdAt: "Transaction Date",
      scheduledPaymentDate: "Scheduled Payment Date",
      nameSurname: "Name-Surname",
      all_amount: "All Amount",
      paid: "Paid",
      debt_total: "Debt Total",
      view: "Detail & View",
      delete: "Delete",
      transactions: "Transactions",
      is_paid: "Payment Status",
    },
  },

  /**
   * Raporlar modülünün genel kullanımlarının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   * https://app.salonrandevu.com/reports/services
   * https://app.salonrandevu.com/reports/stock
   * https://app.salonrandevu.com/reports/staff
   * https://app.salonrandevu.com/reports/customer
   */
  reports: {
    startInputLabel: "Starting Date",
    endInputLabel: "Ending Date",

    headings: {
      stockName: "Product Name",
      serviceName: " Product Name",
      transactionCount: "Number of Transaction",
      soldCount: "Sales Number",
      income: "Income Earnt",
    },
    selectedPageCountInputLabel: "Page Count",

    loaderTitle: "Your reports are loading. Please wait...",
  },

  /**
   * İşletme raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/company
   */
  "reports/company": {
    soldItem: "Sold Product",
    soldService: "Servie Applied",

    serviceIncome: "Service Income",
    itemIncome: "Product Income",

    generalIncome: "Paid Income",
    maxExpense: "Total Expense",
    netIncome: "Net Income",
    totalIncome: "Total Income",

    typeCASH: "Cash",
    typeCARD: "Credit/Bank Card",
    typeEFT: "Transfer",
    _maxAppointment: "Total Number of Appointment",
    _maxReceipt: "Total Number of Cheque",
    _serviceIncome: "Income Earnt from Services",
    _itemIncome: "Income Earnt from Product",
    _paidBounty: "Bonus Paid to Staff",

    alertText:
      "The values specified as service income and product income are calculated over fully paid and debt-free cheques.",

    incomeReports: "Income Reports",
    expensesReports: "Expense Reports",
    summary: "Summary",

    goToDetail: "Go To Details",
  },

  /**
   * Çalışan raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/staff
   */
  "reports/staff": {
    name: "Staff Name",
    serviceCount: "Number of Services Provided",
    soldItemCount: "Number of Product Sold",

    onlyService: "Services Provided",
    bountyService: "Bonus Services",
    productSum: "Product Turnover",
    bountyProduct: "Product Premium",
    packageSum: "Package Turnover",
    bountyPackage: "Package Premium",

    endorsement: "Total Turnover",
    bounty: "Total Premium",

    survey: "Evaluation",
  },

  /**
   * Müşteri raporları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/reports/customer
   */
  "reports/customer": {
    name: "Customer Name",
    appointmentCount: "Number of Appointment",
    transactionCount: "Number of Transaction Performed",
    productCount: "Number of Product Sold",
    totalEarned: "Earnings in Total",
  },

  /**
   * Yapılacaklar listesi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/todos
   */
  todos: {
    toastSuccess: "Operation has been successfully done.",
    toastWarningMessage: "Oparation content can't be empty.",
    toastWarningProcess: "Operation adding process has been failed.",

    addButtonTitle: "Create Task",

    addTodoTitle: "Add Task",
    todoInputLabel: "Task Content",
    todoInputPlaceholder:
      " In this field you can enter your notes and tasks relating to your to do list.",

    dateTitle: "Dated Task",
    notFoundText: "There is no saved task on {date}.",

    statusWaiting: "PENDING",
    statusCompleted: "COMPLETED",

    updateToast: "Task status has been successfully updated.",
    insertToast: "Task has been successfully added.",
    deleteToast: " Task has been successfully deleted.",
    deleteToastDescription: "Task will be deleted.",
    characterLimit: "{length}/250",
    imageAlt: "Not found!",
    deleteConfirmTitle: "Do you confirm to delete?",
    deleteConfirmText: "Task will be deleted.",
    updateStaffTitle: "Update Task Staff",
    noStaff: "No one",
    emptyWarning: "Task content can't be empty",
  },

  /**
   * Cüzdan sayfasının bulunduğu key.
   * https://app.salonrandevu.com/wallet
   */
  wallet: {
    cardTitle: "Total Balance",
    amountInputLabel: "Amount to Top up ($)",
    amountButtonTitle: "Top up Balance",

    remainingSMS: "Remaining Email Credit",
    licenseDetail: "Package Used",
    licenseRemaining: "Remaining to License Expiry",
    appointmentRemaining: "Remaining Appointment Credit",
    paymentForm: "Bank Accounts / Payment Notification Form",

    licenseHeading: "Buy License",
    smsHeading: "Buy Email Package",
    buy: "Buy",
    package_prices: "Package Prices",
    kdv: process.env.APP_NAME === "management" ? "" : "Excluding VAT",

    load_balance: "Load Balance",

    withEFT: "TO TRANSFER",
    buyButtonTitle: "Buy",
    includesAll: "Included in All Licenses",

    see_more: "Show More",
    see_less: "Show Less",

    licenses: "Licenses",
    message_packages: "Message Packages",
    sms_price:
      process.env.APP_NAME === "management"
        ? "WhatsApp Package Prices"
        : "SMS + WhatsApp Package Prices",
    sms_package: "SMS Packages",
    wp_package: "WhatsApp Packages",
    extra_license: "Additional Packages",

    bardcode_extra_package: "Barcode Scanner",
    whatsapp_extra_package: "WhatsApp Instant Messaging",
    online_link_extra_package: "Appointment Booking Page",

    bardcode_extra_package_tooltip:
      "While tracking inventory, you can add products by scanning their barcodes. With our application, you can easily scan barcodes using your mobile phone camera and make sales.",
    whatsapp_extra_package_tooltip:
      "Instant connection is established with your WhatsApp account. You can send messages by clicking on the WhatsApp icon if you are using the web version or quickly communicate through the WhatsApp application if you are using the mobile version.",
    online_link_extra_package_tooltip:
      "This section activates the necessary link for your customers to create appointment requests for your business. Through this link, your customers can create appointment requests 24/7, specifying the date, time, and type of service.",

    freePackage: "Free",
    monthly_payment: "Monthly Payment",
    yearly_payment: "Pay Annually",
    monthly: "Monthly",

    check: {
      title: "Do you confirm to buy?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertText:
        "Do you confirm to buy the package that costs (amount) with your current balance",
      packageChangeAlert:
        "30 days will be added to your remaining days from your package.",
    },

    paymentType: {
      title: "Please Choose a Payment Method",
      creditCard: "Credit Card",
      eft: "Remittance/EFT",
    },

    balanceErrorToast: "Please enter the amount to be loaded!",

    balanceAddWarnToast: "The transaction could not be completed!",

    packages: {
      silver: "Silver Package",
      platinium: "Platinum Package",
      gold: "Gold Package",
      diamond: "Diamond Package",
    },

    packagesName: {
      silver: "Silver",
      platinium: "Platinum",
      gold: "Gold",
      diamond: "Diamond",
    },

    count: "%s items",
  },

  "platform/photo": {
    photoUploadToast: "Photo uploading has been started.",
    insertToast: "Photo uploading has been successfully finished.",
    deleteToast: "Photo successfully removed.",
    anexample: "An Example",
    uploadPhotoAlertTitle: "Upload Showcase Photo",
    selectPhotoTitle: "Select Photo",

    photoUploadErrorToast: "Adding photo process has been failed.",
    deletePhotoErrorToast: "Failed to remove photo.",

    photoInfo:
      "Represents the photo to be displayed on the search and listing screens.",
  },

  "platform/settings": {
    offOn: "OFF / ON",
    chooseColor: "Choose Color",
    platformVisibleTitle: "Enterprise Visibility",
    platformVisibleDescription:
      "It is the setting for your business to appear/not appear in customer searches on the Salon Appointment Platform. When this setting is turned off, your business will not appear in searches on the Salon Appointment Platform.",

    platformPriceTitle: "Service Fees Visibility",
    platformPriceDescription:
      "It is a setting to hide your service fees on salonandevu.com and your business's appointment booking page. When this setting is off, the cost of your services will not appear in either area.",
    platformCommentTitle: "Customer Reviews Visibility",
    platformCommentDescription:
      "It is a setting to hide the reviews made about your business on salonandevu.com and your business's specific appointment booking page. When this setting is off, no customer reviews about your business will appear in either area.",
    platfromAppointmentTitle: "Automatic Appointment Confirmation",
    platfromAppointmentDescription:
      "It is the automatic confirmation setting of your appointment requests. When this setting is on, appointment requests you receive are automatically approved.",
    platformColorSelectionTitle: "Platform Color Adjustment",
    platformColorSelectionDescription:
      "You can change the color of your specific appointment booking page for your business from this menu.",
    platformVideoURLTitle: "Platform Video URL",
    platformVideoURLDescription:
      "The YouTube video URL address you enter will be displayed to visitors on your business' specific appointment booking page.",

    onlineAppointmentTitle: 'Appointment Request Conflict Setting',
    onlineAppointmentDesc: 'When you activate this setting, you can receive more than one appointment request for the same employee and time. If you leave it inactive, if you have an appointment request for the same employee and time, no other requests will be received for that employee and time.',

    platformVideoURLInputLabel: "Video URL",
    platformVideoURLInputPlaceholder: "Enter Video URL",
    appointmentVerificationCode: "Appointment Confirmation Code",
    appointmentVerificationCodeDescription:
      "It is the verification code setting for appointments made from your business' specific appointment booking page. When this setting is selected as On, an SMS / WhatsApp verification code will be sent from your appointment page to verify and confirm your clients' numbers, even if their numbers are registered in the system.",
    platformSettingsUpdated:
      "Platform settings have been successfully updated.",
    appointmentVerificationCodeDeliveryType:
      "Appointment Confirmation Code Submission Type",
    appointmentVerificationCodeDeliveryTypeDescription:
      "This setting is used to send verification codes for appointments made on your business's specific appointment booking page. With the verification method you choose, the method of sending the verification code that will be sent to your client from your private appointment page is selected.",
    colorDialogTitle: "Color Selection",
    colorDialogCancelTitle: "Cancel Color",
    colorDialogSelectTitle: "OK",
    settingOnOff: "On/Off",

    updateSettingsSuccessToast: "Platform settings updated successfully.",
    updateSettingsWarnToast: "Platform settings could not be updated.",
    updateSettingsButtonTitle: "Update Settings",
  },

  "platform/categories": {
    howto: {
      title: "How to Do it?",
      content: [
        "On the left are the services you have created (your existing services), and on the right are the services that will appear on the platform.",
        "Select your services that you want to add to the platform.",
        "Expand the category under which your selected services will appear on the Platform.",
        "If the services you have chosen match the services under the category you have expanded, select that service and click the Transfer button.",
      ],
    },
    knowrequired: {
      title: "What You Need to Know",
      content: [
        "You can select and move multiple services at the same time.",
        "Your services that you migrated to the platform will no longer appear in the Your Existing Services tab.",
        "You cannot map a service to more than one category-service name.",
        "If the services you selected match the services under the category you have expanded, select that service and click the Transfer button.",
        "You can use the cross next to the service name to remove the services you mismatched to the Your Existing Services tab.",
        "When adding a new service, the category-service selection for the platform has also been added to the 'Add New Service' page.",
      ],
      label1: "If the service or services you created do not match the categories or transactions on the platform",
      label2: "iletisim@salonrandevu.com",
      label3: "You can notify your request via email to",
    },
    alertTitle: "To display your services on the Salon Randevu platform, you need to match the services you created with the platform services.",
    serviceTitle: "Your Existing Services",
    transferButtonTitle: "Transfer",
    categoriesTitle: "Categories",
  },

  /**
   * İşletme Ayarları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings
   */
  settings: {
    idInputLabel: "Salon Code",
    nameInputLabel: "Salon Name",
    mailInputLabel: "Salon E-mail Address",
    phoneInputLabel: "Salon Phone Number",
    currencyInputLabel: "Select Your Currency",
    aboutCompanyLabel: "About Company",

    currencyType: "Default Currency",
    countryInputLabel: "Country Selection",
    cityInputLabel: "City Selection",
    districtInputLabel: "District Selection",
    addressInputLabel: "Full Address",
    mapsInputLabel: "Google Map Enterprise Coordination",
    mapsInputHint: "For example: 41.009336, 28.660129",
    currentLocationButtonTitle: "Use Current Location",

    instagramInputLabel: "Instagram User Name",
    facebookInputLabel: "Facebook Page User Name",
    websiteInputLabel: "Web Page Url",
    withoutAtHint: "Enter without @ marks",

    activeInstagramLabel: "Login to Instagram",

    priceInputLabel: "Price Info Settings",
    priceInputHint:
      "This setting will determine whether the transaction fee sum will be shown or not to customers who will request an appointment over your enterprise’s URL.",

    commentsInputLabel: "Customer Comments Settings",
    commentsInputHint:
      "This setting will determine whether the customers’ comments made in survey will be shown or not in your enterprise’s URL.",

    inputOn: "Open",
    inputOff: "Close",

    urlInputLabel: "Enterprise’s Appointment URL",
    openOnBrowserButtonTitle: "Open in Browser",
    copyLinkButtonTitle: "Copy Link",
    updateButtonTitle: "Update Information",

    geolocationErrorToast: "Your browser does not support Geolocation.",
    locationErrorToast: "Your location is failed please try again later.",
    updateToast: "Information has been successfully updated.",

    currentPassword: "Current Password",
    newPassword: "New Password",
    newPasswordAgain: "Confirm New Password",
    updateInfo: "Update Information",

    uploadPhotoWarnToast: "Preparing logo...",
    uploadPhotoSuccessToast: "Logo uploaded successfully.",

    steps: [
      "Step 1",
      "Step 2",
      "Step 3",
      "Step 4",
    ],
    stepBack: "Back",
    stepNext: "Next",
    shortURL: "Shortened Business Appointment URL",
    shortURLWarnToast: "Shortening Not Defined. Please Contact Your Representative.",
    countryCode: "Country Code",
    dialCode: "Dial Code",

    noLogoTitle: "No logo found for this business.",
    updateLogo: "Update Logo",
    setLogo: "Click here to upload a logo for this business.",
    settingsAlertText: "You are about to make changes to the appointment time / start / end / frequency!! All closed hours in your employees' profiles will revert to default, and you will need to reorganize them. Do you confirm?",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/hours
   */
  "settings/hours": {
    businessCategory: "Business Category",
    startInputLabel: "Starting Time",
    endInputLabel: "Finishing Time",
    periodBetweenInputLabel: "Appointment Interval (In Minutes)",

    startInputLabelHour: "Starting Hour",
    startInputLabelMinute: "Starting Minute",
    intervalInputLabel: "Interval (Minutes)",

    endInputLabelHour: "Finishing Hour",
    endInputLabelMinute: "Finishing Minute",

    updateButtonTitle: "Update Information",

    updateToast: "Information has been successfully updated.",
    compareErrorToast:
      "The area of finishing time cannot be smaller than starting time area.",
  },

  /**
   * İşletme Galerisi sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/gallery
   */
  "settings/gallery": {
    numberPhoto: "Total photo count you can upload",
    uploadButtonTitle: "Click here to upload photos.",
    anexample: "An example",
    nextPhoto: "Next Photo",
    uploadPhotoButtonTitle: "Upload",

    checkAllButtonTitle: "Select All Photos",
    deleteSelectedButtonTitle: "Delete Photos Selected ({count})",

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "Do you confirm this action?",
      alertText:
        " All deleted photos will be removed from relating pages if you continue.",
    },

    photoUploadToast: "Delete selected photo(s)",
    insertToast: " Photo uploading has been successfully completed.",
    deleteToast: "Photo has been successfully deleted.",

    photoMorePictureError: "8 fotoğraftan fazla yükleme yapılamaz.",

    enterprise_gallery_info:
      "You can add venue photos of your business from this area. These images will be displayed on the Salon Management Platform and your business page.",
    exampleView: "View Example",
    totalAllowedUpload: "Total Number of Photos You Can Upload:",
  },
  "settings/service-gallery": {
    numberPhoto: "Total photo count you can upload",
    uploadButtonTitle: "Click here to upload photos.",
    anexample: "An example",

    photoDelete: "Select the Photo to be Deleted",

    checkAllButtonTitle: "Select All Photos",
    deleteSelectedButtonTitle: "Delete Photos Selected ({count})",

    delete: {
      // Modal
      title: "Do you confirm to delete?",
      confirmButtonTitle: "Yes",
      discardButtonTitle: "No",

      alertBoldText: "Do you confirm this action?",
      alertText:
        " All deleted photos will be removed from relating pages if you continue.",
    },

    photoUploadToast: "Delete selected photo(s)",
    insertToast: " Photo uploading has been successfully completed.",
    deleteToast: "Photo has been successfully deleted.",

    photoMoreUploadError: "8 fotoğraftan fazla yükleme yapılamaz.",

    service_photo_info:
      "You can add images related to the services you provide in this area. These images will be displayed on the Salon Management Platform and your business page.",
  },

  /**
   * Sistem Logları sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/logs
   */
  "settings/logs": {
    headings: {
      createdAt: "Transaction Date",
      message: "Transaction Explanation",
      detail: "Transaction Details",
      ip: "IP Address",
    },

    categoryList: {
      title: "Record Categories",
      all: "All Records",
      cus: "Customer Records",
      rec: "Receipt Records",
      staf: "Staff Records",
      appo: "Appointment Records",
      sms: "SMS Settings Records"
    },
  },

  /**
   * 404 sayfasının bulunduğu key.
   * https://app.salonrandevu.com/404
   */
  notFound: {
    heroHeadingText: "Sorry, not found!",
    boldText:
      "Unfortunately we could not find the page you wanted to search or open.",
    text: "It might be deleted, removed or not created.",
  },

  care: {
    careTitle: "We are in maintenance...",
    careLabel1: "To provide you with better service,",
    careLabel2: "Maintenance will be performed between %s and %v.",
  },

  /**
   * Çalışma Saatleri sayfasının bulunduğu key.
   * https://app.salonrandevu.com/settings/agreement/index
   */

  "settings/agreements": {
    agreementTemplates: "Agreement Templates",
    createNewAgreement: "Create New Agreement",
    editAgreement: "Edit Agreement",
    agreementClauses: "Agreement Clauses",
    agreements: "Agreements",

    delete: "Delete",
    edit: "Edit",
    close: "Close",

    yesOrNo: "Yes / No",

    createTamplateArea: {
      personalInformation: "Personal Information",
      paymentInformation: "Payment Information",
      clausesInformation: "Clauses Information",
      label: "New Template",
      placeHolder: "New Agreement Template",
      saveTamplate: "Save Template",
      editTamplate: "Edit Template",
      cancel: "Cancel",
      placeHolders: "Business Information",
    },

    personal_info: {
      name_and_surname: "Name and Surname",
      phone: "Phone Number",
      birth_date: "Birth Date",
      job: "Job",
      id_number: "ID Number",
      tax_number: "Tax Number",
      address: "Address",
    },
    payment_info: {
      staff_name: "Staff Name",
      service: "Service",
      total_amount: "Total Amount",
    },

    createClausesArea: {
      createNewClauses: "Create New Clause",
      title: "Clause Title",
      context: "Clause Content",
      yesOrNo: "Add Yes / No Query",
      update: "Update",
      save: "Save",
      cancel: "Cancel",
      fillAreaComplatelty: "Please fill all fields completely.",
    },

    createPdf: {
      companyNamePlaceHolder: "Company Name",
      agreementTitle: "Agreement Title",
    },

    agreementsList: {
      date: "Date",
      nameSurname: "Name and Surname",
      agreementName: "Agreement Name",
      size: "Size",
      actions: "Actions",
      startDate: "Start Date",
      endDate: "End Date",
      searchCustomer: "Search Client",
    },

    agreementRequstFail:
      "An unexpected error occurred. Please contact your client representative.",

    agreementClausesSavedSucces: "Agreement clause saved successfully.",
    agreementClausesEditSucces: "Agreement clause edited successfully.",
    agreementClausesDeleteSucces: "Agreement clause deleted successfully.",

    agreementSaveSucces: "Agreement template saved successfully.",
    agreementEditSucces: "Agreement template edited successfully.",
    agreementDeleteSucces: "Agreement template deleted successfully.",

    deleteClausesModal: {
      title: "Are you sure you want to delete this agreement clause?",
      delete: "Delete",
      cancel: "Cancel",
      expTitle: "This action cannot be undone!",
      expDetail: "The clause will be permanently deleted.",
    },

    deleteAgreementModal: {
      title: "Are you sure you want to delete this agreement template?",
      delete: "Delete",
      cancel: "Cancel",
      expTitle: "This action cannot be undone!",
      expDetail: "The agreement template will be permanently deleted.",
    },

    agreement: {
      yes: "Yes",
      no: "No",
      signiture: "Signature",
    },

    smsAproove: {
      title: "Do you want to send the registered agreement via SMS?",
      send: "Send",
      cancel: "Cancel",
      textFirst: "Are you sure you want to send the agreement to",
      textSecond: "via SMS?",
    },

    wpAproove: {
      title: "Do you want to send the registered agreement via WhatsApp?",
      send: "Send",
      cancel: "Cancel",
      textFirst: "Are you sure you want to send the agreement to",
      textSecond: "via WhatsApp?",
    },

    deleteAgreement: {
      title: "Are you sure you want to delete this agreement?",
      aproove: "Confirm and Delete",
      cancel: "Cancel",
      infoTitle: "The agreement will be permanently deleted.",
      infoContext:
        "This action cannot be undone. The agreement will be permanently deleted.",
    },
  },

  /**
   * İşletme URL'si sayfasının bulunduğu key.
   * https://app.salonrandevu.com/isletme/22
   */
  businessURL: {
    callButtonTitle: "Call on now (0{phone})",
    instagramHeading: "Follow in Instagram!",
    instagramText: "In Instagram @{username}",

    facebookHeading: "Follow in Facebook!",
    facebookText: "In Facebook@{username}",

    aboutHeading: "Contact",
    phoneText: "Phone",
    mailText: "E-Mail",
    addressText: "Address",

    appointment: {
      // Tab
      tab: "Create Appointment",
      title: "Create Appointment Request",

      nameInputLabel: "Name",
      surnameInputLabel: "Surname",
      phoneInputLabel: "Phone Number",
      serviceInputLabel: "Service Selection",
      serviceInputHint: "Select among services",
      staffInputLabel: "Staff Selection",
      staffInputHint:
        "Select the staff who will take care of you during your appointment.",
      dateInputLabel: "Appointment Date",
      dateInputHint:
        "You can either select the date by clicking on calendar icon or do it manually.",

      availableHoursButtonTitle: "Search for Available Hours",

      check: {
        // Modal
        title: "Send Appointment Request",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",

        alertBoldText: "Dear [name] [surname]",
        alertText:
          "Do you confirm to create your appointment on [date] at [time]?",

        priceText:
          "Over all fee of your selected transaction is determined as [amount] by enterprise.",
        priceBoldText:
          "This fee is given for informative purposes and does not constitute exact transaction amount.",

        noteInputLabel: "Appointment Note (Optional)",
        noteInputHint: "%s/ 255 characters",
      },

      insertToast: "Your appointment request has been successfully created.",

      appointment_button: "Book an Appointment",

      appointmentComingText: "Will you attend your scheduled appointment on %s?",
      appointmentConfirmationText: "Your appointment has been confirmed. Thank you.",
      appointmentCancelingText1: "Your appointment will be canceled. Are you sure?",
      appointmentCancelingText2: "Your appointment has been canceled. Thank you.",
      appointmentDatePostponeText: "Would you like to reschedule your appointment to another date?",
      businessPhoneText: "Business Phone: ",
      getDirectionText: "Get Directions",
      appointmentRatingSuccessToast: "Thank you for taking the time to rate us!",
      appointmentRatingWarnToast: "The business rating and staff rating fields are mandatory!",
      evaluationSurverTitle: "Evaluation Survey",
      evaluationSurverSubtitle: "Customer Satisfaction Evaluation Survey",
      evaluationSurverText1: "This survey is a space where you can convey your satisfaction level about the business and its staff.",
      evaluationSurverText2: "Your name and surname will be hidden and conveyed to the business based on privacy.",
      evaluationSurverQuestion1: "Rate the business on a scale of 0-5",
      evaluationSurverQuestion2: "Rate the staff on a scale of 0-5",
      evaluationSurverInputLabel: "Write your experiences and opinions",
      routeToBusinessURL: "You are being redirected to the business page",

      appointmentHourErrorTitle: "*No available appointment time found for the selected date.",
      appointmentAccept: "Appointment Confirmation",
      appointmentAcceptCodeText: "Please enter the 4-digit confirmation code sent to your phone to confirm your appointment.",
      wrongCodeErrorText: "Invalid Code",
      sendAgainText: "Resend confirmation code",
    },

    services: {
      // Tab
      tab: "List of Service",
      title: "List of Service",
      processTime: "Transaction time: {time} minutes",
    },

    staffs: {
      //Tab
      tab: "Staff List",
      title: "Staff List",
    },

    comments: {
      // Tab
      tab: "Reviews",
      title: "Reviews",
    },
  },

  // Support Live Chat

  "support/live_support": {
    liveSupportRequestTitle: "Live Support Request",
    appointmentDate: "Appointment Date and Time",
    appointmentRequestTitle: "Support Request",
    createAppointmentButtonTitle: "Get Appointment",

    enterPhoneLabel: "Please enter your phone number",
    nameInputLabel: "Name",
    nameInputHint: "Please enter your name",

    surnameInputLabel: "Surname",
    surnameInputHint: "Please enter your last name",

    name: "Name",
    surname: "Surname",
    supportrequestsubject: "Support Request Subject",
    supportrequestdetail: "Support Request Detail",
    hours: "Hours",

    phoneNumberInputLabel: "Phone Number",

    supportRequestAboutLabel: "Support Request About",
    supportRequestAboutHint: "Please select the service you want to request",

    dateInputLabel: "Appointment Date",
    noAvailableHours:
      "There are no available hours between hours that you selected.",

    supportRequestDetailLabel: "Support Request Detail",
    supportRequestDetailHint:
      "Please provide a detailed explanation about the issue you are requesting support for.",
    supportRequestDetailHelperText:
      "Please explain in detail the related operations and conflicts that you have encountered. The details you provide will enable us to assist you in the fastest and most accurate way.",

    appointmentCancelDescription:
      "Important: If you solved your problem before the meeting time, please cancel your appointment.",

    headings: {
      appointmentDate: "Appointment Date",
      appointmentAbout: "About",
      appointmentDetail: "Detail",
      name: "Name and Surname",
      phoneNumber: "Phone Number",
      appointmentStatus: "Appointment Status",
      cancelButtonTitle: "Cancel",

      replacements: {
        status: {
          waiting: "Waiting",
          done: "Done",
          cancel: "Cancel",
        },
      },
    },

    alertInfo: {
      text1:
        "At the time you choose, we will call you by 0212100510 number of ours. Please download the",
      text2: "ALPEMIX remote connection program and make it work",
      text3: "in case the need for remote connection.",
      text4: "You can reach their customer information from",
      text5: "Passive Customers section anytime you want.",
    },

    createLiveSupportRequestToast:
      "Your appointment request has been successfully created.",
    cancelLiveSupportRequestToast:
      "Your appointment request has been successfully cancelled.",
  },

  /**
   * Birden fazla yerde kullanılan sabit tanımlar bu alanda yazılmaktadır.
   */
  component: {
    actionHeadingText: "Transaction",
    actionHeadingTextRating: "Comment Transactions",
    detailEditButtonTitle: "Details & Edit",
    validationErrorText: "Edit the fields according to necessary format",
    loadingText: "Loading...",
    loadingTextWithoutDot: "Loading",

    smartBanner: {
      // Loginde mobil uygulama yayında banner'ı
      onAirText: "Download our app!",
    },

    timerButton: {
      waitText: "Wait",
    },

    dayWeekButton: {
      day: "Day",
      week: "Week",
    },

    semiCirclePie: {
      noDisplayChartDescription:
        "The graph is not displayed because the values ​​are equal to zero.",
    },

    excelButton: {
      title: "Download as Excel",
    },

    datePickerInput: {
      // Datepicker Input
      cancelLabel: "CANCEL",
      clearLabel: "DELETE",
      okLabel: "COMPLETE",
    },

    dateRangeStats: {
      // Dashboard'daki istatistik kartları
      yesterday: "Yesterday",
      today: "Daily",
      week: "Weekly",
      month: "Monthly",
      lastWeek: "Last 7 Days",
      last_15_days: "Last 15 Days",
      lastMonth: "Last 30 Days",
    },
    CADialog: {
      // Customer Add Dialog
      title: "Create Quick Customer",
      createButtonTitle: "Create Customer",
      discardButtonTitle: "No",

      nameInputLabel: "Customer Name",
      nameInputPlaceholder: "Type Customer Name",

      surnameInputLabel: "Customer Surname",
      surnameInputPlaceholder: "Type Customer Surname",

      phoneInputLabel: "Phone Number",

      updateCustomerDetails: "Update Customer",
      changeCutomertoActive: "Change Customer to Active",
      changeCutomertoPassive: "Change Customer to Passive",

      genderInputLabel: "Gender",
      genderMale: "Male",
      genderFemale: "Female",

      delete: {
        // Modal
        title: "Do you confirm to delete?",
        confirmButtonTitle: "Yes",
        discardButtonTitle: "No",
        alertText: "This action is irreversible!",
        alertTitleText:
          "In case of deleting this cheque will also delete transaction in it. It may affect your accounting records!",
      },
      all_delete: {
        title: "Confirm deletion of all customers?",
        alertTitleText:
          "If you delete all customers, you may not be able to access their information again.",
      },
      checkingForeingNumber: "Check as a foreign Number",

      confirmChangeCustomerTitle:
        "Are you confirming to change active to passive?",
      confirmCustomerTitle:
        "If you change active state to passive state of this customer, you will be not able to see the customer at Appointment/Bill sections.",
      confirmTitle1: "You can reach their customer information from",
      confirmTitle2: "Passive Customers section",
      confirmTitle3: "anytime you want.",

      birthdayInputLabel: "Date of Birth",
      birthdayInputInvalidMessage: "Pleas enter as indicated Day/Month/Year",

      maritalStatusInputLabel: "Marital Status",
      maritalStatusMarried: "Married",
      maritalStatusSingle: "Single",

      secondPhoneInputLabel: "Second Phone Number",
      secondPhoneInputPlaceholder:
        "Please enter second phone number if exists.",

      mailInputLabel: "E-Mail Address",
      mailInputPlaceholder: "Enter e-mail address if exists.",

      descriptionInputLabel: "Extra Explanations",

      workStatusInputLabel: "Employment Status",
      workStatusWorking: "Employed",
      workStatusUnemployed: "Unemployed",
      workStatusEmployer: "Employer",
      workStatusStudent: "Student",
      workStatusHouseWife: "Housewife",

      relatedBusinessInputLabel: "Related Foundation",
      relatedBusinessInputPlaceholder:
        "Please specify the foundation that customer came over please",

      sendSmsInputLabel: "Email Sending Status",
      sendSmsTrue: "Send",
      sendSmsFalse: "Not Send",

      advancedOptions: "Advance Options",
      advancedOptionsHide: "Hide",
      advancedOptionsShow: "Show",

      insertToast: "Customer registration has been successfully created!",
    },

    sms_review: {
      platformTitle: "Send Platform",
      smsReviewTitle: "Message Preview",
      totalNumberTitle: "Total Number of Persons to Send:",
      totalWpCreditTitle: "Total WhatsApp Credit:",
      totalSMSCreditTitle: "Total SMS Credit:",
      smsContentTitle: "Message Content:",

      inUseSMSCredit: "Total SMS Credits to Use:",
      inUseWPCredit: "Total WhatsApp Credits to Use:",

      sendButtonTitle: "Send",
      discardButtonTitle: "Cancel",
    },
  },
  panel: {
    businessNumber: "Business Number:",
    licenseRemaining: "Day(s) Left",
    sms_credit: "SMS",
    wp_notConnected: "Device Not Connected",
    searchCustomer: "Search Customer",
    remainingAppointment: "Remaining Appointments",
    changeLanguage: "Change Language",
    licenseExpired: "License Expired",
  },

  panelTabs: {
    moreFavoriteErrorToast: "You can only add up to 7 favourites.",
  },

  favorites: {
    customerManagement: "Customer Management",
    customersList: "Customers List",
    customersPassive: "Passive Customers",
    customersRating: "Customers Ratings",
    customersAdd: "Create New Customer",
    addBulk: "Add Customer from Directory",
    serviceManagement: "Service Management",
    serviceList: "Service List",
    servicesAdd: "Create New Service",
    serviceCreateRequest: "Create Service Name Request",
    servicesRequests: "Service Name Requests",
    servicesBounty: "+ Create Service Bonus",
    packageManagement: "Package Management",
    packageList: "Package List",
    packagesSales: "Package Sales List",
    packagesAdd: "Create New Package",
    salesAdd: "Create New Package Sale",
    stockManagement: "Stock Management",
    productsList: "Products List",
    newProduct: "Create New Product",
    accountingManagement: "Accounting Management",
    accountingIncome: "Income Transactions",
    accountingExpense: "Expense Transactions",
    expenseTypes: "Expense Items",
    accountingDebt: "Debt Transactions",
    personalManagement: "Staff Management",
    settingsEmployees: "List Staff",
    bonusTransaction: "Bonus Transactions",
    employeeAdd: "Create New Employee",
    advancedReports: "Advanced Reports",
    companyReports: "Company Reports",
    staffReports: "Staff Reports",
    customerReports: "Customer Reports",
    serviceReports: "Service Reports",
    stockReports: "Products Reports",
    messageManagement: "Message Management",
    smsManagement: "SMS Management",
    sendSelective: "Send Select Message",
    sendFilter: "Send Filtered Message",
    messageReports: "Message Reports",
    smsSettings: "SMS Settings",
    systemSettings: "System Settings",
    profileSettings: "Profile Settings",
    settings: "Enterprise Settings",
    platformSettings: "Platform Settings",
    workingHours: "Working Hours",
    newBranch: "Create New Branch",
    systemLogs: "System Logs",
    languageSettings: "Language Settings",
    walletManagement: "Wallet Management",
    paymentNotify: "Payment Notify",
    currentBalance: "Current Balance",
    purchase: "Purchase",
    smsPackage: "SMS Package",
    logout: "Logout",
    galleryManagement: "Gallery Management",
    showcaseGallery: "Showcase Gallery",
    enterpriseGallery: "Enterprise Gallery",
    serviceGallery: "Service Gallery",
    appointmentsManagement: "Appointment Management",
    appointments: "Appointment Calender",
    appointmentsOnline: "Online Appointment Requests",
    appointmentsAdd: "Create New Appointment",
    appointmentsFilter: "Filter Appointments",
    receipsManagement: "Tickets Management",
    receipts: "List Tickets",
    receiptsAdd: "Create New Ticket",
  },

  noAccessHead: "You are not authorized to access!",
  noAccessTextOne: "You are not authorized to access this page.",
  noAccessTextTwo:
    "If you think there is a problem, you can reach us through our communication channels.",
  licenseDetails: {
    currentPackage: "(current package)",
    features: "Features",
    silver:
      process.env.APP_NAME === "management"
        ? [
          "Up to 3 Employees",
          'Special "50 Appointment Gift" for New Members',
          'Special "100 WhatsApp Gift" for New Members',
          "100 New Appointments for Only 3.99$",
        ]
        : [
          "Up to 3 Employees",
          'Special "50 Appointment Gift" for New Members',
          'Special "100 SMS Gift" for New Members',
          "100 New Appointments for Only 119₺",
        ],
    gold:
      process.env.APP_NAME === "management"
        ? [
          "Up to 5 Employees",
          "Unlimited Appointments",
          "Multi-Branch Management",
          "Dedicated Client Representative",
          "Business Gallery",
          "Send SMS",
          'Special "250 WhatsApp Gift" for New Members',
        ]
        : [
          "Up to 5 Employees",
          "Unlimited Appointments",
          "Multi-Branch Management",
          "Dedicated Customer Representative",
          "Business Gallery",
          "Send SMS",
          'Special "250 SMS Gift" for New Members',
        ],
    platinum:
      process.env.APP_NAME === "management"
        ? [
          "Up to 7 Employees",
          "Ticket Transactions",
          "Package Management",
          "Inventory Management",
          "Bonus Transactions",
          'Special "500 WhatsApp Gift" for New Members',
          "Advanced Reports",
          "Unlimited Appointments",
          "Online Appointment Website",
          "Multi-Branch Management",
          "Dedicated Client Representative",
        ]
        : [
          "Up to 7 Employees",
          "Invoice Transactions",
          "Package Management",
          "Inventory Management",
          "Bonus Transactions",
          'Special "500 SMS Gift" for New Members',
          "Advanced Reports",
          "Unlimited Appointments",
          "Online Appointment Website",
          "Multi-Branch Management",
          "Dedicated Customer Representative",
        ],
    diamond:
      process.env.APP_NAME === "management"
        ? [
          "Up to 10 Employees",
          "E-Signature Add-on",
          "Contract Management",
          "Ticket Transactions",
          "Package Management",
          'Special "1000 WhatsApp Gift" for New Members',
          "Inventory Management",
          "Bonus Transactions",
          "Advanced Reports",
          "Unlimited Appointments",
          "Online Appointment Website",
          "Multi-Branch Management",
          "Dedicated Client Representative",
        ]
        : [
          "Up to 10 Personnel",
          "E-Signature Plugin",
          "Contract Management",
          "Billing Operations",
          "Package Management",
          'Special "2000 SMS Gift" for New Members',
          "Inventory Management",
          "Bonus Transactions",
          "Advanced Reports",
          "Unlimited Appointments",
          "Online Appointment Website",
          "Multi-Branch Management",
          "Dedicated Customer Representative",
          "WhatsApp Integration",
          "Barcode Reader",
          "Confirmation Link for Appointments",
          "Online Appointment Link",
        ],
    includesAllLicence:
      process.env.APP_NAME === "management"
        ? [
          "Business Management",
          "Client Management",
          "Employee Management",
          "WP Sending",
          "Appointment Reminder",
          "Appointment Management",
          "Business Gallery",
          "To-Do List",
        ]
        : [
          "Business Management",
          "Customer Management",
          "Employee Management",
          "SMS Sending",
          "Appointment Reminder",
          "Appointment Management",
          "Business Gallery",
          "To-Do List",
        ],
    packageNames: {
      goldM: "Gold Package (1 Month)",
      goldY: "Gold Package (12 Months)",
      platM: "Platinum Package (1 Month)",
      platY: "Platinum Package (12 Months)",
      diaM: "Diamond Package (1 Month)",
      diaY: "Diamond Package (12 Months)",
    },

    renewalOpportunity: "Exclusive License Renewal Opportunity for You",
    licenseTooltipGold: "Suitable for Businesses Wanting to Manage Appointments",
    licenseTooltipPlat: "Suitable for Businesses Wanting to Manage Appointments, Pre-accounting, and Commission",
    licenseTooltipDia: "Suitable for Businesses Wanting to Manage Contracts, Appointments, Pre-accounting, and Commission",
    allSpecs: "see all features",
    worth: "Earnings",
    monthlyPaymentTitle: "/ monthly",
    buyNowTitle: "Buy Now",
    moreDesc: "Get more information",
    allFeatures: "All Features",
    renewalTitle: "Don't Miss the License Renewal Opportunity!",
    yearly: "Yearly",
    yearlyTitle: "pay (12 months)",
    buyDetailsTitle: "Purchase Details",
    payNowTitle: "Pay Now",
    paymentPage: "Prices on the payment page",
    taxNotIn: "VAT not included.",
    taxIn: "+VAT amount",

    lastDate: "Last %s Days",

    firstBuyText: "Special for First Purchase",
    discountText: " Discounted Prices!",
    goldPlusText: "Would you like to benefit from Gold Plus features?",
    platPlusText: "Would you like to benefit from Platinium Plus features?",
    plusSpecsTitle: "PLUS Package Features",
    instead: "instead of",
    only: "ONLY",
    takeOffTitle: "Remove",

    wpIntegrationTitle: " WhatsApp Integration",
    appointmentLinkTitle: "Link for Appointment Participation",
    thousandCreditGiftTitle: "1000 SMS-WhatsApp Gift!",
    thousandCreditTitle: "1000 SMS - WhatsApp",
    barcodeReaderTitle: "Barcode Reader",
  },
  tutorial: {
    description: "Complete Missions and Earn Gifts",
    is_success: "Collect Rewards",
    title: "Tasks",
    button_wheel: "Spin",
    wheel_title: "Spin the Wheel and Win Your Gift!",
    wheel_prize: "You`ve Won Complete the missions to get the reward!",
  },
  campaigns: {
    footer: {
      salonAPP: "#1 SALON APPLICATION",
      title1:
        "If you're looking for quality and reliability in salon management,",
      title2: "you've come to the right place.",
      getInfo: "I Want More Information",
      askWp: "Ask on WhatsApp",
      wouldLike:
        "Would you like us to tell you about the Premium benefits of .app?",
      name: "Your Name",
      surname: "Your Surname",
      phone: "Your Phone Number",
      companyName: "Your Company Name",
      callMe: "Call Me",
      sendMessageErrorToast: "Sending failed.",
      sendMessageWarnToast: "Please enter the full first name, last name, or phone number.",
    },
    header: {
      title1: "Beauty for Your Salon",
      title2: "Here",
      title3: `Whether you're at home, on vacation, in the salon, in the car, or anywhere else,
               with Turkey's most comprehensive Salon Management Platform, Salon Randevu APP,
               everything runs just as you want it. You can manage appointments, control staff,
               check stock, and send personalized SMS to your customers. Everything here is
               designed to help you manage your salon more easily.`,
      title4: `By selecting one of the packages above that suits you, you can benefit
               from special content tailored for you.`,
    },
    aboutApp: [
      {
        title: "Salon Appointment Page",
        context:
          "Provide your customers with the ability to book appointments online through the specially created Salon Appointment Page for your salon. Eliminate work-hour limitations and prevent potential customer loss with the online appointment system.",
      },
      {
        title: "Customer Management",
        context:
          "Facilitate customer tracking by reporting detailed customer profiles and appointment history for each of your customers. Increase customer loyalty by offering personalized services to each of your clients.",
      },
      {
        title: "Accounting Management",
        context:
          "Keep your financial situation under control while recording income and expenses. With daily and monthly sales, receivables, personnel premiums, stock, and product sales reports, everything will be under your control.",
      },
      {
        title: "Appointment Management",
        context:
          "Prevent financial losses caused by missed appointments and save time with this automation. It's a must-have feature for those who value every minute.",
      },
      {
        title: "Mobile Application",
        context:
          "Manage your salon 24/7 from your mobile device using the Salon Appointment APP, whether you're on your computer or on the go.",
      },
      {
        title: "Advanced Reporting",
        context:
          "With Advanced Reporting, you can view and evaluate your business's performance in daily, weekly, and monthly periods, and make necessary comparisons.",
      },
      {
        title: "WhatsApp Messaging",
        context:
          "It's now possible to send appointment reminders, surveys, and notifications via WhatsApp instead of SMS.",
      },
      {
        title: "E-Signed Contracts",
        context:
          "Eliminate paper contracts and have your contracts saved with all the details for each customer and obtain digital signatures.",
      },
      {
        title: "Receipt System",
        context:
          "The detailed receipt management panel provides our users with the ability to display and track payments for package sales, services, or product sales on a single page.",
      },
      {
        title: "Staff Reports",
        context:
          "Your primary resource is time. To maximize income, you want your staff to always provide the best service. It’s essential to always stay focused and deliver 100% service to become a perfect business.",
      },
      {
        title: "SMS Notifications",
        context:
          "The Salon Appointment APP made reminders easy and fun. Send and automate reminders quickly and easily to ensure your customers never miss an appointment.",
      },
      {
        title: "Inventory Management",
        context:
          "Track product sales and inventory status with the Salon Appointment App and avoid errors. Keep your data up-to-date and accurate with inventory control, and have your product sales under control with daily and monthly reports.",
      },
    ],
    sayAboutUs: {
      title1: "What Our Users Like the MOST",
      title2: "Features",
      title3: "Beautifully designed, easy-to-use web and mobile application",
      title4: "Easily manage your business with rich features",
      title5: "24/7 instant live support whenever you need it",
      title6: `"On weekends, evenings, or when I was outside my center,
              booking an appointment always meant carrying a notebook
              and pen. Now, the notebook and pen are completely out
              of my life; I can instantly make an appointment from
              my phone 24/7, which is an incredible comfort for me.
              From my customers' perspective, the moment we switched
              to the system, I noticed an incredible image change, and
              they told me this program is very professional..."`,
      title7: `"Since we started our salon appointment membership, the
              whole team has been very attentive and helpful in every
              matter. I especially want to thank Ms. Elif for her
              efforts and dedication. The program has greatly reduced
              our workload, and I highly recommend it."`,
      title8: `"An excellent program, the application is very successful:
              It works seamlessly for appointments, customer records,
              accounting, and with its extensive features. It's a
              must-have, and I definitely recommend it."`,
    },
    featureOffApp: {
      head: "App Features",
      title1: "Most Used Features of the Salon Appointment App",
      title2: "Salon Appointment Page",
      title3: "Customer Management",
      title4: "Accounting Management",
      title5: "Appointment Management",
      title6: "Mobile Application",
      title7: "Advanced Reporting",
      title8: "WhatsApp Messaging",
      title9: "E-signed Contract",
      title10: "Billing System",
      title11: "Staff Reports",
      title12: "SMS Notifications",
      title13: "Inventory Management",
    },
    statistics: {
      title1: "The Best",
      title2: "Software",
      title3: "Instant Live Support",
      title4:
        "Our team is ready to support you whenever you need! You can reach us 24/7 through our contact information.",
      title5: "Easy to Use",
      title6:
        "Experience the convenience of controlling all your salon information at any time through the web panel or mobile app.",
      title7: "100% Security",
      title8:
        "Your data is safe with us! We securely store and protect all information related to your salon and you.",
      title9: "E-Signed Contract",
    },
  },
};

export default langEN;
